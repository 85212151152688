import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { TEST_TYPES } from "constant";
import { LOCATIONS_OPTIONS } from "constant";
import { formatDateMDY, capitalizeLetter, formatPhoneNumber, formatDateOfBirthDOB } from "utils";
import moment from "moment";
// import DatePicker from "react-date-picker";
import { debounce } from "debounce";
import API from "api";
import Loader from "components/Loader/Loader";

// import FilterPopOver from "components/FilterPopOver";
import Icon from "components/Icon";
import { COMBINE_PROFILE } from "constant";
import { AppContext } from "../context/app-context";
import ProfileListItems from "./ProfileRelationListItem";
import Filter from "components/Filter";

const ProfileRelationModal = (props) => {
  const { handleClose, employeesId, getConnectedProfiles, connected } = props;
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({});
  const [relations, setRelations] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [relationOptions, setRelationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [fullscreen, setFullscreen] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    getRelationsData();
    getConnectedData();
  }, []);

  const getConnectedData = async () => {
    if (!connected) {
      return;
    }
    const data = await API.getConnectedProfiles({ ids: connected.map((c) => c.id) });

    const connectedEmployees = data?.data || [];

    const formattedRelationalData = connected.map((obj) => {
      const object = connectedEmployees.find((e) => e.id === obj.id);
      return { ...object, ...obj };
    });

    console.log("data", formattedRelationalData);
    setRelations(formattedRelationalData);
  };

  const getRelationsData = async () => {
    const data = await API.getProxyContactData();
    const dataToSave = data?.map((d) => {
      return { value: d.name, label: d.name };
    });
    setRelationOptions(dataToSave);
  };

  const handleMakeRelation = (item, relation) => {
    if (item.mdID && relation) {
      const isExists = relations.find((f)=> f.mdID === item.mdID);
      if(isExists) return;
      setRelations([...relations, { ...item, relation: relation }]);
      setFilteredUsers(filteredUsers.filter((f) => f.mdID !== item.mdID));
      setUsers(users.filter((f) => f.mdID !== item.mdID));
    }
  };

  const handleDeleteRelation = (item) => {
    setRelations(relations.filter((f) => f.mdID !== item.mdID));
    const users = [...filteredUsers];
    users.unshift(item);
    setFilteredUsers(users);
    setUsers((prev) => [item, ...prev]);
  };

  const [checkboxes, setCheckboxes] = useState([]);
  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];
  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };
  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
    }
  }, [filter]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const relationList = relations.filter((f) => f.relation !== COMBINE_PROFILE);
      const combineList = relations.filter((f) => f.relation === COMBINE_PROFILE);
      if (connected.length > 0 || relationList.length > 0) {
        let result = await API.updateRelations({
          id: employeesId,
          connectedTo: relationList.map((m) => ({ id: m.mdID, relation: m.relation, relationType: "Child" })),
        });
        let logData = [];
        logData.push({
          schrID: employeesId,
          auditType: "ProxyProfile",
          action: "View Details",
          relations: JSON.stringify(
            relationList.map((m) => ({
              id: m.mdID,
              firstName: m.firstName,
              lastName: m.lastName,
              relation: m.relation,
            }))
          ),
          companyID: appContext.company.id,
          userName: appContext.user.name,
        });
        await API.addlogs(logData);
      }

      if (combineList.length > 0) {
        const res = await API.mergeEmployeeLogs(
          employeesId,
          combineList.map((m) => m.mdID)
        );
        if (res.data) {
          for (const emp of combineList) {
            await API.deleteEmployee(emp.mdID);
          }

          let logData = [];
          logData.push({
            schrID: employeesId,
            auditType: "CombineProfile",
            action: "View Details",
            deletedIds: JSON.stringify(
              combineList.map((m) => ({
                id: m.mdID,
                firstName: m.firstName,
                lastName: m.lastName,
                phoneNumber: m.phoneNumber || m.phone_number,
              }))
            ),
            companyID: appContext.company.id,
            userName: appContext.user.name,
          });
          await API.addlogs(logData);
        }
      }
      setLoading(false);
      appContext.showSuccessMessage("Proxy relation updated successfully!");
      // getConnectedProfiles();
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadEmployees(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    [relations]
  );

  const loadEmployees = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    try {
      Object.assign(filterParam, { page: page });
      if (dateFilter) {
        const { start, end } = dateFilter;
        if (start)
          Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
        if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
      }
      if (orderBy) {
        Object.assign(filterParam, { orderBy, orderByDirection });
      }

      const pgList = await API.getEmployeeListFromPG(filterParam);

      const filterEmployees = pgList.rows.filter(
        (f) => f.mdID !== employeesId && relations.findIndex((i) => i.mdID === f.mdID) === -1
      );
      setUsers(filterEmployees);
      setFilteredUsers(filterEmployees);
      setLoading(false);

    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"xl"} scrollable keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Manage Proxy Profiles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mt-0 fw-bold">{`Proxy Profiles (${relations.length})`}</h4>
        <div className="">
          <Table className="table">
            <thead>
              <tr>
                <th className="">First Name</th>
                <th className="">Last Name</th>
                <th className="">Gender</th>
                <th className="">DoB</th>
                <th className="">Phone</th>
                <th className="">Relation</th>
                <th className="">Action</th>
              </tr>
            </thead>
            <tbody>
              {relations?.map((item, i) => {
                return (
                  <tr>
                    <td>{capitalizeLetter(item.firstName)}</td>
                    <td>{capitalizeLetter(item.lastName)}</td>
                    <td>{item.sex}</td>
                    <td>{formatDateOfBirthDOB(item.dob)}</td>
                    <td>{formatPhoneNumber(item.phoneNumber || item.phone_number, "", true)}</td>
                    <td>{item.relation}</td>
                    <td>
                      <i className="fa fa-trash" onClick={() => handleDeleteRelation(item)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <h4 className="mt-0 fw-bold">{`All Profiles (${filteredUsers.length})`}</h4>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <Icon handleClick={() => setShowFilter(!showFilter)} label="Filter" title="Filter" iconType={"filter"} />
            {showFilter && (
              <Filter
                filterTerms={searchTerms}
                setFilter={setFilter}
                filter={filter}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                isUsersTable={true}
              />
            )}

            <div className={"table-responsive "}>
              <Table className="table">
                <thead>
                  <tr>
                    <th className="tdWidth16">First Name</th>
                    <th className="tdWidth16">Last Name</th>
                    <th className="tdWidth16">Gender</th>
                    <th className="tdWidth16">DoB</th>
                    <th className="tdWidth16">Phone</th>
                    <th className="tdWidth16">Relations</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 &&
                    filteredUsers.map((emp, index) =>
                      employeesId ? (
                        <ProfileListItems
                          key={emp.mdID}
                          options={relationOptions}
                          //   handleCheck={handleCheckBox}
                          item={emp}
                          employeeId={employeesId}
                          index={index}
                          checkboxes={checkboxes}
                          handleMakeRelation={handleMakeRelation}
                          relations={relations}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      {/* <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div> */}
      <Modal.Footer>
        <Button className="profileButton btn-fill createButtons" variant="primary" onClick={handleClose}>
          Close
        </Button>
        {
          <Button variant="secondary" className="profileButton btn-fill createButtons" onClick={handleSave}>
            Save
          </Button>
        }
      </Modal.Footer>
      {loading && <Loader />}
      {/* {showConfirmationMessage && (
        <ConfirmationModal
          show={showConfirmationMessage ? true : false}
          title="Update Custom Schedule"
          message={showConfirmationMessage}
          handleConfirm={handleConfirm}
          closeBtn
        />
      )} */}
    </Modal>
  );
};

export default ProfileRelationModal;
