import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { userCompanyID } from "utils";
import insuranceServices from "../../../services/InsuranceService";

import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  INSURANCES,
  INSURANCES_CREATE,
  INSURANCES_DELETE,
  INSURANCES_FETCH,
  INSURANCES_UPDATE,
} from "./insuranceConstant";

export const fetchInsurancesAsync = createAsyncThunk(INSURANCES_FETCH, async (param, { dispatch }) => {
  return await insuranceServices.fetchInsurances(param);
});

export const createInsuranceAsync = createAsyncThunk(INSURANCES_CREATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));
  const response = await insuranceServices.createInsurances({
    ...param,
  });
  dispatch(setLoader(false));

  if (response && response.rows && response.rows.length > 0) {
    dispatch(setMessage("Insurance Added Sucessfully", MESSAGE_MODES.success));
    return response.rows[0];
  } else {
    dispatch(setMessage(`${response?.error || "Insurance Not Created"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const updateInsuranceAsync = createAsyncThunk(INSURANCES_UPDATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));
  
  const response = await insuranceServices.updateInsurances({
    ...param,
  });
  dispatch(setLoader(false));

  if (response && response.status) {
    dispatch(setMessage("Insurance Updated Sucessfully", MESSAGE_MODES.success));
    return param;
  } else {
    dispatch(setMessage(`${response?.error || "Insurance Not Updated"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const deleteInsuranceAsync = createAsyncThunk(INSURANCES_DELETE, async (defaultSetting) => {
  // const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return {};
});

const insuranceSlice = createSlice({
  name: INSURANCES,
  initialState: {
    insurancesList: [],
    filteredInsurances: [],
    openInsuranceModal: false,
    selectedInsurance: null,
    openInsruModal: false,
  },
  reducers: {
    setInsurnaceList: (state, action) => {
      state.insurancesList = action.payload;
    },
    setFilteredInsurnaceList: (state, action) => {
      state.filteredInsurances = action.payload;
    },
    setOpenInsuranceModal: (state, action) => {
      state.openInsuranceModal = action.payload;
    },
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = action.payload;
    },
    setOpenInsruModal: (state, action) => {
      state.openInsruModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsurancesAsync.fulfilled, (state, action) => {
        state.insurancesList = action.payload;
        state.filteredInsurances = action.payload;
      })
      .addCase(createInsuranceAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.insurancesList.unshift(action.payload);
          state.filteredInsurances.unshift(action.payload);
        }
      })
      .addCase(updateInsuranceAsync.fulfilled, (state, action) => {
        const updateItem = action.payload;
        if (updateItem) {
          const existingclientIndex = state.insurancesList.findIndex((code) => code.id === updateItem.id);
          if (existingclientIndex !== -1) {
            state.insurancesList[existingclientIndex] = updateItem;
          }
          const existingIndex = state.filteredInsurances.findIndex((code) => code.id === updateItem.id);
          if (existingIndex !== -1) {
            state.filteredInsurances[existingIndex] = updateItem;
          }
        }
      });
  },
});

export const {
  setInsurnaceList,
  setFilteredInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
  setOpenInsruModal,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
