import ErrorMessage from "components/Message/ErrorMessage";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { convertToLower, getValidReg, isValidPhoneWithCode } from "utils";

import AutoCompleteAddress from "components/AutoCompleteAddress";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Select from "react-select";
import DefaultClientSettingModal from "./DefaultClientSettingModal";

import { countryListAllIsoData, MESSAGE_MODES } from "constant";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";

import { BILLING_TYPES_OPT, CONFIG } from "constant";

import api from "api";
import ClientProviderModal from "components/Modal/ClientProviderModal";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import { providerList } from "../../store/features/providers/providersSelectors";

import { getCptData } from "api";
import InputField from "components/InputField/InputField";
import { showHideClientPcsModal } from "store/features/clientPcs/clientPcsAction";
import { updateLabClient } from "store/features/locations/locationsSlice";
import { setSelectedClientId, setShowSalesPersonModal } from "store/features/salePerson/salePersonAction";
import { totalSalesPersonSelector } from "store/features/salePerson/salePersonSelector";

const LabClientModal = (props) => {
  const { handleClose, selectItem, handleSave, appContext } = props;

  const loginUser = useSelector(selectLoginUser);
  const [error, setError] = useState("");
  const getObject = () => {
    const country = countryListAllIsoData.find((f) => f.name === selectItem.country) || { code: "US" };

    return { ...selectItem, isoCode: country.code };
  };
  const [newLocation, setNewLocation] = useState(getObject());
  const [provider, setProvider] = useState(null);
  const [openAssociateProviderModal, setOpenAssociateProviderModal] = useState(null);
  const [openDefaultSetting, setOpenDefaultSetting] = useState(null);
  const dispatch = useDispatch();
  const providers = useSelector(providerList);
  const totalSalesPerson = useSelector(totalSalesPersonSelector);

  useEffect(() => {
    if (!!selectItem) dispatch(setSelectedClientId(selectItem.id));
  }, [selectItem]);

  const handleSaveChange = () => {
    if (!newLocation.name || !newLocation.name.trim()) {
      setError("Client name required");
      return;
    }

    // if (!newLocation.contact_name) {
    //   setError("Main Contact Name is required");
    //   return;
    // }

    // if (!newLocation.contact_email) {
    //   setError("Email is required");
    //   return;
    // }
    // if (!isValidEmail(newLocation.contact_email)) {
    //   setError("Invalid Email");
    //   return;
    // }

    // if (!newLocation.contactName) {
    //   setError("Main Contact is required");
    //   return;
    // }
    // if (!isValidPhoneWithCode(newLocation.contactName)) {
    //   setError("Invalid Phone Number");
    //   return;
    // }

    if (!newLocation.phone_number) {
      setError("Main Contact Number is required");
      return;
    }

    if (!isValidPhoneWithCode(newLocation.phone_number)) {
      setError("Invalid Main Contact Number");
      return;
    }

    const primaryPhysician = PROVIDER_OPTIONS.find((f) => f.value === newLocation?.defaultProvider)?.label || "";

    handleSave({ ...newLocation, primaryPhysician });
  };

  const PROVIDER_OPTIONS = useMemo(() => {
    if (providers && providers.length > 0) {
      return providers.map((f) => ({ label: `${f.npi}-${f.name}`, value: f.id }));
    } else {
      return [];
    }
  }, [providers]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewLocation({ ...newLocation, street: val });
    } else {
      const address = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewLocation({ ...newLocation, ...address });
    }
  };

  const handleCreateClientSetting = async () => {
    const provider = providers.find((f) => f.id === newLocation.defaultProvider);
    const param = {
      companyID: newLocation.id,
      subAgentID: CONFIG.generalSubAgent,
      defaultProvider: {
        prov_id: provider.id,
        prov_name: provider.name,
        prov_npi: provider.npi,
        prov_name_f: provider.firstName,
        prov_name_l: provider.lastName,
        prov_name_m: provider.middleName,
        ref_id: provider.id,
        ref_npi: provider.npi,
        ref_name: provider.name,
        ref_name_f: provider.firstName,
        ref_name_l: provider.lastName,
        ref_name_m: provider.middleName,
        ord_prov_id: provider.id,
        ord_prov_npi: provider.npi,
        ord_prov_name: provider.name,
        ord_prov_name_f: provider.firstName,
        ord_prov_name_l: provider.lastName,
        ord_prov_name_m: provider.middleName,
      },
    };
    await api.newClientSetting(param);
  };

  const handleOpenDefaultSetting = () => {
    setOpenDefaultSetting(newLocation);
  };
  const handlePhoneNo = (e) => {
    setNewLocation({
      ...newLocation,
      contactName: e,
    });
  };

  const handleChangeDefaultProvider = async (obj) => {
    const updatedObject = { ...newLocation, defaultProvider: obj.id, primaryPhysician: obj.name };
    setNewLocation(updatedObject);
    await dispatch(updateLabClient(updatedObject));
  };

  const handleShowClientPcs = async () => {
    const cptCodes = selectItem?.excludedCPT?.excluded?.map((item) => item.code).join(",");

    const cptData = await getCptData({ codes: cptCodes });

    dispatch(showHideClientPcsModal({ show: true, clientId: selectItem.id, pcsData: cptData?.rows ?? [] }));
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newLocation.isNew ? " Create New Lab Client Data" : "Edit Lab Client Data"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper">
          <div className="leftSide">
            <strong>Client Contact information:</strong>
            <div className="row mb-3">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Practice Name</label>
                <input
                  value={newLocation?.name}
                  className="modalInput"
                  placeholder="Client Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      name: getValidReg(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Practice ID</label>
                <input
                  value={newLocation?.practiceID}
                  className="modalInput"
                  placeholder="Client Practice ID"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      practiceID: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Address:</label>
                <AutoCompleteAddress
                  cssClass="modalInput"
                  value={newLocation.street}
                  // value={newLocation.city}
                  handleChange={handleUpdateAddress}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">City:</label>
                <input
                  type="text"
                  className="modalInput"
                  value={newLocation.city}
                  placeholder="City"
                  onChange={(e) => setNewLocation({ ...newLocation, city: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Country:</label>
                <input
                  type="text"
                  className="modalInput"
                  value={newLocation.country}
                  placeholder="Country"
                  onChange={(e) => setNewLocation({ ...newLocation, country: e.target.value })}
                />
              </div>
              {/* <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      phone_number: e,
                    })
                  }
                  value={newLocation?.phone_number}
                />
              </div> */}
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      phone_number: e,
                    })
                  }
                  value={newLocation?.phone_number}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Fax:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      fax: e,
                    })
                  }
                  value={newLocation?.fax}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email:</label>
                <input
                  type="text"
                  className="modalInput"
                  placeholder="Client Email"
                  value={newLocation?.email}
                  onChange={(e) => {
                    setNewLocation({
                      ...newLocation,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <InputField
                type="dropDown"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                inputStyle="w-100"
                label="Billing Type :"
                options={BILLING_TYPES_OPT}
                value={
                  newLocation.setting?.billType
                    ? BILLING_TYPES_OPT.find((f) => f.value === newLocation.setting?.billType)
                    : null
                }
                index="billType"
                placeholder="Select Billing Type"
                // disabled={editButton}
                handleChange={(e) => {
                  setNewLocation({
                    ...newLocation,
                    setting: { ...(newLocation.setting || {}), billType: e.value },
                  });
                }}
              />
            </div>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
          <div className="leftSide">
            <div className="row mb-3">
              <strong>Main Contact information:</strong>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Name:</label>
                <input
                  value={newLocation?.contact_name}
                  className="modalInput"
                  placeholder="Main Contact Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_name: getValidReg(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Main Contact Phone Number:</label>
                <PhoneNoInput handleChange={handlePhoneNo} value={newLocation.contactName} />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Email: </label>
                <input
                  value={newLocation.contact_email}
                  className={`modalInput 
                                    ${
                                      // newLocation.isNew &&
                                      "pd-right-30"
                                    }`}
                  placeholder="Email"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_email: convertToLower(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Fax: </label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_fax: e,
                    })
                  }
                  value={newLocation?.contact_fax}
                />
              </div>

              <div
                className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
              >
                <span>Active/Inactive:</span>
                <SwitchToggle
                  checked={newLocation.isActive}
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      isActive: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Discount %:</label>
                <input
                  type="number"
                  value={newLocation.discount}
                  className={`modalInput`}
                  placeholder="1%"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      discount: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <div>
                  <label className="modalLineHeaders" style={{ marginBottom: "10px" }}>
                    Commission %:{" "}
                  </label>

                  <label style={{ color: "green", cursor: "pointer", float: "right" }} onClick={handleShowClientPcs}>
                    Excluded CPT
                  </label>
                  <input
                    type="number"
                    value={newLocation.commission}
                    className={`modalInput`}
                    placeholder="1%"
                    style={{
                      width: "100%",
                      flexShrink: "initial",
                    }}
                    onChange={(e) =>
                      setNewLocation({
                        ...newLocation,
                        commission: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-group-wrapper">
                <label
                  className="modalLineHeaders cursor-pointer"
                  onClick={() => newLocation?.associatedClients && setOpenAssociateProviderModal(newLocation)}
                >
                  Associated Provider: {newLocation?.associatedClients || 0}
                </label>
                {newLocation.defaultProvider && (
                  <label className="modalLineHeaders fw-bold">
                    {`Primary Provider: 
                    ${PROVIDER_OPTIONS.find((f) => f.value === newLocation.defaultProvider)?.label || ""}`}
                  </label>
                )}
                <div style={{ display: "flex" }}>
                  <Select
                    options={PROVIDER_OPTIONS}
                    blurInputOnSelect={true}
                    className="referring-provider-select w-100"
                    value={PROVIDER_OPTIONS.find((item) => item.value === provider)}
                    isClearable={true}
                    // isDisabled={editButton}
                    menuPlacement="auto"
                    autoComplete="none"
                    placeholder="Select Associate Provider"
                    onChange={(e) => {
                      setProvider(e?.value);
                    }}
                  />
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill mx-2"
                    style={{ marginLeft: "10px" }}
                    onClick={async () => {
                      // dispatch(udpateProviderClient({ pId: newLocation.associatedClients, uId: newLocation.id }));
                      if (!provider) return;
                      let prevRes = await api.updateProviderClient(provider, newLocation.id);
                      if (prevRes.id) {
                        if (prevRes.associatedClient !== newLocation.id) {
                          setNewLocation({ ...newLocation, associatedClients: newLocation.associatedClients + 1 });
                        }
                        dispatch(setMessage("Provider Associated Successfully", MESSAGE_MODES.success));
                      }
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Associated Sales persons: {totalSalesPerson || "0"}</label>
                <Button
                  variant="secondary"
                  className="headerButton btn-fill mx-2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => dispatch(setShowSalesPersonModal(true))}
                >
                  Manage
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <div>
          {/* <Button variant="secondary" className="headerButton btn-fill me-1" onClick={() => handleOpenDefaultSetting()}>
            Open Default Setting
          </Button> */}
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange}>
            {newLocation.isNew ? "Create" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
      {openAssociateProviderModal && (
        <ClientProviderModal
          handleClose={(obj) => {
            setOpenAssociateProviderModal(null);
          }}
          cssClass="seperateModal"
          user={loginUser}
          data={openAssociateProviderModal}
          setDefaultProvider={handleChangeDefaultProvider}
        />
      )}
      {openDefaultSetting && (
        <DefaultClientSettingModal
          data={openDefaultSetting}
          handleClose={() => setOpenDefaultSetting(null)}
          cssClass="seperateModal"
        />
      )}
    </Modal>
  );
};

export default LabClientModal;
