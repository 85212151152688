import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { formatTest } from "utils";
import PDFLayout from "components/PDF/PDFLayout";

const ResultViewModal = (props) => {
  const { test, appContextView, handleClose } = props;

  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();

  useEffect(() => {
    if (test) {
      loadPDFData();
    }
  }, []);

  const loadPDFData = async () => {
    const {
      employee_demographics: { mdID },
    } = test;
    let empID = mdID;

    if (!empID) {
      let id = await appContextView.getEmployeeID(test.employeeID);
      empID = id;
    }

    appContextView.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setTestPdf(formatTest({ ...test, empID }));
    });
  };

  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} size={"2x2"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Result View - {`${test?.barcode}`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          {testPdf ? <PDFLayout setShowPdf={handleClose} testPdf={testPdf} testsLab={testsLab} /> : "Loading"}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResultViewModal;
