import React, { useState } from "react";
import EditIcon from "assets/img/edit-icon.png";
import TrashIcon from "assets/img/trash-icon.png";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import api from "api";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import { Col, Table } from "react-bootstrap";
import { formatUTCDate }  from "utils"

const DocumentList = (props) => {
  const { headerLabel, list, handleEdit, disabled, setDocuments, appContext, setLoading, dropDownOptions, tabelForm } =
    props;

  const handleDownloadDoc = async (doc, name) => {
    try {
      setLoading(true);
      const resut = await Storage.get(doc.fileName, {
        bucket: "employee-external-tests",
      });
      const response = await fetch(resut);
      const blob = await response.blob();

      console.log("fileMetadata", blob.size);
      setLoading(false);
      saveAs(blob, `${name}`);
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  };

  const handleDocDeleted = async (param) => {
    try {
      setLoading(true);
      const response = await api.deleteDocumentAPI(param);
      console.log("response", response);
      setLoading(false);
      if (response && response.status) {
        appContext.showSuccessMessage("Document Deleted Successfull");
        setDocuments((prev) => prev.filter((f) => f.id !== param.id));
        return;
      }
      appContext.showErrorMessage(response?.error || "Document Deleted Successfull");
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  const handleDropDownOptions = (type, item, filename) => {
    switch (type) {
      case "Edit":
        handleEdit(item);
        break;
      case "Delete":
        handleDocDeleted(item);
        break;
      case "Download":
        handleDownloadDoc(item, filename);
        break;
    }
  };

  const TableRow = ({ item, index }) => {
    const parts = item.fileName.split("$");

    const slicedString = parts[parts.length - 1];
    return (
      <tr>
        <td className="popOver-centered">{formatUTCDate(item.createdAt)}</td>
        <td className="popOver-centered">{item.docType}</td>
        <td className="popOver-centered">{item.docLabel || ""}</td>
        <td className="popOver-centered">{slicedString || ""}</td>
        <td className="popOver-centered">
          <DropdownMenu
            options={dropDownOptions}
            handleDropDownOptions={(type) => handleDropDownOptions(type, item, slicedString)}
          />
        </td>
      </tr>
    );
  };
  return (
    <>
      {tabelForm ? (
        <Table className="section-testDetails table-striped">
          <thead>
            <tr>
              <th className="popOver-centered border-0">Date</th>
              <th className="popOver-centered border-0">Doc Type</th>
              <th className="popOver-centered border-0">Doc Label</th>
              <th className="popOver-centered border-0">File Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, i) => (
              <TableRow key={i} index={i} item={item} />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="order-select-edited mb-4 bg-white scrollable-div">
          {list &&
            list.map((item) => {
              const parts = item.fileName.split("$");

              const slicedString = parts[parts.length - 1];

              return (
                item && (
                  <div className="secondary-insurance-change d-flex justify-content-between">
                    <div className="w-75 flex-column d-flex" title={item.fileName}>
                      <span className="fw-bold">{item.docType}</span>
                      <span>{item.docLabel || ""}</span>
                      <span>{slicedString || ""}</span>
                    </div>
                    <div className="w-25 text-center">
                      <DropdownMenu
                        options={dropDownOptions}
                        handleDropDownOptions={(type) => handleDropDownOptions(type, item, slicedString)}
                      />
                      {/* <img
                        src={EditIcon}
                        className={disabled ? "disable-cursor" : "cursor-pointer"}
                        width="18"
                        alt="edit icon"
                        onMouseLeave={(e) => (e.target.style.color = "black")}
                        aria-hidden="true"
                        onClick={(e) => handleEdit && handleEdit(item)}
                      />

                      <i
                        className={`fas fa-download fs-4 mx-2 cursor-pointer`}
                        style={{ color: "grey" }}
                        onClick={() => handleDownloadDoc && handleDownloadDoc(item, slicedString)}
                      />
                      <img
                        src={TrashIcon}
                        width="16"
                        alt="Trash icon"
                        className={"cursor-pointer"}
                        onMouseLeave={(e) => (e.target.style.color = "black")}
                        aria-hidden="true"
                        onClick={(e) => handleDocDeleted && handleDocDeleted(item)}
                      /> */}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      )}
    </>
  );
};

export default DocumentList;
