import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
// import PreviousNotes from "components/Note/PreviousNotes";
import RadioButton from "components/RadioButton/RadioButton";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH, PAGE_LIMIT } from "constant";
import graphQLApi from "graphQLApi";
import moment from "moment";
import { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateProviderNoteAsync } from "store/features/providers/providersSlice";
import { updateLabClientNotes } from "store/features/locations/locationsSlice";
import MainTable from "components/Table/MainTable";
import { personalizationLocalStorage } from "utils";
import { providerList } from "store/features/providers/providersSelectors";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";

const ClientProviderModal = (props) => {
  const { data, handleClose, user, cssClass, setDefaultProvider } = props;

  const [providers, setProviders] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [defaultProviderID, setDefaultProviderID] = useState("");
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  //   const [status, setStatus] = useState(data.status);
  //   const [level, setLevel] = useState(emp ? "patient" : "claim");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const PROVIDER_OPTIONS = useSelector(providerList).filter((provider) => {
    return provider.associatedClient === data.id;
  });
  const quickTools = [TABLE_QUICK_TOOLS.checkbox];

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(PROVIDER_OPTIONS);

  const onHandleSave = async (selectedOpt) => {
    setDefaultProvider(selectedOpt);
    handleClose();
  };
  useEffect(() => {
    setPersonalize(personalizationLocalStorage.get(user, "provider", PROVIDER_PERSONALIZE));
  }, []);

  const handleCellClick = (key, row, event) => {
    if (key === TABLE_QUICK_TOOLS.checkbox && setDefaultProvider) {
      if (event.target.checked) {
        setDefaultProviderID(row);
      } else {
        setDefaultProviderID("");
      }
    }
  };

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"xl"} scrollable className={cssClass}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Assoicated Providers </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <MainTable
                columns={personalize}
                rows={usersToMap}
                // flipSort={flipSort}
                // sortBy={sortBy}
                // sortDescending={sortDescending}
                widthToSkip={160}
                selectedRows={[defaultProviderID?.id]}
                // selectedRows={checkboxes}
                tools={setDefaultProvider ? quickTools : []}
                handleCellClick={handleCellClick}
              />
            </div>
          </div>
          {/* <PreviousNotes id={order || claim ? data.employeeID : data.id} user={user} /> */}
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          {setDefaultProvider && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              disabled={!defaultProviderID}
              className="modalButtons headerButton btn-fill"
              onClick={() => onHandleSave(defaultProviderID)}
            >
              Set as a Primary
            </Button>
          )}

          {loading && <Loader />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientProviderModal;
