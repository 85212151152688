import api from "api";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import FilledCheckbox from "components/CheckBox/FilledCheckBox/FilledCheckBox";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import {
  CONFIG,
  countryListAllIsoData,
  CUSTOM_INS,
  INSURANCE_PROVIDER,
  NEW_DISCOVERY_ID,
  stringConstant,
  UN_RESULT,
} from "constant";
import countriesOption from "countries";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { customCloneDeep } from "util/customLodash";
import {
  convertToLower,
  convertToUpper,
  formatPhoneWithOutSpaces,
  formatZip,
  getPhoneNo,
  isValidEmail,
  isValidName,
  isValidPhoneWithCode,
  makeAlphabetInput,
  makeBarcode,
  sortByAlphaOrder,
  toTitleCase,
  validSpace,
  zipCodeValidationUS,
  parseJSONObject,
  getClientSpecificString,
} from "utils";
import GQAPI from "views/GraphQL/gqApi";
import OrderTestHeader from "views/Order/OrderTestHeader";
import OrderTestItem from "views/Order/OrderTestItem";
import DiagnosisAccordionView from "views/TestTypeSetting/TestDiagnosisView/DiagnosisAccordionView";

const getFormattedEmployeeObject = (record) => {
  console.log("record", record);
  const {
    zip,
    state,
    sex,
    fetchFrom,
    city,
    whiteGlove,
    isVaccinated,
    employeeType,
    street,
    phone_number,
    phoneNumber,
    dob,
    last,
    first,
    firstName,
    lastName,
    email,
    id_number,
    id,
    country,
    countryCode,
    clientID,
    insurance_name,
    insuranceCompanyCode,
    insurance_number,
  } = record;

  const [ccCode, phno] = getPhoneNo(phone_number || phoneNumber, countryCode);
  const val = countryListAllIsoData.find((f) => f.name === country) || { code: "US" };

  const obj = {
    employee_demographics: {
      email: email,
      firstName: first || firstName,
      lastName: last || lastName,
      dob,
      phoneNumber: `${ccCode}${phno}`,
      countryCode: ccCode,
      address: "",
      employeeType: employeeType,
      isVaccinated: isVaccinated || false,
      whiteGlove: whiteGlove,
      city: city,
      country: country || "United States",
      isoCode: val.code || "US",
      state: state,
      street: street,
      street2: "",
      zip: zip,
      isLucira: false,
      sex: sex,
      testerDes: "",
      idNumber: id_number,
      insurance_name,
      insuranceCompanyCode,
      insurance_number,
      status: "Pending",
    },
    phoneNumber: phone_number || phoneNumber,
    email: email,
    employeeID: CONFIG.clientID === clientID ? id : "",
  };

  return obj;
};

const OrderTestModal = (props) => {
  const { order, handleCloseCreateTest, clients, sites, labs, isNew, users, appContext } = props;
  const parseOrder = () => {
    if (order.isNew) {
      if (order?.selectedPatient) {
        const obj = getFormattedEmployeeObject(order.selectedPatient);
        const newObj = {
          ...order,
          employee_demographics: obj.employee_demographics,
          email: obj.email,
          phoneNumber: obj.phoneNumber,
          employeeID: obj.employeeID,
          orderDate: moment().format("YYYY-MM-DDTHH:mm"),
        };
        delete newObj.selectedPatient;
        return newObj;
      }

      return { ...order, orderDate: moment().format("YYYY-MM-DDTHH:mm") };
    }
    return order;
  };

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showPopOver, setShowPopOver] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [newTestCreate, setTestCreate] = useState(parseOrder());
  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [disableVaccination, setDisabledVaccination] = useState(false);
  const [onSelectedTester, setOnSelectedTester] = useState({});
  const [resultTypes, setResultTypes] = useState([]);
  const childNode = useRef();
  const [lab, setLabs] = useState([]);
  const [clientInsurances, setClientInsurances] = useState(INSURANCE_PROVIDER);
  const [testOptions, setTestOptions] = useState();

  const [ictList, setIctList] = useState(null);
  const [editIndexIct, setEditIndexIct] = useState(null);

  const defaultConfig = useMemo(() => {
    return getClientSpecificString();
  }, []);

  const loadDefaultInsurances = async () => {
    try {
      const res = await api.getClientInsurances({ list: "y" });

      setClientInsurances(res);
    } catch (ex) {
      console.log("Ex", ex);
    }
  };

  const loadTestOptions = async () => {
    const options = await api.getTestTypesOptions();
    setTestOptions(options);
  };

  useEffect(() => {
    loadDefaultInsurances();
    loadTestOptions();
    appContext.resetSites();
  }, []);

  const getSiteOptions = useMemo(() => {
    if (!newTestCreate.clientID) return [];
    return sites
      .filter((s) => s.clientID === newTestCreate.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [newTestCreate.clientID]);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes(
          sortByAlphaOrder(
            [
              ...(client.resultTypes.results.map((m) => ({ value: m, label: m })) || []),
              { value: UN_RESULT, label: UN_RESULT },
            ],
            "label"
          )
        );
      }
    }
  }, [appContext.clients]);

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  useEffect(() => {
    let updates = {};

    if (clients.length === 1 && !newTestCreate.clientID) {
      const { id, name } = clients[0];
      updates = {
        ...updates,
        clientID: id,
        clientName: name,
      };

      const baseSite = sites.find((s) => s.clientID === id);
      if (baseSite) {
        const { id, name } = baseSite;
        updates = {
          ...updates,
          siteID: id,
          site_name: name,
        };
      }
    }

    if (labs.length === 1 && !newTestCreate.labID) {
      const { id, name } = labs[0];
      updates = {
        ...updates,
        labID: id,
        labName: name,
      };
    }

    if (Object.keys(updates).length > 0) {
      setTestCreate((prev) => ({
        ...prev,
        ...updates,
      }));
    }
  }, [clients, labs, newTestCreate]);

  useEffect(() => {
    if (newTestCreate?.test_type) {
      const TEST_TYPE_VALUE = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Rapid_PCR: "Other",
        Rapid_Antigen: "Antigen",
        Rapid_Flu: "RapidFlu",
      };

      let type = TEST_TYPE_VALUE[newTestCreate?.test_type.replace(/ /g, "_")];
      if (type === undefined) {
        type = newTestCreate?.test_type;
      }

      setTestCreate({
        ...newTestCreate,
        test_type: type,
        employee_demographics: {
          ...newTestCreate.employee_demographics,
          country: newTestCreate.employee_demographics?.country
            ? newTestCreate.employee_demographics?.country
            : "United States",
          isoCode: newTestCreate.employee_demographics?.isoCode ? newTestCreate.employee_demographics?.isoCode : "US",
          phoneNumber: formatPhoneWithOutSpaces(
            newTestCreate.employee_demographics.phoneNumber,
            newTestCreate.employee_demographics.countryCode
          ),
        },
      });
    } else {
      setTestCreate({
        ...newTestCreate,
      });
    }
    if (newTestCreate?.tester_name) {
      setOnSelectedTester({ label: newTestCreate?.tester_name, value: newTestCreate?.tester_name });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    // setSite(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, street: val },
      });
    } else {
      let addressObj = {
        street: val.street,
        country: val.country || "United States",
        city: val.city,
        zip: val.zipcode,
        county: val.county,
        state: val.state,
        isoCode: val.isoCode || val.isCode || "US",
      };
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, ...addressObj },
      });
    }
  };

  const orderComparison = (ord, list) => {
    const orderDate = moment(ord.orderDate).format("YYYY-MM-DD");
    const isRecordFind = list.find((f) => moment(f.orderDate).format("YYYY-MM-DD") === orderDate);
    if (isRecordFind) return true;

    return false;
  };

  const handlePhoneNo = (e) => {
    setTestCreate({
      ...newTestCreate,
      employee_demographics: { ...newTestCreate.employee_demographics, phoneNumber: e },
      phoneNumber: e,
    });
  };

  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    let countryCode = "";
    if (phoneNo && type === "Phone") {
      const [cc, ph] = getPhoneNo(data);
      phoneNo = ph;
      countryCode = cc;
    }
    let env = CONFIG.isLive ? "remote" : "localhost";

    let uniqueData = [];
    try {
      let siteID = null;

      if (sites.length > 0) {
        siteID = newTestCreate.siteID;
      }

      let record = await api.getPreRegisterData(phoneNo, env, type, countryCode, siteID);

      uniqueData = Array.from(new Map(record.map((item) => [item["id"], item])).values());

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    if (uniqueData && uniqueData.length === 1) {
      populate(uniqueData[0]);
      return;
    }
    if (uniqueData && uniqueData.length > 1) {
      setRecordData(
        uniqueData.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const { isVaccinated } = record;
    const obj = getFormattedEmployeeObject(record);

    setTestCreate({
      ...newTestCreate,
      employee_demographics: obj.employee_demographics,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      employeeID: obj.employeeID,
    });

    setDisabledVaccination(isVaccinated || false);
  };

  const createTest = async (newTestCreate) => {
    let newTestObj = { ...newTestCreate, createdAt: "" };
    if (newTestCreate.sequenceNo) {
      let sequenceData = await GQAPI.getTestBySequenceNoByAPI(newTestCreate.sequenceNo);
      if (sequenceData == newTestCreate.sequenceNo || sequenceData < newTestCreate.sequenceNo) {
        setError("Sequence No. already used");
        setLoading(false);
        return;
      }
      let barcode = makeBarcode(newTestObj.employee_demographics.idNumber, newTestCreate.sequenceNo);
      newTestObj = { ...newTestObj, barcode };
    }

    // if create new test allocate the new sequenec no
    let newSequenceNo;
    if (!newTestCreate?.sequenceNo) {
      // if bio lab then change the barcode format
      if (CONFIG.isWhiteLabel) {
        let dateOfTest = moment().format("YYMMDD");
        if (newTestObj.createdAt) {
          dateOfTest = moment(newTestObj.createdAt).format("YYMMDD");
        }
        newSequenceNo = await GQAPI.getLabTestAutoNumber(dateOfTest, newTestObj.labID);
      } else {
        newSequenceNo = await GQAPI.getTestAutoNumber();
      }

      const barcode = newSequenceNo.dailyCounter || newSequenceNo.counter;
      newTestObj = { ...newTestObj, sequenceNo: newSequenceNo.counter, barcode };
    }
    // create test
    let testData = {
      ...newTestObj,
      orderID: newTestObj.id,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      tester_name: appContext?.user?.name,
    };

    // Lab Check barcode is active or not
    const checkLab = labs?.find((item) => item.id === testData.labID && item.barCodeProvided);
    if (checkLab && checkLab.id) {
      let barcode = await api.getLabProvidedBarCode(checkLab.id);
      if (barcode) {
        testData = { ...testData, barcode };
      }
    }

    let newTest = await GQAPI.createTest(testData);
    if (JSON.stringify(order.employee_demographics) !== JSON.stringify(newTestCreate.employee_demographics)) {
      await onHandleSave();
    }
    setLoading(false);
    if (newTest) {
      appContext.showSuccessMessage("Test created successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be created");
    }
  };
  
  const onHandleSave = async () => {
    let newTestObj = _.cloneDeep(newTestCreate);
    const [ccCode, phoneNo, completePhone] = getPhoneNo(
      newTestCreate?.employee_demographics?.phoneNumber,
      newTestCreate?.employee_demographics?.countryCode
    );

    const { employee_demographics: emp } = newTestCreate;

    if (!emp.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(emp.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!emp.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(emp.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!emp.email) {
      setError("Email name is required");
      return;
    }

    if (!isValidEmail(emp.email)) {
      setError("Invalid Email");
      return;
    }

    if (!emp.dob) {
      setError("Date of birth is required");
      return;
    }

    if (!emp.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!isValidPhoneWithCode(emp.phoneNumber)) {
      setError("Phone number is invalid");
      return;
    }

    if (!emp.sex) {
      setError("Gender is required");
      return;
    }

    if (!emp.street.trim()) {
      setError("Address is required");
      return;
    }
    if (!emp.country) {
      setError("Country is Required");
      return;
    }
    if (emp.zip === null || !emp.zip) {
      setError("Zipcode is required");
      return;
    }

    if (!zipCodeValidationUS(emp.zip)) {
      setError("Invalid Postal Code");
      return;
    }

    if (!emp.state.trim()) {
      setError("State is required");
      return;
    }
    if (!newTestCreate.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestCreate.siteID) {
      setError(`${stringConstant.site} is required`);
      return;
    }

    if (!newTestCreate.labID) {
      setError("Lab is required");
      return;
    }

    if (!newTestCreate.testsToDo?.test?.length) {
      setError("Test Type is required");
      return;
    }

    if (!newTestCreate.orderDate) {
      setError("Order Date is required");
      return;
    }

    if (
      emp.insuranceCompanyCode &&
      !CUSTOM_INS.includes(emp.insuranceCompanyCode) &&
      (!emp.insurance_number || !emp.insurance_number.trim())
    ) {
      setError(`Insurance Number required for ${emp.insurance_name} Insurance`);
      return;
    }

    newTestObj.testerPhone = appContext.user.phone_number;
    newTestObj.phoneNumber = phoneNo;
    newTestObj.employee_demographics.phoneNumber = phoneNo;
    newTestObj.employee_demographics.countryCode = ccCode;
    newTestObj.employee_demographics.idNumber = "000000000";

    setLoading(true);

    try {
      // save employee data using proxy contact api
      let record = await api.proxyContant({
        ...newTestObj,
        employee_demographics: {
          ...newTestObj.employee_demographics,
          dob: moment(newTestObj.employee_demographics.dob).format("MMDDYYYY"),
        },
        updatedBy: appContext.user.sub,
        updatedByName: appContext.user.name,
        clientID: newTestCreate.clientID,
      });

      if (record && record.eligibilityStatus) {
        record.eligibilityStatus = parseJSONObject(record.eligibilityStatus);
      }

      if (
        newTestObj &&
        newTestObj.employeeID &&
        record?.eligibilityStatus?.message !== "Eligibile" &&
        !CUSTOM_INS.includes(newTestObj.insuranceCompanyCode)
      ) {
        // Check Eligibility When employee exist but eligibility not checked
        const EligRes = await api.checkEligibility({
          ...newTestObj.employee_demographics,
          mdID: record.id,
          updatedBy: appContext.user.sub,
          updatedByName: appContext.user.name,
        });

        if (EligRes) {
          newTestObj.eligibilityStatus = {
            message: EligRes.message,
            apiData: EligRes.apiData,
            createdAt: moment().toISOString(),
          };
        }
      } else if (!newTestObj.eligibilityStatus) {
        newTestObj.eligibilityStatus = record.eligibilityStatus;
      }

      if (!record || record?.message) {
        setError(record?.message || "Fail to save the Employee Record");
        setLoading(false);
        return;
      }

      if (record.id || newTestObj.employeeID) {
        const orderList = await api.getOrderListThroughEmpID(record.id || newTestObj.employeeID);

        const otherOrders = orderList.filter((f) => f.id !== newTestObj.id);
        if (orderComparison(newTestObj, otherOrders)) {
          setError("Same Patient order is already exists");
          setLoading(false);
          return;
        }
      }

      // Covert Order Date into UTC Format
      newTestObj.orderDate = moment(newTestObj.orderDate);

      // set the newly assigned employee id at test level and in demographics
      if (record.id) {
        newTestObj.employeeID = record.id;
      }

      if (newTestObj.isNew) {
        newTestObj.createdBy = appContext.user.sub;
        newTestObj.createdByName = appContext.user.name;

        if (CUSTOM_INS.includes(newTestObj.insuranceCompanyCode)) {
          newTestObj.eligibilityStatus = { message: "Eligibile" };
        }
      }
      // if create new test allocate the new sequenec no

      if (newTestObj.isNew) {
        await GQAPI.createOrderAPI(newTestObj);
        if (newTestObj.requestID) {
          await api.updateUploadRequest(newTestObj.requestID, {});
        }

        appContext.showSuccessMessage("Order Created Successfully");
      } else {
        newTestObj.updatedBy = appContext.user.sub;
        newTestObj.updatedByName = appContext.user.name;
        const res = await GQAPI.updateOrderAPI(newTestObj);
        if (newTestObj.testID) await api.updateTestFromOrder(newTestObj);
        if (res) appContext.showSuccessMessage("Order Updated Successfully");
      }
      handleCloseCreateTest();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const updateTestsToDo = (currentSelection, newSelection) => {
    // Convert the current testsToDo array to a map for easier lookup
    const currentMap = new Map(currentSelection.map((item) => [item.value, item]));

    // Iterate over newSelection and add items that are not in currentMap
    newSelection.forEach((item) => {
      if (!currentMap.has(item.value)) {
        currentMap.set(item.value, item);
      }
    });

    // Convert the map back to an array
    return Array.from(currentMap.values());
  };

  const handleTestType = (e) => {
    setTestCreate({ ...newTestCreate, testsToDo: { test: updateTestsToDo(newTestCreate.testsToDo?.test || [], e) } });
  };

  const handleItemChange = (index, updatedItem) => {
    const updatedTests = newTestCreate.testsToDo.test.map((item, i) =>
      i === index ? { ...item, ...updatedItem } : item
    );

    setTestCreate({
      ...newTestCreate,
      testsToDo: { ...newTestCreate.testsToDo, test: updatedTests },
    });
  };

  const handleRemoveItem = (indexToRemove) => {
    const updatedTests = newTestCreate.testsToDo.test.filter((_, index) => index !== indexToRemove);
    setTestCreate({
      ...newTestCreate,
      testsToDo: { ...newTestCreate.testsToDo, test: updatedTests },
    });
  };
  const handleEditTest = (item, index) => {
    setIctList(item.ictCodes);
    setEditIndexIct(index);
  };

  const showSelectedTests = () => {
    if (!newTestCreate?.testsToDo?.test) {
      return "";
    }

    return (
      <div className="">
        <table className="custom-separate-border-tr table w-100">
          <thead>
            <OrderTestHeader />
          </thead>
          <tbody>
            {newTestCreate.testsToDo?.test.map((c, index) => (
              <OrderTestItem
                key={index}
                item={c}
                index={index}
                onItemChange={handleItemChange}
                showSelection={false}
                onItemRemove={handleRemoveItem}
                handleEditTest={handleEditTest}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const saveDiagnosisCodes = (item) => {
    const copyTestCreate = customCloneDeep(newTestCreate);

    if (
      copyTestCreate &&
      copyTestCreate.testsToDo &&
      copyTestCreate.testsToDo.test &&
      copyTestCreate.testsToDo.test[editIndexIct]
    ) {
      copyTestCreate.testsToDo.test[editIndexIct].ictCodes = item;
    }

    setTestCreate(copyTestCreate);
    setIctList(null);
    setEditIndexIct(null);
  };

  return (
    <>
      {ictList && (
        <DiagnosisAccordionView
          handleClose={() => setIctList(null)}
          handelSaveDiagnosisPanel={saveDiagnosisCodes}
          ictList={ictList}
          setIctList={setIctList}
          isNew={false}
        />
      )}

      <Modal
        show
        animation={true}
        onHide={handleCloseCreateTest}
        size="xl"
        style={{ paddingLeft: "0" }}
        centered
        className="seperateModal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {!newTestCreate.isNew ? "Update Order" : "Create Order"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="tset-clent-edit createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">First Name: </label>
              <input
                className="modalInput"
                placeholder="First Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.firstName}
                onChange={(e) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      firstName: makeAlphabetInput(e.target.value),
                    },
                  });
                }}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.firstName, "FirstName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Last Name: </label>
              <input
                className="modalInput"
                placeholder="Last Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.lastName}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      lastName: makeAlphabetInput(e.target.value),
                    },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.lastName, "LastName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Email: </label>
              <input
                className="modalInput"
                placeholder="Email"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.email}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      email: convertToLower(e.target.value),
                    },
                    email: convertToLower(e.target.value),
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.email, "Email")}
              />
            </div>
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">DoB: </label>
              <input
                className="modalInput"
                type="date"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate.employee_demographics?.dob}
                placeholder="Date Of Birth"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(e.target.value).format("YYYY-MM-DD"),
                    },
                  })
                }
                min={moment("1900-01-01").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.dob, "DOB")}
              />
              {/* <SingleDatePicker
                onApply={(event, picker) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(picker.startDate).format("MMDDYYYY"),
                    },
                  });
                }}
                value={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                startDate={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                currentDate={true}
              /> */}
            </div>

            <div className="form-group-wrapper phoneInput">
              <label className="modalLineHeaders">Phone #: </label>
              <PhoneNoInput handleChange={handlePhoneNo} value={newTestCreate?.employee_demographics?.phoneNumber} />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.phoneNumber, "Phone")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Gender: </label>
              <div className="d-flex justify-content-between w-100">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "M"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "M",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Male</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "F"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "F",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Female</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "X"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "X",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Not Specified</span>
                </span>
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">DL/Passport #: </label>
              <input
                className="modalInput"
                placeholder="License/Passport"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                maxLength={16}
                value={newTestCreate?.employee_demographics?.idNumber}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, idNumber: e.target.value },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.idNumber, "License")}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Address: </label>
              <AutoCompleteAddress
                value={newTestCreate?.employee_demographics?.street}
                cssClass={"modalInput"}
                handleChange={handleUpdateAddress}
              />
              {/* <input
                ref={ref}
                value={newTestCreate?.employee_demographics?.street}
                placeholder="Enter Address"
                className="modalInput"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      street: validSpace(e.target.value),
                    },
                  })
                }
              /> */}
            </div>
            {defaultConfig.isShowRoom && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{defaultConfig.roomno} : </label>
                <input
                  className="modalInput"
                  placeholder={defaultConfig.roomno}
                  value={newTestCreate?.employee_demographics?.street2}
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        street2: validSpace(e.target.value),
                      },
                    })
                  }
                />
              </div>
            )}
            {defaultConfig.isShowFloor && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Floor No:</label>
                <input
                  className="modalInput"
                  placeholder="Floor No"
                  value={newTestCreate?.floorNo}
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      floorNo: validSpace(e.target.value),
                    })
                  }
                />
              </div>
            )}

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">City: </label>
              <input
                className="modalInput"
                placeholder="City "
                value={newTestCreate?.employee_demographics?.city}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, city: validSpace(e.target.value) },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Zip: </label>
              <input
                className="modalInput"
                placeholder="Zip"
                maxLength={10}
                value={newTestCreate?.employee_demographics?.zip}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) val = val.toUpperCase();
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      zip: newTestCreate.employee_demographics.isoCode === "US" ? formatZip(val) : validSpace(val),
                    },
                  });
                }}
              />
            </div>
            {loading && <Loader />}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">State: </label>
              <input
                className="modalInput"
                placeholder="State"
                maxLength={35}
                value={newTestCreate?.employee_demographics?.state}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      state: e.target.value.toUpperCase(),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Country: </label>
              <Select
                options={countriesOption}
                blurInputOnSelect={true}
                defaultValue={{ label: "United States", value: "United States" }}
                menuPlacement="auto"
                className="w-100 siteSelector"
                placeholder="Select Country"
                value={
                  newTestCreate.employee_demographics.country
                    ? {
                        label: newTestCreate.employee_demographics.country,
                        value: newTestCreate.employee_demographics.country,
                      }
                    : null
                }
                onChange={(e) => {
                  const country = countryListAllIsoData.find((f) => f.name === e.value) || {
                    code: newTestCreate.employee_demographics.isoCode || "US",
                  };
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      country: e.value,
                      isoCode: country.code,
                    },
                  });
                }}
              />
            </div>

            {clients.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Client </label>
                <Select
                  className="w-100 siteSelector"
                  options={clients.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={
                    newTestCreate.clientID
                      ? {
                          label: newTestCreate.clientName,
                          value: newTestCreate.clientID,
                        }
                      : null
                  }
                  placeholder="Select Client"
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      clientID: e.value,
                      clientName: e.label,
                      siteID: null,
                      site_name: null,
                    })
                  }
                />
              </div>
            )}
            {getSiteOptions.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{stringConstant.site} </label>
                <Select
                  className="w-100 siteSelector"
                  options={getSiteOptions}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder={`Select ${stringConstant.site}`}
                  value={newTestCreate.siteID ? getSiteOptions.find((f) => f.value === newTestCreate.siteID) : null}
                  onChange={(e) => setTestCreate({ ...newTestCreate, siteID: e.value, site_name: e.label })}
                />
              </div>
            )}
            {labs.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab </label>
                <Select
                  className="w-100 siteSelector"
                  options={labs?.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder="Select Lab"
                  value={
                    newTestCreate.labID
                      ? {
                          label: newTestCreate.labName,
                          value: newTestCreate.labID,
                        }
                      : null
                  }
                  onChange={(e) => setTestCreate({ ...newTestCreate, labID: e.value, labName: e.label })}
                />
              </div>
            )}
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Test Date: </label>

              <input
                className="modalInput"
                type="datetime-local"
                value={newTestCreate?.orderDate}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setTestCreate({ ...newTestCreate, orderDate: e.target.value })}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Payer Information: </label>
              <Select
                className="w-100 siteSelector"
                options={clientInsurances}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={
                  newTestCreate.employee_demographics?.insurance_name
                    ? {
                        label: newTestCreate.employee_demographics?.insurance_name,
                        value: newTestCreate.employee_demographics?.insurance_number,
                      }
                    : ""
                }
                placeholder="Select Payer"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      insurance_name: e.label,
                      insuranceCompanyCode: e.value,
                    },
                  })
                }
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Insurance Number: </label>
              <input
                className="modalInput"
                placeholder="Insurance Number"
                value={newTestCreate?.employee_demographics?.insurance_number}
                onChange={(e) => {
                  let val = e.target.value;
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      insurance_number: convertToUpper(val),
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-wrapper w-100">
              <label className="modalLineHeaders">Instructions: </label>
              <div className="d-flex justify-content-between align-items-center w-100 borderless-checkbox">
                <FilledCheckbox
                  label={"STAT"}
                  id={"stat"}
                  isChecked={newTestCreate.employee_demographics?.stat}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: { ...newTestCreate.employee_demographics, stat: e.target.checked },
                    })
                  }
                />
                <FilledCheckbox
                  label={"FAX"}
                  id={"fax"}
                  isChecked={newTestCreate.employee_demographics?.fax}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: { ...newTestCreate.employee_demographics, fax: e.target.checked },
                    })
                  }
                />
                <FilledCheckbox
                  label={"CALL"}
                  id={"call"}
                  isChecked={newTestCreate.employee_demographics?.call}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: { ...newTestCreate.employee_demographics, call: e.target.checked },
                    })
                  }
                />
                <FilledCheckbox
                  label={"FASTING"}
                  id={"fasting"}
                  isChecked={newTestCreate.employee_demographics?.fasting}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: { ...newTestCreate.employee_demographics, fasting: e.target.checked },
                    })
                  }
                />
                <FilledCheckbox
                  label={"RANDOM"}
                  id={"random"}
                  isChecked={newTestCreate.employee_demographics?.random}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: { ...newTestCreate.employee_demographics, random: e.target.checked },
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">External Reference ID: </label>
              <input
                className="modalInput"
                placeholder="External Reference ID"
                value={newTestCreate?.externalID}
                onChange={(e) => setTestCreate({ ...newTestCreate, externalID: e.target.value })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Tests </label>
              <Select
                className="w-100 siteSelector"
                options={testOptions}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Select Tests"
                isMulti
                value={null}
                onChange={(e) => handleTestType(e)}
              />
            </div>

            {showPopOver && recordData.length > 1 && (
              <EmployeeSearchRecord
                data={recordData}
                handleSelect={populate}
                handleClose={() => {
                  setShowPopOver(false), setSelectSearchData(null);
                }}
                setSelectSearchData={setSelectSearchData}
                selectSearchdata={selectSearchdata}
              />
            )}
          </div>
          {showSelectedTests()}
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          <div>
            {/* {!newTestCreate.isNew && newTestCreate.status === "Pending" && (
              <Button
                style={{ marginBottom: 10 }}
                variant="primary"
                disabled={loading}
                className="modalButtons mx-2"
                onClick={() => createTest(newTestCreate)}
              >
                Create Test
              </Button>
            )} */}
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              disabled={loading}
              className="modalButtons"
              onClick={onHandleSave}
            >
              {!newTestCreate.isNew ? "Update" : "Create"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderTestModal;
