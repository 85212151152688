import React from "react";
import "./TestDetailsRangeView.css";
import Icon from "components/Icon";

const TestDetailsRangeView = ({ onChange, data, handelRemoveView }) => {
  const rangesArr = ["Low", "Normal", "High", "Panic", "Repeat"];

  return (
    <div className="range-view-container">
      <table className="range-view-table">
        <thead>
          <tr>
            {/* <th className="range-view-th" colSpan="2">
              Age Range
            </th> */}

            {rangesArr.map((range) => (
              <th className="range-view-th" colSpan="2">
                {range}
              </th>
            ))}
            {/* <th className="range-view-th">Action</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td className="range-view-td text-center">Start</td>
            <td className="range-view-td text-center">End</td> */}
            {rangesArr.map(() => (
              <>
                <td className="range-view-td text-center">Low</td>
                <td className="range-view-td text-center">High</td>
              </>
            ))}

            {/* <td style={{ border: "none" }}></td> */}
          </tr>

          {data.map(
            (
              {
                id,

                NormalLow,
                NormalHigh,

                HighLow,
                HighHigh,

                PanicLow,
                PanicHigh,

                RepeatLow,
                RepeatHigh,

                LowLow,
                LowHigh,
              },
              index
            ) => {
              return (
                <tr key={index}>
                  {/* <td>
                    <input
                      className="range-view-input"
                      name="AgeStart"
                      value={AgeStart}
                      onChange={(e) => onChange(e, index)}
                      type="number"
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="AgeEnd"
                      value={AgeEnd}
                      onChange={(e) => onChange(e, index)}
                      type="number"
                    />
                  </td> */}

                  <td>
                    <input
                      className="range-view-input"
                      name="LowLow"
                      value={LowLow}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="LowHigh"
                      value={LowHigh}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>

                  <td>
                    <input
                      className="range-view-input"
                      name="NormalLow"
                      value={NormalLow}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="NormalHigh"
                      value={NormalHigh}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>

                  <td>
                    <input
                      className="range-view-input"
                      name="HighLow"
                      value={HighLow}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="HighHigh"
                      value={HighHigh}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>

                  <td>
                    <input
                      className="range-view-input"
                      name="PanicLow"
                      value={PanicLow}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="PanicHigh"
                      value={PanicHigh}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>

                  <td>
                    <input
                      className="range-view-input"
                      name="RepeatLow"
                      value={RepeatLow}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      className="range-view-input"
                      name="RepeatHigh"
                      value={RepeatHigh}
                      onChange={(e) => onChange(e, index)}
                    />
                  </td>

                  {/* <td className="text-center">
                    <Icon
                      iconType={"binIcon"}
                      containerClass={"cursor-pointer"}
                      innerClass={""}
                      imageClass={"delete-icon "}
                      handleClick={() => handelRemoveView(id)}
                    />
                  </td> */}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TestDetailsRangeView;
