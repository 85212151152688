import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import api from "api";
import ImageInput from "components/Input/ImageInput";
import moment from "moment";
import { Storage } from "aws-amplify";
import { CSVReader } from "react-papaparse";
import { exportToCalRedie, SYSTEM_TIME_ZONE, removeSpecialCharacters } from "utils";
import { CONFIG, TEST_TYPES } from "constant";
import InputField from "components/InputField/InputField";
import Select from "react-select";
import { UPLOAD_CSV_FILE } from "constant";
import { RevokeTokenRequestFilterSensitiveLog } from "@aws-sdk/client-cognito-identity-provider";
import { v4 as uuidv4 } from "uuid";

const TestScheduleModal = (props) => {
  const { handleClose, employees, user, uploadCallType, showSuccessMessage, showErrorMessage, appContext } = props;
  const [detail, setDetail] = useState({ testType: TEST_TYPES, date: moment().add(1, "day").format("YYYY-MM-DD") });
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [error, setError] = useState("");

  const isDuplicateRecord = (list, item) => {
    if (list.length === 0 || !item) return null;

    return list.find(
      (f) =>
        removeSpecialCharacters(f.first_name) === removeSpecialCharacters(item.first_name) &&
        removeSpecialCharacters(f.last_name) === removeSpecialCharacters(item.last_name) &&
        removeSpecialCharacters(f.dob) === removeSpecialCharacters(item.dob) &&
        removeSpecialCharacters(f.gender) === removeSpecialCharacters(item.gender)
    );
  };

  const handleOnDrop = async (arr) => {
    let patients = [];
    setLoading(true);

    const site = appContext.sites?.find((f) => f.id === CONFIG.siteID);
    const client = appContext.clients?.find((f) => f.id === CONFIG.clientID);
    for (const { data } of arr) {
      let status = "pass";
      if (uploadCallType === UPLOAD_CSV_FILE.employee) {
        if (
          !data.first_name ||
          !data.last_name ||
          !data.dob ||
          !data.gender ||
          !data.room_number ||
          !data.p_insurance ||
          !data.p_insurance_id ||
          !data.referring_provider
        )
          continue;
      } else {
        if (!data.first_name || !data.last_name || !data.dob || !data.gender) continue;
      }

      if (isDuplicateRecord(patients, data)) {
        status = "duplicate";
      }

      patients.push({
        ...data,
        clientID: CONFIG.clientID,
        siteID: CONFIG.siteID,
        clientName: client?.name,
        siteName: site?.contact_name,
        localID: uuidv4(),
        timezone: SYSTEM_TIME_ZONE,
        createdAt: moment().toISOString(),
        createdBy: user.sub,
        createdByName: user.name,
        status,
        uploadCallType,
      });
    }
    if (patients.length > 0) {
      setRecords(patients);
    }
    setLoading(false);
  };

  const handleOnError = (err) => {
    console.log(err);
    setError(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
    setRecords([]);
  };

  const isValidInput = () => {
    if (uploadCallType !== UPLOAD_CSV_FILE.manualtest && records.length === 0) {
      setError("Please Upload CSV File !");
      return false;
    }
    if (uploadCallType === UPLOAD_CSV_FILE.manualtest) {
      if (!detail.date) {
        setError("Please Select the Test Date");
        return false;
      }
      if (!detail.testType) {
        setError("Please Select the Test Type");
        return false;
      }
    }
    return true;
  };

  const onHandleConfirm = async () => {
    if (!isValidInput()) {
      return;
    }

    setLoading(true);

    let requestRes = null;
    try {
      if (uploadCallType !== UPLOAD_CSV_FILE.manualtest) {
        const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_employee_import_${CONFIG.envType}.csv`;

        const res = await api.createUploadResult({
          title: `Upload ${uploadCallType}`.toUpperCase(),
          fileName,
          fileType: "csv",
          createdByName: user?.name,
          createdBy: user?.sub,
          uploadCallType,
        });

        const params = records.map((d) => ({ ...d, requestID: res.id }));
        await api.uploadPatientTestSchedule(params, fileName);

        requestRes = { ...res, Items: [] };
        // showSuccessMessage(
        //   uploadCallType === UPLOAD_CSV_FILE.employee
        //     ? `Total of  ${records.length} ${
        //         records.length > 1 ? "Patients  have" : "Patient has"
        //       } been uploaded successfully`
        //     : `Total of  ${records.length} ${
        //         records.length > 1 ? "Tests  have" : "Test has"
        //       } been uploaded for scheduling`
        // );
      } else {
        const empIds = employees.map((e) => ({ employeeID: e.mdID }));
        await api.assignedTestSchedule(empIds, detail, user);
        showSuccessMessage(`Successfully Assigned Test Schedule`);
      }

      setLoading(false);
      handleClose(requestRes);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      showErrorMessage(err.message);
      handleClose();
    }
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {uploadCallType === UPLOAD_CSV_FILE.employee ? "Upload Patient Data" : "Upload Patient Test Schedule"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {uploadCallType !== UPLOAD_CSV_FILE.manualtest && (
          <div className="form-group-wrapper d-block md-customer-reader">
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              config={{
                header: true,
                skipEmptyLines: true,
                skipLinesWithEmptyValues: true, // Skip lines with empty values
              }}
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here.</span>
            </CSVReader>
          </div>
        )}
        {uploadCallType === UPLOAD_CSV_FILE.manualtest && (
          <>
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Test Date: </label>

              <input
                className="modalInput"
                type="date"
                value={detail?.date}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setDetail({ ...detail, date: e.target.value })}
                min={moment().format("YYYY-MM-DD")}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Select Test Type: </label>
              <Select
                className="w-100 siteSelector"
                options={TEST_TYPES}
                blurInputOnSelect={true}
                defaultValue={null}
                isMulti
                menuPlacement="auto"
                value={detail?.testType}
                placeholder="Select Test Type"
                onChange={(e) =>
                  setDetail({
                    ...detail,
                    testType: e,
                  })
                }
              />
            </div>
          </>
        )}
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => onHandleConfirm()}>
          {uploadCallType === UPLOAD_CSV_FILE.manualtest ? "Schedule" : "Upload"}
        </Button>
      </Modal.Footer>

      {loading && <Loader />}
    </Modal>
  );
};

export default TestScheduleModal;
