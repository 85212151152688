import API from "api";
import Filter from "components/Filter";
import Icon from "components/Icon";
import { AppContext } from "context/app-context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import Loader from "../../components/Loader/Loader";
import { calculateTdWidth } from "../../utils";
import TableRow from "./TableRow";
import TestDetailsModal from "./TestDetailsModal";
import { createTestsDetails } from "api";
import { CONFIG } from "constant";
import TestResultSetting from "./TestResultSetting";
import LabBarCodeAlertSetting from "./LabBarCodeAlertSetting";
import { updateTestsDetails } from "api";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [testSources, setTestSources] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [testPanelData, setTestPanelData] = useState(null);

  const searchTerms = ["Name"];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 50, 5);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const [editObj, setEditObj] = useState(null);

  useEffect(() => {
    getList();
    getSourceType();
  }, []);

  const getSourceType = async () => {
    const sources = await API.getTestSources();
    setTestSources(sources);
  };

  const getList = async () => {
    setList(await API.getTestTypes());
  };

  const handleEdit = (item) => {
    setEditObj(item);
  };

  const handleSave = async (item) => {
    const obj = { ...item };
    setEditObj(null);
    setLoading(true);
    try {
      if (item.isNew) {
        await API.addTestTypes(obj);
      } else {
        await API.updateTestTypes(obj);
      }
      setLoading(false);
      setList(await API.getTestTypes());
      appContext.showSuccessMessage("Test Type updated successfully");
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = async (newSource) => {
    setShowSourceModal(null);

    if (newSource) {
      appContext.showSuccessMessage("Source added successfully");
      setTestSources([...testSources, newSource]);
    }
  };

  const handleCloseTestTypeModal = async () => {
    setEditObj(null);
    setList(await API.getTestTypes());
  };

  const handleSaveTestDetail = async (testDetailsData) => {
    const { isNew } = testDetailsData;
    const action = isNew ? "create" : "update";

    try {
      const result = await (isNew ? createTestsDetails : updateTestsDetails)(testDetailsData);

      if (result?.data) {
        setEditObj((prevEditObj) => ({
          ...prevEditObj,
          targetRanges: prevEditObj?.targetRanges?.map((item) => (item.id === result.data.id ? result.data : item)),
        }));
      }

      setOpenDetailModal(false);
      setTestPanelData(null);
    } catch (error) {
      console.error("Error", error);
      appContext.showErrorMessage(`Unable to ${action} panel test.`);
    }
  };

  const handleSetTestPanelData = (data) => {
    setEditObj((prevEditObj) => {
      const exists = prevEditObj?.targetRanges?.some((item) => item.id === data.id);
      return {
        ...prevEditObj,
        targetRanges: exists
          ? prevEditObj.targetRanges.map((item) => (item.id === data.id ? data : item))
          : [...(prevEditObj.targetRanges || []), data],
      };
    });

    setTestPanelData(data);
  };

  return (
    <>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="12">
                      <Card.Title>
                        <div>Test Settings</div>
                        <div className="shows-filter-wrapper">
                          <div className="shows-filter-inner">
                            <Icon
                              handleClick={() => setShowFilter(!showFilter)}
                              title={"Filter"}
                              label={"Filter"}
                              iconType={"filter"}
                            />

                            <Icon
                              handleClick={() =>
                                setEditObj({
                                  isNew: true,
                                })
                              }
                              title={"Create Test Type"}
                              label={"Create"}
                              iconType={"createIcon"}
                            />
                           
                          </div>
                        </div>
                        <div>
                          {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                        </div>
                      </Card.Title>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="separate-border-tr">
                      <thead ref={componentRef}>
                        <tr>
                          <th></th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="name">Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Code</span>
                            </div>
                          </th>

                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Result Methodology</span>
                            </div>
                          </th>

                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Category</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Sources</span>
                            </div>
                          </th>
                          {/* <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Total Time">Total Time</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="First Alert">First Alert</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Second Alert">Second Alert</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                            }}
                            className="border-0"
                          >
                            <div>
                              <span title="Second Alert">Valid duration</span>
                            </div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, i) => {
                          return <TableRow key={i} item={item} handleEdit={handleEdit} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* {CONFIG.isWhiteLabel ? <TestResultSetting /> : <LabBarCodeAlertSetting />} */}
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {editObj && (
        <TestTypeModal
          item={editObj}
          handleSave={handleSave}
          handleClose={handleCloseTestTypeModal}
          testSources={testSources}
          openDetailModal={() => setOpenDetailModal(true)}
          mainTests={list}
          setTestPanelData={(data) => handleSetTestPanelData(data)}
        />
      )}
      {openDetailModal && (
        <TestDetailsModal
          data={testPanelData}
          handleSaveTestDetail={handleSaveTestDetail}
          handleClose={() => {
            setOpenDetailModal(false);
            setTestPanelData(null);
          }}
        />
      )}

     
    </>
  );
};

export default TestTypeSetting;
