import {
  AMAZON_CLIENT_RESULT,
  CONFIG,
  CUSTOM_INS,
  ELIGIBILITY_STATUS_COLOR,
  ELIGIBILITY_STATUS_VALUE,
  EXPORT_TO_CALREDIE,
  EXPORT_TO_CALREDIE_RESULT,
  LAB_ORDER_STATUS,
  NOT_INC_KEYS,
  PRE_REG_PERSONALIZE,
  RECEIVED_BYLAB,
  RESULT_COLOR,
  RESULT_PDF,
  TEST_TYPE_VALUE,
  defaultPersonalization,
  ALTA_ID,
  NEW_DISCOVERY_ID,
} from "constant";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { ORDER_PERSONALIZE } from "constants/personalization";
import { COMPLETED_RESULT_PERSONALIZE } from "constants/personalization";
import { PENDING_RESULT_PERSONALIZE } from "constants/personalization";
import { LAB_CLIENT_PERSONALIZE } from "constants/personalization";
import { LAB_MEMBER_PERSONALIZE } from "constants/personalization";
import FileSaver from "file-saver";
import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import moment from "moment";
import Papa from "papaparse";
import postalCodes from "postal-codes-js";
import { jsonToCSV } from "react-papaparse";
import { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";
import { customIsJsonString } from "util/customLodash";
const { v4: uuidv4 } = require("uuid");

export const SYSTEM_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatPhoneNumber = (phone_number_value, ccCode, viewAbel) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = formatPhoneNumberIntl(phone_number);
    if (viewAbel) return phone;
    return phone.replace(/\s/g, "");
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const formatPhone = (phone_number_value, ccCode) => {
  try {
    if (!phone_number_value) return "";

    let phone_number = phone_number_value?.replace(/[^0-9+]/g, "");
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = parsePhoneNumber(phone_number);
    return phone?.formatNational() || "";
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const formatPhoneWithOutSpaces = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    return phone_number;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const processAndAddColumnsIntoCsvFiles = async (files, additionalObj, uploadStatus) => {
  return await Promise.all(
    files.map((file, index) => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: (results) => {
            const modifiedData = results.data
              .filter((row) => Object.keys(row).length > 0) // Filter out completely empty rows
              .map((row) => {
                let newRow = { ...row, ...additionalObj,  localID: uuidv4(), fileID: uploadStatus[index].id };
                return newRow;
              });
            resolve({ index, items: modifiedData });
          },
          header: true,
        });
      });
    })
  );
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return new AsYouType("US").input(d);
  }
  return phone;
};

export const getValidName = (name) => {
  if (!name) return "";
  return capitalizeLetter(name.replace(/  +/g, " "));
};

export const isValidPhone = (phone) => isValidNumber(phone, "US");

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};
export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);
  console.log("zipValid", validate, code, zip);
  return typeof validate !== "string" ? validate : false;
};
export const zipCodeValidationUS = (zip) => {
  if (!zip || !zip.trim()) return false;
  const zipCode = zip.replace(/[^0-9]/g, "");
  if (zipCode.length === 5 || zipCode.length === 9) return true;
  return false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};

export const parseBooleanValue = (val) => (val ? "Yes" : "No");

export const parseIntValue = (val) => {
  if (typeof variable === "undefined" || variable === null) {
    return "0";
  }
  parseInt(val).toString();
};

export const parseBooleanVal = (val) => {
  if (val) {
    if (typeof val === "string" && val === "false") return false;
    return true;
  }
  return false;
};

export const isValidIDNumber = (val) => {
  if (!val) return false;
  if (new RegExp(/^(?=.*[0-9])([a-zA-Z0-9]{8,})+$/).test(val)) {
    return true;
  }
  return false;
};

export const convertToUpper = (val) => {
  if (val) return val.toUpperCase();
  return val;
};

export const convertToLower = (val) => {
  const trimmedVal = val.trim();
  return trimmedVal ? trimmedVal.toLowerCase() : "";
};

export const capitalizeLetter = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    if (word.length <= 3) {
      return word.toUpperCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
};

export const getCrewsName = (empDemos) => {
  // console.log(typeof empDemos);
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};

export const testAppVersion = (test) => {
  if (test.appVer) return test.appVer;

  const val = test.sr_no;
  if (!val) return "-";
  if (!isNaN(val)) return "-";
  try {
    const obj = typeof val === "string" ? JSON.parse(val) : val;
    if (obj.version) {
      return obj.version;
    }
  } catch (error) {
    console.log("Error ", error);
  }
  return "-";
};

export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};

export const setProceduresFromDate = (claimToBeEdited, date) => {
  const claim = { ...claimToBeEdited };
  const proc_arr_to_save = claim.proc_array || [];
  for (let i = 0; i < proc_arr_to_save.length; i++) {
    const obj = { ...proc_arr_to_save[i] };
    obj.from_date = date;
    proc_arr_to_save.splice(i, 1, obj);
  }
  Object.assign(claimToBeEdited, {
    proc_array: proc_arr_to_save,
    from_date_1: date,
  });
  return claimToBeEdited;
};

export const parseErrorMessageString = (input) => {
  if (!input) return [];
  const errorMessages = input.split("|");
  let parsedData = [];

  errorMessages.forEach((errorMessage) => {
    const [code, _, field, message] = errorMessage.split(":");
    const obj = { code, field, message };
    if (errorMessage.includes("(resolve)")) {
      Object.assign(obj, { resolve: "(resolve)" });
    }
    parsedData.push(obj);
  });

  if (parsedData.length === 1 && !parsedData[0].field && !parsedData[0].messages) {
    const errorMessages = input.split(",");
    const nestedPushed = [];
    errorMessages.forEach((errorMessage) => {
      const [code, _, field, message] = errorMessage.split(":");
      const obj = { code, field, message };
      if (errorMessage.includes("(resolve)")) {
        Object.assign(obj, { resolve: "(resolve)" });
      }
      nestedPushed.push(obj);
    });
    parsedData = nestedPushed;
  }

  return parsedData;
};

export const createErrorMessageString = (input) => {
  if (!input || !Array.isArray(input) || input.length === 0) return "";

  const errorMessages = input.map((error) => {
    if (!error) return "";
    const { code, field, message, resolve } = error;
    return `${code ? `${code}:` : ""}${field ? `${field}:` : ""}${message || ""}${resolve || ""}`;
  });

  return errorMessages.join("|");
};

export const isJSONString = (string) => {
  if (!string || !string.trim()) return false;
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const getDiagonosisCode = (str) => {
  return isJSONString(str) ? JSON.parse(str).code : str || "";
};

export const getDiagonosisInternalCodeValue = (str) => {
  return isJSONString(str) ? JSON.parse(str).internalCode || JSON.parse(str).code : str || "";
};

export const getIntVal = (val) => {
  if (!val) return 0;
  try {
    return parseInt(val);
  } catch (err) {
    return 0;
  }
};

export const parseStringIntoFloat = (str) => {
  if (!str || !str.trim()) return;
  return str.replace(/[^0-9.]/g, "");
};

export const formatTest = (testData, clients, employees) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;

  let resultDetails =
    typeof testData?.resultDetails === "string" ? JSON.parse(testData.resultDetails) : testData.resultDetails;

  let name;
  let firstName;
  let lastName;
  let dob;
  let isVaccinated;
  let employeeType;
  let preRegTest;
  let labStatusTest;
  let transitionStatus;
  let note;

  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    dob = formatDateOfBirthDOB(demos.dob);
    isVaccinated = demos.isVaccinated;
    employeeType = demos.employeeType;
    note = demos.clinicalcomment;
  }
  if (typeof testData.labOrderDetail === "string") {
    testData.labOrderDetail = JSON.parse(testData.labOrderDetail);
  }
  if (employees) {
    const emp = employees.find((e) => e.id === testData.employeeID);

    if (emp) {
      employeeType = emp.employeeType;
    } else {
      console.log("Employee not found ", testData);
    }
  }

  if (typeof employeeType === "undefined") {
    if (testData?.employeeType) {
      employeeType = testData?.employeeType;
    }
  }

  const test = { ...testData };
  preRegTest = test.createSource === "PR" ? true : false;
  labStatusTest = LAB_ORDER_STATUS[test.labOrderDetail?.status] || "0";
  Object.assign(test, { preRegTest, labStatusTest });
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  if (testData.result) {
    test.pcrStatus = RECEIVED_BYLAB;
  }

  if (test.test_type === "PCR") {
    if (test.scanByLabUserName) {
      transitionStatus = "Accepted";
    } else if (test.scanByTesterName) {
      transitionStatus = "In-transit";
    } else {
      transitionStatus = "Pending";
    }
  } else {
    transitionStatus = "";
  }

  if (!CONFIG.isWhiteLabel) {
    test.status =
      testData.status?.toLowerCase() === "pending" && testData.test_type === "PCR" && !testData.result
        ? "New"
        : testData.status;
  } else {
    if (testData.status?.toLowerCase() === "sent") {
      test.status = "Processing";
    }

    if (testData.result) {
      test.status = "Completed";
    }
  }
  test.pcrStatus = getValidDep(test.pcrStatus);
  test.test_type = testData.test_type === "Other" ? "Rapid PCR" : testData.test_type;

  let receivedDate = testData.createdAt;

  try {
    const arr = JSON.parse(test.sr_no);
    receivedDate = arr.length > 1 ? arr[1]?.date : arr[0]?.date;
    if (arr.length === 1) {
      dateOfReceive = moment(arr[0]?.date).add("30", "minutes").toISOString();
    }
  } catch (error) {}

  return {
    ...test,
    resultDetails,
    note,
    name,
    firstName,
    lastName,
    dob,
    sex: demos?.sex,
    street: demos?.street,
    street2: demos?.street2,
    state: demos?.state,
    city: demos?.city,
    idNumber: demos?.idNumber,
    zip: demos?.zip,
    isReview: demos?.isReview,
    employee_demographics: demos,
    isVaccinated,
    employeeType,
    phoneNumber: formatPhoneNumber(test.phoneNumber, demos?.countryCode),
    transitionStatus,
    receivedDate,
  };
};

export const formatOrders = (orderData) => {
  let demos =
    typeof orderData.employee_demographics === "string"
      ? JSON.parse(orderData.employee_demographics)
      : orderData.employee_demographics;

  let testsToDo = typeof orderData.testsToDo === "string" ? JSON.parse(orderData.testsToDo) : orderData.testsToDo;

  let eligibilityStatus =
    typeof orderData.eligibilityStatus === "string"
      ? JSON.parse(orderData.eligibilityStatus)
      : orderData.eligibilityStatus;

  const isArray = testsToDo && Array.isArray(testsToDo);
  const testList = (isArray ? testsToDo : testsToDo?.test) || [];
  const test = testList[0] || {};
  const { result, barcode, testID } = test;

  const testType = testList.length > 1 ? "Multi" : testList[0] ? testList[0].value : null;

  return {
    ...orderData,
    ...demos,
    test_type: testType,
    testsToDo,
    eligibilityStatus,
    testID,
    result,
    barcode,
    employee_demographics: demos,
  };
};

export const calculateStringSimilarity = (str1, str2) => {
  const matrix = [];

  if (!str1 && !str2) return 0;

  for (let i = 0; i <= str1.length; i++) {
    matrix[i] = [i];
    for (let j = 1; j <= str2.length; j++) {
      if (i === 0) {
        matrix[i][j] = j;
      } else {
        const cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
      }
    }
  }

  const maxLen = Math.max(str1.length, str2.length);
  const distance = matrix[str1.length][str2.length];
  return 1 - distance / maxLen;
};

export const isSimilarPayer = (provider, payerName) => {
  const similarityThreshold = 0.8;
  const { label, payer_alt_names } = provider;

  if (label.toLowerCase() === payerName.toLowerCase()) {
    return true;
  }

  if (calculateStringSimilarity(label.toLowerCase(), payerName.toLowerCase()) > similarityThreshold) {
    return true;
  }

  if (payer_alt_names) {
    const similarAltNames = payer_alt_names.some(
      (altName) =>
        altName.alt_payer_name &&
        calculateStringSimilarity(altName.alt_payer_name.toLowerCase(), payerName.toLowerCase()) > similarityThreshold
    );

    if (similarAltNames) {
      return true;
    }
  }

  return false;
};

export const formatCurrency = (val) => {
  if (!val) return "$0.00";
  const obj = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return obj.format(val);
};

export const formatExternalTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  let phoneNumber = testData.phoneNumber;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    phoneNumber = `${demos.countryCode}${demos.phoneNumber}`;
  }

  const testType = TEST_TYPE_VALUE[testData.test_type];

  return { ...testData, phoneNumber, name, firstName, lastName, test_type: testType || testData.test_type };
};

export const getAMPM = (date) => {
  if (!date) return "";
  const hours = new Date(date).getHours();
  return hours >= 12 ? "PM" : "AM";
};

export const toLowerCase = (val) => {
  if (val) val.toLowerCase();
  return val;
};

export const validSpace = (value) => {
  if (!value) return "";
  return value.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  return true;
  if (!name) return false;
  if (new RegExp(/^(?!.*([a-z])\1{2})[A-Za-z ]{2,75}$/i).test(name) && window.navigator.language === "en-US") {
    return true;
  }
  if (window.navigator.language === "ja-JP" && new RegExp(/^.{2,75}$/i).test(name)) return true;
  return true;
};
export const isValidState = (state) => {
  return true;
  if (!state) return false;
  if (new RegExp(/^[A-Z]{2}$/).test(state)) {
    return true;
  } else return false;
};
export const isValidIdNum = (id) => {
  return true;
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidDigits = (num) => {
  if (!num) return false;
  if (new RegExp(/^[1-9]\d*$/g).test(num)) {
    return true;
  }
  return false;
};
export const isValidBarcodeNumber = (num) => {
  if (!num) return false;
  if (new RegExp(/^\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(?!0000)\d{4}$/).test(num)) {
    return true;
  }
  return false;
};

export const testTypeValid = (test, TestTypeDuaration) => {
  if (!test) return "";
  if (!TestTypeDuaration) return "";
  const { employee_demographics } = test;
  const findval = JSON.parse(TestTypeDuaration).find((c) => c.testType === employee_demographics.externalTestType);
  if (!findval) return "";
  const date = new Date(test.createdAt);
  date.setHours(date.getHours() + parseInt(findval.hours));
  if (date <= new Date()) {
    return "EXPIRED";
  }
  return moment(date).format("MM-DD-YYYY HH:mm:ss");
};
export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};

export const getDemoGraphics = (test) => {
  if (!test) return;

  const demos = parseJSONObject(test.employee_demographics);
  const ins = parseJSONObject(demos.insuranceDetails);
  const eligStatus = parseJSONObject(demos.eligibilityStatus);
  const insDetails = parseInsDetails(ins, demos);

  return {
    ...demos,
    insuranceDetails: insDetails,
    ...(!demos?.swabtype && { swabtype: "oral" }),
    eligibilityStatus: eligStatus,
  };
};

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirthDOB(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const isValidDate = (val) => {
  const date = val.replace(/\/|-|,/g, "");
  if (date?.length === 8) {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4);
    const testDate = moment(`${year}-${month}-${day}`).format("YYYY-MM-DD");
    if (testDate !== "Invalid date") {
      return true;
    }
    return false;
  }
  return false;
};

export const isValidDobField = (dob, format) => {
  if (!dob) return false;
  return moment(dob, `${format}`, true).isValid();
};
export const setDate = (val) => {
  if (!val) return;
  const date = val.replace(/\/|-|,/g, "");
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);
  return moment(`${year}-${month}-${day}`).format();
};

export const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "");

export const formatDOB = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatTimeFromDate = (date) => (date ? moment(date).format("hh:mm A") : "");

export const formatUTCDate = (date) => (date ? moment(date).format("MM-DD-YYYY") : "");

export const formatLogDate = (date) => (date ? moment(date).format("MM-DD-YYYY HH:mm:ss") : "");

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const formatReportDate = (date) => (date ? moment(date).format("MM-DD-YYYY HH:mm") : "");

export const formateDateLL = (date) => (date ? moment(date).format("LL") : "");

export const formateDateDDMMMYYYY = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formateDatellll = (date) => (date ? moment(date).format("llll") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const sortByAlphaOrder = (list, key) => {
  return [...list].sort((a, b) => {
    const testerB = b[key] ?? "";
    const testerA = a[key] ?? "";
    return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
  });
};

export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
    case "isExternalTest":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "sequenceNo":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
            })
      );
      break;
    case "resultDate":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) < parseTestResult(a.id) ? 1 : parseTestResult(a.id) < parseTestResult(b.id) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) > parseTestResult(a.id) ? 1 : parseTestResult(a.id) > parseTestResult(b.id) ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
          : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0))
      );
  }
};

export const sortTestList = (
  sortBy,
  sortDescending,
  filteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );

    case "clientID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) < getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) < getClientName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) > getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) > getClientName(b[sortBy])
              ? -1
              : 0
          );
    case "siteID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) < getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) > getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
              ? -1
              : 0
          );
    case "labID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
          );
    case "result":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
          );
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "sequenceNo":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests?.length < a.tests?.length ? 1 : a.tests?.length < b.tests?.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests?.length > a.tests?.length ? 1 : a.tests?.length > b.tests?.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
        : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0));
  }
};

export const isValidEmail = (email) =>
  new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
export const checkValidity = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};
export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};

export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `+${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const formatZipCode = (id) => {
  if (id && id.length > 6) {
    return `${id.substring(0, 5)}`;
  }
  return id;
};

export const formatZip = (val) => {
  if (!val) {
    return "";
  }

  const input = val.replace(/[^0-9]+/g, "");
  const length = input.length;

  if (length < 9 && length > 5) {
    return input.substring(0, 5);
  }

  if (length > 5) {
    if (input.substring(5) === "0000") return input.substring(0, 5);
    return `${input.substring(0, 5)}-${input.substring(5)}`;
  }

  if (length === 5) {
    return input;
  }

  return input;
};

export const medFlowInLocalStorage = {
  save: (type) => localStorage.setItem("medFlow", type),
  get: () => localStorage.getItem("medFlow") || null,
  clear: () => localStorage.clear(),
};

export const medFlowPortalTitle = {
  save: (type) => localStorage.setItem("medFlowTitle", type),
  get: () => localStorage.getItem("medFlowTitle") || null,
  clear: () => localStorage.clear(),
};

export const roleInLocalStorage = {
  save: (type) => localStorage.setItem("role", type),
  get: () => localStorage.getItem("role") || null,
  clear: () => localStorage.clear(),
};

export const connectedIDInLocalStorage = {
  save: (type) => localStorage.setItem("connectedID", type),
  get: () => localStorage.getItem("connectedID") || null,
  clear: () => localStorage.removeItem("connectedID"),
};

export const clientIDInLocalStorage = {
  save: (type) => localStorage.setItem("clientID", type),
  get: () => localStorage.getItem("clientID") || null,
  clear: () => localStorage.removeItem("clientID"),
};

export const registrationShowSelectInLocalStorage = {
  save: (data) => localStorage.setItem("showSelected", data),
  get: () => JSON.parse(localStorage.getItem("showSelected")) || null,
  clear: () => localStorage.removeItem("showSelected"),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowmd", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowmd")) {
      return JSON.parse(localStorage.getItem("medflowmd"));
    }
    return {
      alltests: {},
      unprocessedtest: {},
      senttest: {},
      pendingreleasetest: {},
      totaltests: {},
      totaltest: {},
      expiredtests: {},
      processing: {},
      processedtests: {},
      positivetest: {},
      quarantine: {},
      tests: {},
      sites: {},
      labs: {},
      clients: {},
      users: {},
      crews: {},
      crewhs: {},
      employees: {},
      crewprofile: {},
    };
  },
  clear: () => localStorage.removeItem("medflowmd"),
};

export const uploadPatientRecordInLC = {
  save: (data) => localStorage.setItem("uploadStatusRecord", data),
  get: () => JSON.parse(localStorage.getItem("uploadStatusRecord")) || null,
  clear: () => localStorage.removeItem("uploadStatusRecord"),
};

export const showsInSS = {
  save: (obj) => sessionStorage.setItem("medflowSs", JSON.stringify(obj)),
  get: () => {
    if (sessionStorage.getItem("medflowSs")) {
      return JSON.parse(sessionStorage.getItem("medflowSs"));
    }
    return {
      selectedShow: {},
    };
  },
  clear: () => sessionStorage.removeItem("medflowSs"),
};

export const personalisationInLC = {
  save: (key, obj) => {
    const dataInLc = localStorage.getItem("medflowpersonalisation");
    let newObj = obj;
    if (key && dataInLc && dataInLc !== "undefined") {
      const data = typeof dataInLc === "string" ? JSON.parse(dataInLc) : dataInLc;
      Object.assign(data, { [key]: obj });
      newObj = data;
    } else if (key) {
      newObj = { [key]: obj };
    }
    localStorage.setItem("medflowpersonalisation", JSON.stringify(newObj));
  },
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    let objData = JSON.parse(localStorage.getItem("medflowpersonalisation"));
    if (objData) {
      objData[key] = selectedSetting;
      localStorage.setItem("medflowpersonalisation", JSON.stringify(objData));
      return objData;
    }
    const persolizeToMap = defaultPersonalization();
    if (persolizeToMap) {
      persolizeToMap[key] = selectedSetting;
      localStorage.setItem("medflowpersonalisation", JSON.stringify(persolizeToMap));
      return persolizeToMap;
    }
  },
  get: (key) => {
    const dataInLC = localStorage.getItem("medflowpersonalisation");
    if (!key) return dataInLC;
    const persolizeToMap = getPersonalizationData(key);
    if (dataInLC && dataInLC !== "undefined") {
      const data = JSON.parse(dataInLC);
      if (data[key] && Array.isArray(data[key])) {
        return persolizeToMap.map((d) => {
          return { ...d, isCheck: data[key].indexOf(d.id) !== -1 };
        });
      }

      if (data[key] && !Array.isArray(data[key])) {
        const arrData = typeof data[key] === "string" ? JSON.parse(data[key]) : [];
        if (arrData.length > 0) {
          return persolizeToMap.map((d) => {
            const obj = arrData.find((f) => f.id === d.id);
            return { ...d, isCheck: obj ? true : false, width: obj?.width || d.width };
          });
        }
      }
    }

    return persolizeToMap;
  },
  clear: () => localStorage.removeItem("medflowpersonalisation"),
};

export const qrResponseJOSN = (obj, empID, type) => {
  if (!obj || Object.keys(obj).length === 0) return "";
  let ph = obj.phone_number || obj.phoneNumber;
  ph = ph.replace(obj.countryCode ? obj.countryCode : "+1", "");

  return {
    firstName: obj.first || obj.firstName,
    lastName: obj.lastName || obj.last,
    phone: ph,
    employeeID: empID,
    email: obj.email,
    countryCode: obj.countryCode,
    dob: obj.dob,
    type: type || "MD",
  };
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  if (!dob.includes("-")) {
    const dobDate = FormatAWSDate(dob);
    return moment(dobDate).format("MM/DD/YYYY");
  }
  return moment(dob, "MM-DD-YYYY").format("MM/DD/YYYY");
};

export const formatAddress = (val) => {
  if (!val || (!val.city && !val.state && !val.zip)) return "";
  let arr = [];
  if (val.city) arr.push(val.city);
  if (val.state) arr.push(val.state);
  arr = [arr.join(",")];
  if (val.zip) arr.push(val.zip);
  if (val.zipcode) arr.push(val.zipcode);
  return arr.join(" ");
};

export const formatDoBForDemo = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MMDDYYYY");
  }
  return dob;
};
export const FormatAWSDate = (dobFromID) => {
  if (dobFromID && !dobFromID?.includes("-")) {
    const awsdob = [];
    awsdob.push(dobFromID.substring(4));
    awsdob.push(dobFromID.substring(0, 2));
    awsdob.push(dobFromID.substring(2, 4));
    return awsdob.join("-");
  }
  return dobFromID;
};
export const formatQuaratineDate = (value) => {
  if (!value || value == "") return value;
  return moment(value).format("YYYY-MM-DD");
};

export const formatQuaratineDisplayDate = (value) => {
  if (!value || value == "") return "-";
  return moment(value).format("MM-DD-YYYY");
};

export const calculateTdWidth = (width, numberOfCol) => Math.ceil(width / numberOfCol);

export const toTitleCase = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  if (str.length <= 3) {
    return str.toUpperCase();
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const parseTestType = (test_type) => {
  if (!test_type) return "";
  return test_type === "Other" ? "Rapid PCR" : test_type;
};

export const getPersonalizationIds = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE.map((c) => c.id);
  if (key === "employee") return LAB_MEMBER_PERSONALIZE.map((m) => ({ id: m.id, width: "100%" }));
};

export const getPersonalizationData = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE;
  if (key === "employee") return LAB_MEMBER_PERSONALIZE;
  if (key === "labClient") return LAB_CLIENT_PERSONALIZE;
  if (key === "provider") return PROVIDER_PERSONALIZE;
  if (key === "pendingtest") return PENDING_RESULT_PERSONALIZE;
  if (key === "completedtest") return COMPLETED_RESULT_PERSONALIZE;
  if (key === "orderpersonalize") return ORDER_PERSONALIZE;
  return [];
};

export const removeWhitespaceAndParentheses = (str) => {
  // Remove white spaces
  if (!str || !str.trim()) return "";
  str = str.replace(/\s/g, "");

  // Remove parentheses and their inner values
  str = str.replace(/\([^)]*\)/g, "");

  // Remove the file extension
  const lastDotIndex = str.lastIndexOf(".");
  if (lastDotIndex >= 0) {
    str = str.substring(0, lastDotIndex);
  }

  return str.toUpperCase();
};

export const getValidDep = (name) => {
  if (!name || !name.trim()) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const parseIntVal = (val) => {
  if (val) return parseInt(val);
  return 0;
};

export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const makeAlphabetInput = (value) => {
  if (!value) return null;
  return getValidName(value);
};

export const makeBarcode = (id_number, sequenceNo) => {
  if (!id_number) {
    id_number = moment().valueOf().toString();
  }
  if (id_number && sequenceNo) {
    return id_number.concat("-", sequenceNo.toString());
  }
  return "";
};

// Generate Track URL
export const DeliveryTrackUrl = (company, trackingNo) => {
  if (!company || !trackingNo) return null;
  if (company && trackingNo) {
    if (company === "DHL") {
      return `https://www.dhl.com/us-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${trackingNo}`;
    } else if (company === "FedEx") {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`;
    } else if (company === "UPS") {
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNo}`;
    } else {
      return "";
    }
  }
  return "";
};

// Generate Track URL
export const siteStatus = (status) => {
  if (status === 0) return "Pending";
  if (status === 1) return "Active";
  if (status === 2) return "Hold";
  return "Active";
};

// image url validation
export const isValidUrl = (file) => {
  if (!file) return false;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = typeof file === "object" ? URL.createObjectURL(file) : file;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

//Get Connected ID From URL
export const connectedIDByURL = (url) => {
  let urlparams = url.split("/");
  let connectedID = "";
  let testID = "";

  if (urlparams.length === 4) {
    connectedID = urlparams[3];
  }
  if (urlparams.length === 5) {
    connectedID = urlparams[3];
    testID = urlparams[4];
  }
  return { connectedID, testID };
};

export const openSite = (siteID) => {
  // console.log("siiteID::", siteID);
  let url = `${window.location.origin}/site/tests/${siteID}`;
  window.open(url);
};

export const getConnectedToValue = (val) => {
  let parentEmployeeRelation = val || [];
  if (typeof parentEmployeeRelation === "string") {
    parentEmployeeRelation = JSON.parse(parentEmployeeRelation);
  }
  return parentEmployeeRelation;
};

export const ChangeObject = (newReord, oldRecord, testNewRecord, testOldRecord) => {
  console.log({ newReord, testNewRecord });
  const prevRecord = { ...oldRecord, ...testOldRecord };
  const record = { ...newReord, ...testNewRecord };
  return Object.keys(record).reduce((obj, item) => {
    if (
      record.hasOwnProperty(item) &&
      (record[item] || prevRecord[item]) &&
      record[item] !== prevRecord[item] &&
      !NOT_INC_KEYS.includes(item)
    ) {
      obj[item] = `${prevRecord[item] || ""} to ${record[item] || ""}`;
    }
    return obj;
  }, {});
};

export const removeSpecialCharacters = (inputString) => {
  if (!inputString) return "";
  const val = inputString.replace(/[^a-zA-Z0-9 ]/g, "");
  if (val) {
    return val.trim().toLowerCase();
  }
  return "";
};

export const isValidAccessionNo = (inputString) => {
  if (!inputString) return "";
  const val = inputString.replace(/[^0-9 ]/g, "");
  return val ? true : false;
};

export const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
};

const parseDateOfBirth = (dob) => {
  if (dob.includes("-")) {
    let index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  return moment(dob, "MMDDYYYY").format("MM/DD/YYYY");
};

const parseSrNoObjectRecviedDate = (test) => {
  let arr = [];
  try {
    arr = JSON.parse(test.sr_no);
  } catch (er) {
    return test.createdAt;
  }
  if (arr.length > 0) {
    return arr[0]?.date;
  }
  return test.createdAt;
};

export const getRecviedDateFromSrNo = (test, onlyDate) => {
  if (!test?.scanByLabUserID) return null;
  try {
    const arr = JSON.parse(test.sr_no);
    const date = test.receivedDate || arr[0]?.date;
    if (onlyDate) {
      return date;
    }
    return moment(date).format("YYYY-MM-DDTHH:mm");
  } catch (error) {
    return null;
  }
};

export const formatPhoneForCalRedie = (phoneNumber) => {
  if (!phoneNumber) return "";

  let phone = formatPhoneNumber(phoneNumber);
  phone = phone.replace(/\D/g, "").replace(/^1/, "");
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    phone = `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phone;
};

export const parseLabAddress = (lab) => {
  const address = lab.city_state_zip;

  let phone = formatPhoneForCalRedie(lab.phone_number);

  // Regular expression pattern to match city, state, and ZIP code
  const regex = /(.*),\s+(\w{2})\s+(\d{5})/;

  const matches = address.match(regex);

  if (matches) {
    const city = matches[1].trim(); // Extracted city
    const state = matches[2]; // Extracted state
    const zipCode = matches[3]; // Extracted ZIP code

    return {
      city: city,
      state: state,
      zip: zipCode,
      phone: phone,
    };
  } else {
    return {
      city: "",
      state: "",
      zip: "",
      phone: "",
    };
  }
};

export const formatDateTimeTZ = (testDate, timeZone, date, time) => {
  try {
    const testFormatedDateTimeToLocalStr = new Date(testDate)
      .toLocaleString("en-US", {
        timeZone: timeZone ? timeZone : "America/Los_Angeles",
        ...(date && {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        ...(time && {
          hour: "2-digit",
          minute: "2-digit",
        }),
      })
      .replace(",", "");

    if (testFormatedDateTimeToLocalStr) return testFormatedDateTimeToLocalStr;
  } catch (error) {
    console.log("error:::::", error);
  }
};

export const exportToCalRedie = (list, labRecord, siteProvider) => {
  const labAddress = parseLabAddress(labRecord);
  return list.map((e) => {
    const obj = { ...EXPORT_TO_CALREDIE };
    const demo = JSON.parse(e.employee_demographics);
    let phone = formatPhoneForCalRedie(demo.phoneNumber);

    const defaultProvider = siteProvider[e.siteID];

    // if Site has Provider Set Default Provider into Ordering Provider

    if (defaultProvider) {
      Object.assign(obj, {
        ordering_provider_id: defaultProvider.npi,
        ordering_provider_last_name: defaultProvider.lastName,
        ordering_provider_first_name: defaultProvider.firstName,
        ordering_provider_middle_name: defaultProvider.middleName,
        ordering_provider_street: defaultProvider.street,
        ordering_provider_street2: defaultProvider.street2,
        ordering_provider_city: defaultProvider.city,
        ordering_provider_state: defaultProvider.state,
        ordering_provider_zip_code: defaultProvider.zip,
        ordering_provider_phone_number: formatPhoneForCalRedie(defaultProvider.phoneNumber),
      });
    }
    const { fluor, swabtype } = demo;
    obj.patient_id = `BRM-002-${demo.mdID || e.sequenceNo}`;
    obj.patient_last_name = capitalizeLetter(demo.firstName);
    obj.patient_first_name = capitalizeLetter(demo.lastName);
    obj.patient_street = demo?.street;
    obj.patient_street2 = demo?.street2 || "";
    obj.patient_city = demo.city;
    obj.patient_state = demo.state;
    obj.patient_zip_code = formatZip(demo.zip);
    obj.patient_county = "Los Angeles";
    obj.accession_number = e.barcode;
    obj.patient_phone_number = phone;
    obj.patient_dob = parseDateOfBirth(demo.dob);
    obj.patient_gender = demo.sex || "UNK";
    obj.test_result = EXPORT_TO_CALREDIE_RESULT[e.result?.toLowerCase()];
    obj.order_test_date = formatDateTimeTZ(e.createdAt, e.timeZone, "date");
    obj.specimen_collection_date = formatDateTimeTZ(e.createdAt, e.timeZone, "date");
    obj.testing_lab_specimen_received_date = formatDateTimeTZ(parseSrNoObjectRecviedDate(e), e.timeZone, "date");
    obj.test_result_date = moment(e.resultDateTime).format("MM/DD/YYYY");
    obj.date_result_released = moment(e.resultDateTime).format("MM/DD/YYYY");
    obj.testing_lab_clia = labRecord.clia_number;
    obj.testing_lab_name = labRecord.name;
    obj.testing_lab_street = labRecord.street;
    obj.testing_lab_street2 = "";
    obj.testing_lab_city = labAddress.city;
    obj.testing_lab_state = labAddress.state;
    obj.testing_lab_zip_code = labAddress.zip;
    obj.testing_lab_phone_number = labAddress.phone;

    if (fluor && fluor.toLowerCase() === "cy5") {
      obj.equipment_model_name = "CDC 2019-nCoV Real-Time RT-PCR Diagnostic Panel (CDC)";
      obj.test_performed_code = "94309-2";
      obj.test_ordered_code = "94309-2";
    }

    if (swabtype && swabtype === "nasal") {
      obj.specimen_type = "258500001";
    }

    return { ...obj };
  });
};

export const tdEmail = (email) => (
  <td
    key={email}
    className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const getResultToShow = (testCreate) => {
  const { resultDetails, result } = testCreate;

  if (resultDetails != null) {
    return { label: "View Detail", labelColor: RESULT_COLOR["detail"] };
  }

  const resultKey = result ? result.toLowerCase() : "detail";
  return {
    label: result || "-",
    labelColor: RESULT_COLOR[resultKey],
  };
};

export const tdResult = (index, result, handleCellClick) => (
  <td
    key={`result_${index}`}
    className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none text-center"
    style={{
      color: result.labelColor,
    }}
    onClick={() => handleCellClick()}
    title={result.label}
  >
    {result.label}
  </td>
);

export const tdPhone = (phoneNumber) => (
  <td
    key={phoneNumber + Math.random()}
    className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber, "".true)}
  >
    {formatPhoneNumber(phoneNumber, "", true)}
  </td>
);

export const isAllowToArchive = (phone_number) => {
  const validPhone = [
    "+14105980783",
    "+14436870662",
    "+18185730434",
    "+16265413922",
    "+17602186687",
    "+18322958840",
    "+923004991004",
  ];
  return validPhone.includes(phone_number);
};

export const formatFileSize = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const parseInsDetails = (insuranceDetails, user) => {
  if (!insuranceDetails) return null;

  if (Array.isArray(insuranceDetails)) {
    return insuranceDetails;
  }
  if (!user.insuranceCompanyCode) return [];
  const primary = {
    id: "prefix_" + Math.random().toString(36).slice(2, 9),
    insuranceCompany: user.insuranceCompanyCode,
    insuranceCompanyCode: user.insurance_name,
    insuranceType: user.insuranceType,
    copay: user.copay,
    deductible: user.deductible,
    insuranceGroupId: user.insuranceGroupId,
    isMedicare: user.isMedicare,
    medicalNo: user.insurance_number,
    medicareNo: user.medicalNo,
    isActive: true,
  };
  const insDetails = [primary];
  if (insuranceDetails?.insuranceCompany) {
    insDetails.push({ ...insuranceDetails, isActive: true, id: "prefix_" + Math.random().toString(36).slice(2, 9) });
  }
  return insDetails;
};

export const parseTestResultForPDF = (test) => {
  return test.result && RESULT_PDF[test.result.toLowerCase()];
};

export const formatPatientIntoPgData = (emp) => {
  if (!emp) return emp;
  return {
    ...emp,
    firstName: emp.first,
    lastName: emp.last,
    phoneNumber: emp.phone_number,
    mdID: emp.id,
  };
};

export const getBarCodeFromTest = (test) => {
  try {
    let arr = [];
    try {
      arr = JSON.parse(test.sr_no);
    } catch (er) {
      return test.barcode;
    }
    if (arr.length > 0) {
      return arr.map((r) => r.accession_no).join("\n");
    }
    return test.barcode;
  } catch (e) {
    return test.barcode;
  }
};

export const patientInsuranceType = (test) => {
  const insuranceDemographics = test?.employee_demographics;
  const EMPTY_OBJECT = {
    CLIENT: "",
    PATIENT: "",
    INSURANCE: "",
    MEDICARE: "",
    MEDICADE: "",
    CASH: "",
  };
  const { insuranceCompanyCode, insurance_name, insurance_number } = insuranceDemographics;

  if (!insurance_name) {
    return EMPTY_OBJECT;
  }

  if (insurance_name?.includes("Medicare")) {
    return { ...EMPTY_OBJECT, MEDICARE: insurance_number };
  }

  if (insurance_name?.includes("Medicaid")) {
    return { ...EMPTY_OBJECT, MEDICADE: insurance_number };
  }

  if (insuranceCompanyCode === "00001" || insurance_name?.includes("Bill Patient")) {
    return { ...EMPTY_OBJECT, PATIENT: "Patient" };
  }

  if (insuranceCompanyCode === "00002" || insurance_name?.includes("Bill Client")) {
    return { ...EMPTY_OBJECT, CLIENT: "Client" };
  }

  if (insuranceCompanyCode === "00003" || insurance_name?.includes("Cash Patient")) {
    return { ...EMPTY_OBJECT, CASH: "Cash" };
  }

  return { ...EMPTY_OBJECT, INSURANCE: insurance_number };
};

export const parseTestDates = (dates) => {
  try {
    if (!dates) return;

    const list = dates.split(",");

    const newList = list.map((d) => {
      let r = d.trim();
      const date = moment(r.padStart(6, 0), "MMDDYY").format("MM/DD/YYYY");
      return date;
    });

    return newList;
  } catch (ex) {
    console.log("[parseTestDates]", ex);
  }
  return null;
};

export const mileageOptions = () => {
  const array = [];
  for (let i = 1; i <= 100; i++) {
    array.push({ value: i, label: i });
  }
  return array;
};

export const isTenDigitNumber = (str) => {
  return /^\d{11}$/.test(str);
};
export const convertYYYYMMDDToDate = (str, format) => {
  if (!str) return "";
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6, 8);

  return moment(`${year}-${month}-${day}`).format(format || "MMM DD,YYYY");
};

export const hideElementsByClassName = (className) => {
  const elements = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }
};

export const eligibilityMessage = (item, row) => {
  if (row && CUSTOM_INS.includes(row?.insuranceCompanyCode)) {
    return "Eligibile";
  }

  if (!item) return "Not Eligibile";
  let message = item?.status || item?.message;

  if (message && message.toLowerCase() === "ineligibile") message = "Not Eligibile";
  return message || "Not Eligibile";
};

export const eligibilityMessageColor = (message) => {
  const msg = message === "Eligibile" ? "Eligibile" : "Not Eligibile";
  return ELIGIBILITY_STATUS_COLOR[msg];
};

export const parseJSONObject = (obj) => {
  if (!obj) return {};
  return typeof obj === "string" ? JSON.parse(obj) : obj;
};

export const getSelectedColumn = (items) => items.map((m) => ({ itemKey: m.itemKey, title: m.title }));

export const isLabImageExist = (testsLab) => {
  if (
    testsLab?.id === "8cd1a74a-392c-4378-a8a0-15480ee37a1e" ||
    testsLab?.id === "9a41b680-4f5f-4914-a8f6-2229740dca0b" ||
    testsLab?.id === "3eaf991b-b876-4897-8e39-47aef2247dcd" ||
    testsLab?.id === "2ce90d8b-daa3-4b3c-a685-6bec0dfecb43" ||
    testsLab?.id === "b63c398e-dc3f-412c-871e-ad89a94ddb0e"
  ) {
    return true;
  }
  return false;
};
// add new

export const parseInvoiceData = async (data, loginUser, clients) => {
  const [setting, employeeData] = await Promise.all([
    getCompanySetting(),
    api.getEmployeeByID({ id: data.employeeID }),
  ]);

  const outStandingBalance = employeeData?.setting?.outstanding_balance;

  let claimClients = clients;
  let errorMessage = "";
  let invoiceAlreadySent = false;
  const { invoiceID, invoiceNo, invoiceSendDate } = data?.toData?.find((f) => f.invoiceNo) || {};

  if (invoiceSendDate && moment(invoiceSendDate).isSame(moment(), "day")) {
    errorMessage = "Invoice Already Sent.";
    invoiceAlreadySent = true;
  }

  let sendTo = data.payerid === "00001" ? "Patient" : "Client";

  let toData = {};

  let barCode = data.remote_claimid.replace("CL-", "");

  if (data.payerid === "00002") {
    if (!claimClients) {
      claimClients = await getDefaultProvidersForInvoice({ ids: [data.provider.ref_id] });
    }
    const client = claimClients[data.provider.ref_id];
    toData = {
      id: client.id,
      isActive: true,
      payerid: data.payerid,
      payer_name: data.payer_name,
      firstName: client?.name || "",
      lastName: "",
      street: client?.street || "",
      state: client?.state ? `${client.state},` : "",
      zip: client?.zip || "",
      city: client?.city || "",
      country: client?.country || "",
      email: client?.email,
      sendToType: client?.setting?.billType,
      sendTo: sendTo,
      barCode,
    };
  } else {
    toData = {
      id: data.employeeID,
      isActive: true,
      payerid: data.payerid,
      payer_name: data.payer_name,
      firstName: data.pat_name_f,
      lastName: data.pat_name_l,
      street: data.pat_addr_1,
      street2: data.pat_addr_2,
      city: data.pat_city,
      state: data.pat_state,
      faxSubject: "",
      country: data.pat_country,
      zip: data.pat_zip,
      pcn: data.pcn,
      email: data.pat_email,
      sendTo: sendTo,
      barCode,
      outStandingBalance,
    };
  }

  let paramData = {
    id: invoiceID,
    invoiceSendDate: moment().toISOString(),
    invoiceDate: moment().toISOString(),
    dueDate: moment().add(10, "days").toISOString(),
    sendToType: data.sendToType,
    sendTo: sendTo,
    employeeID: data.employeeID,
    details: data.proc_array,
    invoiceAmount: generateInvoiceOutStanding(data),
    minAmount: data.minimumAmount ? Number(parseFloat(data.minimumAmount).toFixed(2)) : 0,
    claimID: data.id,
    local_id: data.id,
    clientID: userCompanyID.get() || CONFIG.generalClient,
    companyID: data.locationID,
    subAgentID: data.subAgentID,
    reSubmissionDate: data.reSubmissionDate,
    status: "pending",
    claimStatus: data.status,
    errorMessage: errorMessage,
    invoiceAlreadySent,
    udpatedBy: loginUser?.sub,
    updatedByName: loginUser?.name,
    message: INVOICE_DEFAULT_MESSAGE,
    invoiceNo: invoiceNo ?? "00000",
    payer_name: data.payer_name,
    firstName: data.pat_name_f,
    lastName: data.pat_name_l,
    toData: toData,
    fromData: {
      id: setting.id,
      companyName: setting.name,
      street: setting.street,
      street2: setting.street2,
      city: setting.city,
      state: setting.state,
      zip: setting.zip,
      phone: setting.phoneNumber,
    },
  };

  return paramData;
};
export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};
export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("medflowpersonalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("medflowpersonalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("medflowpersonalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("medflowpersonalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("medflowpersonalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch (err) {}

    if (!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return { ...obj, isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1, width: f.width };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if (!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter((obj) => !lsData.includes(obj.id));
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("medflowpersonalisation"),
};
export const RemoveSpaceAndChar = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};
export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};
export const downloadFileFromS3 = async (filePath, fileName, bucket) => {
  let alink = null;
  let fileURL = null;

  try {
    const data = await Storage.get(filePath, { bucket: bucket });
    const response = await fetch(data);

    if (!response.ok) {
      return false;
    }

    const blob = await response.blob();

    if (blob.size === 0) {
      return false;
    }

    fileURL = window.URL.createObjectURL(blob);
    alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = fileName;
    document.body.appendChild(alink);
    alink.click();

    return true;
  } catch (error) {
    console.error("Error downloading requisition zip:", error);
    return false;
  } finally {
    if (alink) {
      document.body.removeChild(alink);
      window.URL.revokeObjectURL(fileURL);
    }
  }
};
export const emailMsgToCreatedMember = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your health.<br><br>

        Please login to <a href="https://www.ltc.safecampmd.com/member-login">https://www.ltc.safecampmd.com/member-login</a> To view your profile, order tests and update your insurance information.<br><br>

        Username: ${userName}<br>
        Password: ${password}<br><br>

        We thank you again for choosing us to service your critical healthcare needs<br><br>

        Best Regards,<br>
        SafeCamp LTC Team`;

  return msg;
};
export const formatEmployeeClaim = (claim, setting, isMulti, isSecondary, era) => {
  const data = { ...claim, ...(claim.provider || {}) };

  const primaryIns = findMaxValueObject(data.toData, "status_code");

  console.log("primaryIns", primaryIns);
  const keysToDelete = [
    "mod1_2",
    "mod2_2",
    "mod3_2",
    "mod4_2",
    "proc_code_2",
    "thru_date_2",
    "units_2",
    "place_of_service_2",
    "proc_code_2",
    "remote_chgid_2",
  ];
  if (data) {
    keysToDelete.forEach((key) => {
      if (data.hasOwnProperty(key)) {
        delete data[key];
      }
    });
  }
  let procArray = [];
  let totalCharge = 0;
  if (data.proc_array.length > 0 && !isMulti) {
    for (let i = 0; i < data.proc_array.length; i++) {
      const proc = data.proc_array[i];

      let eraCPT = null;

      if (isSecondary && era) {
        eraCPT = era.charge.find((f) => f.proc_code === proc.proc_code);
      }

      const obj = {
        diag_ref_1: proc.diag_ref_1,
        diag_ref_2: proc.diag_ref_2,
        diag_ref_3: proc.diag_ref_3,
        diag_ref_4: proc.diag_ref_4,
      };

      const ttlCharge = getTotalCharge(proc);

      totalCharge = totalCharge + ttlCharge;

      const index = i + 1;
      Object.assign(data, {
        [`diag_ref_${index}`]: Object.values(obj)
          .filter((f) => f)
          .join(","),
        [`from_date_${index}`]: proc.from_date,
        [`thru_date_${index}`]: proc.from_date,
        [`charge_${index}`]: ttlCharge,
        [`units_${index}`]: proc.units,
        [`proc_code_${index}`]: proc.proc_code,
        [`place_of_service_${index}`]: proc.place_of_service,
        [`remote_chgid_${index}`]: `${data.remote_claimid}-${index}`,
        [`mod1_${index}`]: proc.mod1,
        [`mod2_${index}`]: proc.mod2,
        [`mod3_${index}`]: proc.mod3,
        [`mod4_${index}`]: proc.mod4,
        ...(isSecondary && {
          [`primary_paid_amount_${index}`]: proc.paid,
          [`primary_paid_date_${index}`]: data.checkDate,
        }),
      });

      if (isSecondary && eraCPT && eraCPT.adjustment && eraCPT.adjustment.length > 0) {
        eraCPT?.adjustment?.forEach((item, i) => {
          const childIndex = i + 1;
          data[`adj_amt_${childIndex}_${index}`] = item.amount;
          data[`adj_code_${childIndex}_${index}`] = item.group + item.code; // or any other value you want to assign
        });
      }

      procArray.push({ ...proc, remote_chgid: `${data.remote_claimid}-${index}` });
    }
  }

  const [countryCode, phoneNo] = getPhoneNo(data.bill_phone);

  Object.assign(data, {
    ...(totalCharge > 0 && { total_charge: totalCharge, proc_array: procArray }),
    bill_phone: phoneNo,
    accept_assign: "Y",
    pat_country: "",
    pat_rel: "18",
    payer_icn: claim.status === CLAIM_SUBMIT_STATUS.denied && claim.payerid !== "01192" ? claim.claimNo : "",
    assigned_comment: claim.assign_details?.message.replace(/'/g, "") || "",
    ...(isSecondary && {
      other_ins_name_l: data.pat_name_l,
      other_ins_name_f: data.pat_name_f,
      other_ins_name_m: data.pat_name_m,
      other_ins_dob: data.pat_dob,
      other_ins_sex: data.pat_sex,
      other_ins_payment_date: data.checkDate,
      other_payerid: primaryIns?.payerid,
      payer_order: "secondary",
      primary_allowed_amount: era?.total_allowed,
      other_payer_name: primaryIns?.payer_name,
      other_ins_number: primaryIns?.ins_number,
    }),
  });

  if (
    isSecondary &&
    data.payerid !== primaryIns?.payerid &&
    data.status === CLAIM_SUBMIT_STATUS.submitted &&
    !CUSTOM_INS.includes(data.payerid)
  ) {
    Object.assign(data, {
      toData: [
        ...(data.toData || []),
        {
          payer_name: data.payer_name,
          payerid: data.payerid,
          ins_number: data.ins_number,
          submissionDate: moment().toISOString(),
          status_code: (primaryIns?.status_code || 0) + 1,
        },
      ],
    });
  }

  // set the billing information according to the NPI set at insurnace level
  addCompanyInusranceNPI(data, setting);
  return data;
};

export const formatEmployeesData = (employees, subAgents, locations, companies, providers) => {
  if (employees.length === 0) return [];

  return employees.map((emp) => {
    const subAgent = subAgents?.find((s) => s.id === emp.subAgent);
    const location = locations?.find((s) => s.id === emp.companyID);
    const client = companies?.find((c) => c.id === emp.clientID);
    const provider = providers?.find((p) => p.id === emp.renderingProvider);
    const refProvider = providers?.find((p) => p.id === emp.referringProvider);
    const ordProvider = providers?.find((p) => p.id === emp.orderingProvider);
    return {
      ...emp,
      phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
      subAgent: subAgent ? { ...subAgent, value: subAgent.id, label: subAgent.name } : null,
      location: location ? { ...location, value: location.id, label: location.name } : null,
      client: client ? { ...client, value: client.id, label: client.name } : null,
      gender: emp.sex,
      secondaryInsurance: emp.insuranceDetails?.insuranceCompany || "",
      secondaryInsNumber: emp.insuranceDetails?.medicalNo || "",
      clientName: client ? client.name : "N/A",
      locationName: location ? location.name : "N/A",
      subAgentName: subAgent ? subAgent.name : "N/A",
      subAgentID: subAgent ? subAgent.id : null,
      providerName: provider?.name,
      providerFirstName: provider?.firstName,
      providerLastName: provider?.lastName,
      providerMidName: provider?.middleName,
      providerID: provider?.id,
      providerNpi: provider?.npi,
      providerTaxId: provider?.taxId,
      refProviderID: refProvider?.id,
      refProviderFirstName: refProvider?.firstName,
      refProviderName: refProvider?.name,
      refProviderLastName: refProvider?.lastName,
      refProviderMiddleName: refProvider?.middleName,
      refProviderNpi: refProvider?.npi,
      ordProviderID: ordProvider?.id,
      ordProviderFirstName: ordProvider?.firstName,
      ordProviderName: ordProvider?.name,
      ordProviderLastName: ordProvider?.lastName,
      ordProviderMiddleName: ordProvider?.middleName,
      ordProviderNpi: ordProvider?.npi,
    };
  });
};
export const getFloatVal = (val) => {
  if (!val) return 0;
  try {
    return +parseFloat(val).toFixed(2);
  } catch (err) {
    return 0;
  }
};
export const removeKeysFromObject = (obj, keysToRemove) => {
  if (!obj || typeof obj !== "object" || !Array.isArray(keysToRemove)) {
    return obj;
  }

  keysToRemove.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  });

  return obj;
};
export const formatNumber = (num) => {
  if (!num) return 0;
  return num.toLocaleString("en-US");
};

export const patientDataInLocalStorage = {
  save: (obj) => localStorage.setItem("client", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("client")) {
      return JSON.parse(localStorage.getItem("client"));
    }
    return newRegisterObj;
  },
  clear: () => localStorage.clear(),
};
export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz === "Pakistan Standard Time") return "PKT";
  return Tz.match(/\b\w/g).join("");
};
export const formatInvoice = (items) => {
  try {
    return items.map((obj) => {
      let item = { ...obj };
      item["toData"] = parseObject(item, "toData");
      item["details"] = parseObject(item, "details");

      return { ...item, discount: item.discount || 0 };
    });
  } catch (ex) {
    console.log("[formatInvoice]", ex);
  }
  return items;
};
export const formatLocations = (locations, companies) => {
  if (locations.length === 0) return [];

  return locations.map((emp) => {
    const client = companies.find((s) => s.id === emp.companyID);

    return {
      ...emp,
      clientName: client ? client.name : "N/A",
      client,
    };
  });
};
export const formatClaims = (models) => {
  let claims = [...models];
  claims = claims.map((obj) => {
    let m = { ...obj };
    m["provider"] = parseObject(m, "provider");
    m["proc_array"] = parseObject(m, "proc_array");
    m["acknowledgeBy"] = parseObject(m, "acknowledgeBy");
    m["assign_details"] = parseObject(m, "assign_details");
    m["paid_insurance"] = parseObject(m, "paid_insurance");

    if (m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.submitted) {
      m.status = CLAIM_SUBMIT_STATUS.sent;
    }

    let subBy = m.submittedByName || m.updatedByName;
    if (subBy === "0") subBy = m.updatedByName;

    const toData = m.toData && typeof m.toData === "string" ? JSON.parse(m.toData) : m.toData ?? [];

    return {
      ...m,
      invoiceID: toData?.find((f) => f.invoiceID)?.invoiceNo,
      toData,
      partialAmount: m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.partial ? true : false,
      reSubmissionDate: m.reSubmissionDate || m.createdAt,
      paid_ins_processed: m.paid_insurance?.payer_name || "",
      submittedByName: subBy,
    };
  });
  return claims;
};
export const formatOrder = (obj, employees) => {
  const address = [];
  const orderObj = { ...obj };
  const emp = (employees || []).find((f) => f.id === obj.employeeID);
  orderObj.details = typeof orderObj.details === "string" ? JSON.parse(orderObj.details) : orderObj.details;
  address.push(orderObj.details?.street || "");
  if (orderObj.details?.street2) address.push(orderObj.details?.street2 || "");
  address.push(orderObj.details?.city || "");
  return {
    ...orderObj,
    lastName: orderObj.lastName,
    firstName: orderObj.firstName,
    phoneNumber: orderObj.phoneNumber ? `${orderObj.countryCode}${orderObj.phoneNumber}` : "",
    email: orderObj.email,
    siteName: orderObj.locationName,
    medicalNo: orderObj.medicalNo,
    orderId: orderObj.orderId === "-1" ? "-" : orderObj.orderId,
    address: address.join(","),
    employeeClaimID: orderObj.employeeClaimID || "",
    claim: orderObj.claimSubmitDate ? "1" : "0",
    employeeStatus: emp?.status,
    note: {
      ...(orderObj.note ? orderObj.note : {}),
      message: emp?.note || orderObj.note?.message || "",
    },
  };
};
export const formatOrderedDateAws = (date) =>
  date ? `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}` : "";

export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);

  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData[key].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData?.indexOf(f.id.toString()) !== -1 };
    });
  },
  clear: () => localStorage.removeItem("personalisation"),
};
export const convertLabClientObject = (loc, client) => {
  const phone_number = importPhoneNoFormat(loc.client_phone);
  const fax = importPhoneNoFormat(loc.client_fax);
  const contactFax = importPhoneNoFormat(loc.client_main_contact_fax);

  const obj = {
    uuid: uuidv4(),
    clientID: client.id,
    practiceID: loc.client_payer_id || "",
    name: loc.client_name || "",
    street: loc.client_address || "",
    street2: loc.client_address_2 || "",
    city: loc.client_city || "",
    state: loc.client_state || "",
    zip: loc.client_zip || "",
    phone_number: phone_number || null,
    fax: fax || null,
    email: loc.client_email || null,
    contact_name: `${loc.client_main_contact_first_name || ""} ${loc.client_main_contact_last_name || ""}`,
    contact_fax: contactFax || null,
    contact_email: loc.client_main_contact_email || null,
    commission: loc.percent_claims_commission || null,
    discount: loc.percent_discount_list_price || null,
    setting: {
      primaryBillType: loc.primary_invoice_method || "email",
      ...(loc.secondary_invoice_method && { secondaryBillType: loc.secondary_invoice_method }),
    },
  };

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value])
  );
};

export const emailMsgToCreatedFacility = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, add sub agents, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const getPreRegistrationLink = (id, type = "") => {
  if (CONFIG.isLive) {
    return `https://ltc.safecampmd.com/order-test${type}/${id}`;
  }
  return `https://staging.ltc.safecampmd.com/order-test${type}/${id}`;
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return toTitleCase(reg.replace(/  +/g, " "));
};

export const tdLink = (item, dates, itemID, filterKey) => (
  <td
    className="ellipsis"
    style={{
      textAlign: "center",
      textOverflow: "none",
    }}
  >
    {item > 0 ? (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: "/admin/orders",
          state: {
            name: itemID,
            term: filterKey,
            filterName: dates,
            filterTerm: dates,
          },
        }}
      >
        {formatNumber(item)}
      </Link>
    ) : (
      item
    )}
  </td>
);
export const getStateLength = (obj) => {
  if (
    !obj.country ||
    obj.isoCode === "US" ||
    obj.isoCode === "CA" ||
    obj.country === "United States" ||
    obj.country === "Canada"
  ) {
    return 2;
  }
  return 8;
};

export const generatePassword = (email, phone) => {
  // Extracting 2 characters from the email and phone number
  const emailChars = email.slice(0, 2);
  const phoneChars = phone.slice(-2);

  // Generating 4 random characters
  const randomChars = Array.from({ length: 4 }, () => Math.random().toString(36)[2]).join("");

  // Combining the extracted and random characters
  let password = emailChars + phoneChars + randomChars;

  // If the password length is less than 8, add random characters to make it 8
  if (password.length < 8) {
    password += Math.random()
      .toString(36)
      .slice(2, 10 - password.length);
  }

  return password;
};

export const getFileType = (filename) => {
  const parts = filename.split(".");
  return parts.pop();
};

export const getFileName = (filename) => {
  const parts = filename.split(".");
  parts.pop();
  return parts.join(".");
};

export const getSplitValue = (data, idx) => {
  const parts = data.split(",");
  return parts[idx] ? parts[idx] : "";
};

export const formatTestDemo = (employee) => {
  return {
    city: employee.city,
    dob: moment(employee.dob).format("MMDDYYYY"),
    email: employee.email,
    firstName: employee.firstName,
    lastName: employee.lastName,
    idNumber: employee.lastName,
    idNumber: employee.idNumber,
    insurance_name: employee.insurance_name,
    insurance_number: employee.insurance_number,
    insuranceCompanyCode: employee.insuranceCompanyCode,
    isVaccinated: employee.isVaccinated || false,
    phoneNumber: employee.phone_number,
    countryCode: employee.countryCode,
    sex: employee.sex,
    state: employee.state,
    street: employee.street,
    street2: employee.street2,
    whiteGlove: employee.whiteGlove || false,
    employeeType: employee.employeeType,
    testerDes: employee.testerDes,
    swabtype: employee.swabtype,
    worker: employee.worker,
    possibleexposure: employee.possibleexposure,
    confirmedexposure: employee.confirmedexposure,
    relatedsymptoms: employee.relatedsymptoms,
    symptoms: employee.symptoms,
    othercomment: employee.othercomment,
    comment: employee.comment,
    clinicalcomment: employee.clinicalcomment,
    docUploaded: employee.docUploaded || false,
    id: employee.id,
    zip: employee.zip,
    mdID: employee.mdID || "",
    ...(employee.fluor && { fluor: employee.fluor }),
    isReview: employee.isReview || false,
    venipuncture: employee.venipuncture || "",
    distance: employee.distance || 0,
    isEligible: employee.isEligible || false,
    stat: employee.stat || false,
  };
};

export const getUUID = () => uuidv4();

export const generateRefInterval = ({ minValue, maxValue }) => {
  const hasMin = minValue !== undefined && minValue !== null && minValue !== "";
  const hasMax = maxValue !== undefined && maxValue !== null && maxValue !== "";

  return hasMin && hasMax
    ? `${minValue}-${maxValue}` // Display the range
    : hasMin
    ? `>${minValue}`
    : hasMax
    ? `<${maxValue}`
    : "Not Estab.";
};

const getUserDataFromLocalStorage = (suffix) => {
  const keys = Object.keys(localStorage);
  const userDataKey = keys.find((key) => key.endsWith(suffix));
  if (userDataKey) {
    const userData = localStorage.getItem(userDataKey);
    return JSON.parse(userData); // Parse once when the key is found
  }
  return null;
};

const processUserData = (attributes) => {
  const attrMap = attributes.reduce((map, attr) => {
    map[attr.Name] = attr.Value;
    return map;
  }, {});

  const id = attrMap["sub"];
  const firstName = attrMap["custom:firstName"];
  const lastName = attrMap["custom:lastName"];
  const name = `${firstName} ${lastName}`;

  return { userID: id, userName: name };
};

export const getLoginUserInfoFromLocal = () => {
  const userData = getUserDataFromLocalStorage(".userData");
  if (userData && userData.UserAttributes) {
    return processUserData(userData.UserAttributes);
  }
  return null;
};

export const analyzeResultValue = (range, result, refInterval) => {
  if (!result) return { label: "", color: "black", fontWeight: "500", orginalResult: result };

  if (customIsJsonString(result)) {
    const parsedJSONString = JSON.parse(result);

    if (parsedJSONString.value !== refInterval) {
      return { label: "", color: "red", fontWeight: "bold", orginalResult: parsedJSONString.label.toUpperCase() };
    }

    return { label: "", color: "black", fontWeight: "bold", orginalResult: parsedJSONString.label.toUpperCase() };
  }

  const numericResult = parseFloat(result);

  const minValue = range.minValue || range.minValue === 0 ? parseFloat(range.minValue) : null;
  const maxValue = range.maxValue || range.maxValue === 0 ? parseFloat(range.maxValue) : null;

  if (minValue !== null && !isNaN(minValue) && numericResult < minValue) {
    return { label: "Low", color: "blue", fontWeight: "bold", orginalResult: result };
  } else if (maxValue !== null && !isNaN(maxValue) && numericResult > maxValue) {
    return { label: "High", color: "red", fontWeight: "bold", orginalResult: result };
  } else {
    return { label: "", color: "green", fontWeight: "bold", orginalResult: result };
  }
};

export const mergeUniqueCptCodes = (data) => {
  if (!data) return [];

  const uniqueCodesMap = new Map();

  data.forEach((item) => {
    item.cptCodes.forEach((cptCode) => {
      if (!uniqueCodesMap.has(cptCode.code)) {
        uniqueCodesMap.set(cptCode.code, cptCode);
      }
    });
  });

  return Array.from(uniqueCodesMap.values());
};

export const isTestCodeMatch = (sourceTest, testToMatch) => {
  if (!sourceTest) return "";

  const order = sourceTest.order || {};
  const testsToDo = order.testsToDo || {};
  const test = testsToDo.test || [];

  if (!Array.isArray(test) || test.length === 0) return false;

  return test.some(
    (item) => item.code === testToMatch.internalCode || item.label.toLowerCase() === testToMatch.title.toLowerCase()
  );
};

export const getTestName = (sourceTest) => {
  if (!sourceTest) return "";

  const order = sourceTest.order || {};
  const testsToDo = order.testsToDo || {};
  const test = testsToDo.test || [];

  if (test.length > 0) {
    return test.map((item) => item.label).join(", ");
  }

  return sourceTest.test_type;
};

export const formatTwoFaErrorMessage = (error) => {
  const errorData = error?.response?.data;

  if (typeof errorData?.error === "string") {
    return errorData.error;
  } else if (Array.isArray(errorData?.errors)) {
    return errorData.errors.map((err) => err.code).join(", ");
  } else {
    return "Unknown error";
  }
};

export const getClientSpecificString = () => {
  const DEFAULT_CONFIG = {
    roomno: "Room No ",
    isShowRoom: true,
    isShowFloor: true,
    eligibilityBucket: CONFIG.eligibilityBucket,
  };

  switch (CONFIG.clientID) {
    case ALTA_ID:
      return {
        ...DEFAULT_CONFIG,
        eligibilityBucket: process.env.REACT_APP_ALTA_BUCKET,
        roomno: "Apt/Unit #",
        isShowFloor: false,
      };
    case NEW_DISCOVERY_ID:
      return {
        ...DEFAULT_CONFIG,
        roomno: "Apt/Unit #",
        isShowRoom: false,
        isShowFloor: false,
      };
    default:
      return DEFAULT_CONFIG;
  }
};

export const getDiagnosisCode = (order) => {
  const ictSet = new Set();
  order?.testsToDo?.test?.forEach(({ ictCodes = [] }) =>
    ictCodes.forEach(({ code: ictCode, cptCodes = [] }) => {
      ictSet.add(ictCode);
    })
  );
  const ictCodes = Array.from(ictSet.values());

  return ictCodes.reduce((acc, diagCode, index) => {
    acc[`diag_${index + 1}`] = diagCode;
    return acc;
  }, {});
};
