import { getTestsDetails } from "api";
import Icon from "components/Icon";
import React from "react";

import AsyncSelect from "react-select/async";
import { toTitleCase } from "utils";
import { parseJSONObject } from "utils";

const TestDetailsSelector = ({
  label = "Select",
  value,
  onChange,
  placeholder = "Select an option...",
  blurInputOnSelect = true,
  menuPlacement = "auto",
  tabIndex = 0,
  className = "",
  openDetailModal,
  mainTests,
  selectedTest,
}) => {
  const fetchDetails = async (inputValue) => {
    const response = await getTestsDetails({ search: inputValue });
    const data = response.rows;
    // const filteredMainTests = mainTests
    //   .filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()) && item.name !== selectedTest.name)
    //   .map((item) => ({
    //     testType: "mainTest",
    //     label: toTitleCase(item.name),
    //     value: item.id,
    //     category: item.category,
    //     clientID: item.clientID,
    //     code: item.code,
    //     custom_notes: item.custom_notes,
    //     typeOfTest: item.typeOfTest,
    //     _version: item._version,
    //     name: item.name,
    //     id: item.id,
    //     unit: item.units,
    //     refInterval: item?.refInterval || "",
    //     targetRanges: item.targetRanges,
    //   }));

    const subTest = data.map((item) => ({
      ...item,
      unit: parseJSONObject(item.units).label,
      label: toTitleCase(item.name),
      value: item.id,
      testType: "subTest",
    }));
    // return [...filteredMainTests, ...subTest];
    return [...subTest];
  };

  return (
    <div className="row mt-2">
      <div className="col-sm-6">
        <p className="testDetailsSelector">{label}</p>
        <div className="d-flex align-items-center">
          <AsyncSelect
            className={`w-100 ${className}`}
            loadOptions={fetchDetails}
            blurInputOnSelect={blurInputOnSelect}
            menuPlacement={menuPlacement}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            tabIndex={tabIndex}
          />
          <Icon
            iconType={"createIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={"test-selector-add-icon"}
            handleClick={() => openDetailModal()}
          />
        </div>
      </div>
    </div>
  );
};

export default TestDetailsSelector;
