import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const ShowAlertMessage = (props) => {
  const { message, handleClose, info, error, success, title = "" } = props;
  return (
    <div className="tcMatrix-custom-notification-wrapper">
      <SweetAlert
        show={true}
        info={info}
        error={error}
        success={success}
        onConfirm={handleClose}
        style={{ margin: "0" }}
        title= {title}
        showConfirm={false}
        hideOverlay
        customClass={`toast ${info && "infoAlert"}`}
        timeout={4000}
      >
        {message}
      </SweetAlert>
    </div>
  );
};

export default ShowAlertMessage;
