import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Filter from "components/Filter";
import Icon from "components/Icon";
import HeaderItem from "components/Table/HeaderItem";
import { debounce } from "debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import AddDiagnosisCodeSettingsModal from "../../components/GeneralSettings/AddDiagnosisCodeSettingsModal";
import {
  ICTCodeSelector,
  openCreateICTModalSelector,
  selectedICTItemSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import {
  fetchICTCodeAsync,
  setOpenCreateICTModal,
  setSelectedICTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { t } from "../../stringConstants";
import { DIAGNOSIS_CODE_PERSONALIZE } from "constants/personalization";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS } from "../../constant";
import { calculateTdWidth } from "../../utils";
import MainTable from "components/Table/MainTable";
import Status from "components/Status";

const DiagnosisCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const diagnosisList = useSelector(ICTCodeSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const mainWidth = useRef();
  const dispatch = useDispatch();
  const selectedICTItem = useSelector(selectedICTItemSelector);
  const openCreateICTModal = useSelector(openCreateICTModalSelector);
  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const searchTerms = ["Diagnosis Code", "Alias", "Diagnosis Code Desc","Active"];


  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending) => {
      dispatch(
        fetchICTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : { code: "a" }),
          orderBy: sortBy,
          orderByDirection: sortDescending,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedICTItem({ item: row }));
        dispatch(setOpenCreateICTModal(true));
        break;

      default:
        break;
    }
  };
  
  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("diagnosisCodes")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => dispatch(setOpenCreateICTModal(true))}
                    title={"Create Diagnosis"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />
                </div>
                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} personalisationData={DIAGNOSIS_CODE_PERSONALIZE} />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <MainTable
                    columns={DIAGNOSIS_CODE_PERSONALIZE}
                    rows={diagnosisList}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          {openCreateICTModal && (
            <AddDiagnosisCodeSettingsModal
              handleClose={() => {
                dispatch(setOpenCreateICTModal(false));
                dispatch(setSelectedICTItem(null));
              }}
              selectedItem={selectedICTItem?.item || null}
            />
          )}
        </Row>
      </>
    </div>
  );
};

export default DiagnosisCodeSettings;
