import TabPills from "components/Tabs/TabPills";
import {
  DIAGNOSIS_CODE, LAB_CLIENT, PAYER_MANAGEMENT,
  PROCEDURE_CODE,
  PROVIDER, SYSTEM_USERS, TEST_SETTING, TEST_DICTIONARY
} from "constants/tabPillsContants";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { fetchCompanySetting } from "store/features/companySetting/companySettingSlice";
import { providerList } from "store/features/providers/providersSelectors";
import { usersList } from "store/features/users/userSelectors";
// import ClaimInvoiceAPI from "views/ClaimInvoice/ClaimInvoiceAPI";
import DiagnosisCodeSettings from "views/GeneralSettings/DiagnosisCodeSettings";
import Insurances from "views/GeneralSettings/Insurances";
import ProcedureCodeSettings from "views/GeneralSettings/ProcedureCodeSettings";
// import MessageSetting from "views/MessageSetting/MessageSetting";
// import MessageTemplateView from "views/MessageTemplate/MessageTemplateView";
import UsersViewWhiteLabel from "views/GraphQL/UsersViewWhiteLabel";
import LabClient from "views/LabClient/LabClient";
import Provider from "views/Provider";
import TestTypeSetting from "views/TestTypeSetting/TestTypesSetting.js";
import TestDictionary from "views/GeneralSettings/TestDictionary";

const LabTestTypesSetting = () => {
  const [tabsOptions, setTabOptions] = useState([]);
  const appContext = useContext(AppContext);
  const providers = useSelector(providerList);
  const usersListArr = useSelector(usersList);
  const user = useSelector(selectLoginUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setTabOptions(
      appContext.permission?.users?.access && appContext.user?.isAdmin()
        ? tabPillsConstants : tabPillsConstants
        // : tabPillsConstants.filter((f) => f.title !== SYSTEM_USERS)
    );
  }, [providers, usersListArr, appContext.permission, user]);

  useEffect(()=> {
    dispatch(fetchCompanySetting())
  },[])

  const tabPillsConstants = [
    {
      title: PROVIDER,
      length: providers?.length,
      component: <Provider />,
    },
    {
      title: LAB_CLIENT,
      component: <LabClient />,
    },
    {
      title: PAYER_MANAGEMENT,
      component: <Insurances />,
    },
    {
      title: DIAGNOSIS_CODE,
      component: <DiagnosisCodeSettings />,
    },
    {
      title: PROCEDURE_CODE,
      component: <ProcedureCodeSettings />,
    },
    {
      title: SYSTEM_USERS,
      component: <UsersViewWhiteLabel />,
    },
    {
      title: TEST_SETTING,
      component: <TestTypeSetting />,
    },
    {
      title: TEST_DICTIONARY,
      component: <TestDictionary />,
    },
    
    // {
    //   title: SYSTEM_USERS,
    //   // length: appContext.users.filter((f) => !ADMIN_USERS.includes(f.phone_number)).length,
    //   component: <Users />,
    // },
    // window.location.hostname.includes("localhost") && {
    //   title: INVOICES,
    //   component: <ClaimInvoiceAPI />,
    // },
    // {
    //   title: SALE_PERSONS,
    //   component: <SalesPersonView />,
    // },
    // {
    //   title: MESSAGE_TEMPLATE,
    //   component: <MessageTemplateView />,
    //   // component: <MessageSetting />,
    // },

    // { title: LOCATIONS, length: appContext.locations?.length, component: <BRMFacilities /> },
    // {
    //   title: GENERAL_SETTINGS,
    //   component: <GeneralSettings />,
    // },

    // {
    //   title: ALERT_SETTINGS,
    //   component: <MessageSetting />,
    // },
  ];

  return (
    <div className="shows-screen-parent custom-lab-test">
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          <div className="system-settings-wrapper">
            <TabPills tabs={tabsOptions} />
          </div>
        </Col>
      </Row>
    </div>
    </div>
  );
};

export default LabTestTypesSetting;
