import _ from "lodash";

export const customIsEmpty = (value) => {
  return _.isEmpty(value);
};

export const customKeyBy = (array, key) => {
  return _.keyBy(array, key);
};
export const customUniqBy = (array, key) => {
  return _.uniqBy(array, key);
};

export const customCloneDeep = (obj) => {
  return _.cloneDeep(obj);
};

export const isNullOrEmpty = (value) => {
  if (_.isNull(value) || _.isUndefined(value)) {
    return true;
  }
  if (_.isArray(value) && _.isEmpty(value)) {
    return true;
  }
  return false;
};

export const customIsObject = (variable) => {
  return _.isObject(variable) && !_.isArray(variable) && variable !== null;
};

export const customIsJsonString = (str) => {
  if (!_.isString(str)) {
    return false;
  }
  try {
    const parsed = JSON.parse(str);
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};
