// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Lab, Test, Employee, Client, Site, Order, LencoBarcodes, TestConsent, TestTypes, EmployeeBarCodes, EmployeeTestCounter, ExternalTest, EmployeeLogin, TestSession, Documents, UploadRequestMain, UploadRequest, GeneralSettingType } = initSchema(schema);

export {
  Lab,
  Test,
  Employee,
  Client,
  Site,
  Order,
  LencoBarcodes,
  TestConsent,
  TestTypes,
  EmployeeBarCodes,
  EmployeeTestCounter,
  ExternalTest,
  EmployeeLogin,
  TestSession,
  Documents,
  UploadRequestMain,
  UploadRequest,
  GeneralSettingType
};