import axios from "axios";
import { API, Auth } from "aws-amplify";
import api from "../api";
import { userCompanyID } from "utils";
import { CONFIG } from "constant";

const apiName = "AdminQueries";
const publicApiName = "usersApi";

const fetchInsurances = async (param) => {
  let path = "/insurances/list";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      clientID: CONFIG.clientID,
      ...param,
    },
  };

  try {
    const models = await API.post(apiName, path, myInit);

    return models.rows || [];
  } catch (e) {
    console.log("Error getting users:", e);
    return [];
  }
};

const createInsurances = async (params) => {
  let path = "/insurances";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ...params,
    },
  };

  try {
    const models = await API.post(apiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

const updateInsurances = async (params) => {
  let path = "/insurances";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ...params,
    },
  };

  try {
    const models = await API.put(apiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

export default {
  fetchInsurances,
  createInsurances,
  updateInsurances,
};
