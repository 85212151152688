import React from "react";
import editIcon from "../../assets/img/edit-icon.png";
import { capitalizeLetter } from "utils";

const TableRow = ({ item, handleEdit }) => {
  const sourcesTitle = () => {
    if (!item?.sources?.length) {
      return "N/A";
    }
    return item.sources.map((source) => source.label).join(", ");
  };
  return (
    <tr className="trDataWrapper">
      <td className="icon">
        {/* <i
          className="fas fa-edit"
          onMouseLeave={(e) => (e.target.style.color = "black")}
          onMouseOver={(e) => {
            e.target.style.color = "blue";
            e.target.style.cursor = "pointer";
          }}
          aria-hidden="true"
          onClick={() => handleEdit(item)}
        /> */}
        <img src={editIcon} alt="edti icon" width="16" onClick={() => handleEdit(item)} />
      </td>
      <td className="ellipsis">{capitalizeLetter(item.name)}</td>
      <td className="ellipsis">{item.code}</td>
      <td className="ellipsis">{item.typeOfTest}</td>
      <td className="ellipsis">{capitalizeLetter(item.category)}</td>
      <td className="ellipsis" title={capitalizeLetter(sourcesTitle())}>
        {item?.sources?.length > 1 ? "Multi" : capitalizeLetter(item?.sources?.[0]?.label) || "N/A"}
      </td>
      {/* <td className="ellipsis">{item.totalTime}</td>
      <td className="ellipsis">{item.firstAlert}</td>
      <td className="ellipsis">{item.secondAlert}</td>
      <td className="ellipsis">{item.duration}</td> */}
    </tr>
  );
};

export default TableRow;
