import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { draggablePersonalizationLocalStorage, getValidName } from "../../utils";
import api from "../../api";

import FileInput from "components/Input/FileInput";
import ErrorMessage from "components/Message/ErrorMessage";
import EditableDropdown from "components/DropdownMenu/EditableDropdown";
import { DOCUMENT_TYPES_OPT } from "constant";

const DocumentForm = (props) => {
  const { handleClose, emp, cssClass, document, setDocuments, appContext, setLoading } = props;
  const [newDocument, setNewDocument] = useState(document);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [error, setError] = useState("");
  const [documentTypes, setDocumentTypes] = useState(DOCUMENT_TYPES_OPT);

  useEffect(() => {
    if (!document.isNew) {
      setNewDocument(document);
      setFileType(document.fileType);
      setFileSize(document.fileSize);
      const parts = document.fileName?.split("$");
      const slicedString = parts[parts.length - 1];
      setSelectedFileName(slicedString);
    }
  }, [document]);

  const handleCancel = () => {
    setNewDocument({ isNew: true });
    setFile("");
    setFileType("");
    setSelectedFileName("");
    setFileSize("");
  };

  const validateDocumentForm = (doc) => {
    if (!doc.docType) {
      setError("Document Type is Required");
      return;
    }
    if (!doc.docLabel) {
      setError("Document Label is Required");
      return;
    }
    if (!doc.file && newDocument.isNew) {
      setError("Document File is Required.");
      return;
    }

    return true;
  };

  const createDocumentAsync = async (param) => {
    try {
      setLoading(true);
      let fileName = null;
      if (param.file) {
        fileName = await api.saveDocumentFile(param.employeeID, param.selectedFileName, param.file);
      }

      const logObject = {
        employeeID: param.employeeID,
        userID: appContext.user?.sub,
        slug: "Document Created",
        file: fileName,
        newValue: "Done",
        userName: appContext.user?.name,
      };

      const response = await api.newDocumentCreateAPI([
        {
          ...param,
          fileName,
          updatedBy: appContext.user?.sub,
          updatedByName: appContext.user?.name,
        },
      ]); // Your API call logic here

      setLoading(false);
      if (response.rows && response.rows.length > 0) {
        setDocuments((prev) => [...prev, response.rows[0]]);
        await api.addLogs(logObject);
        appContext.showSuccessMessage("Document Uploaded Successfully");
      } else {
        appContext.showErrorMessage("Document Not Created");
      }

      return null;
    } catch (err) {
      setLoading(false);
      console.log("Error createDocumentAsync:-", err);
    }
  };

  const updateDocumentAsync = async (param) => {
    try {
      setLoading(true);
      const logObject = {
        employeeID: param.employeeID,
        userID: appContext.user?.sub,
        slug: "Document Updated",
        file: fileName,
        newValue: "Done",
        userName: appContext.user?.name,
      };

      let fileName = param.fileName;
      if (param.file) {
        fileName = await api.saveDocumentFile(param.employeeID, param.selectedFileName, param.file);
      }

      const response = await api.updateDocumentAPI({
        ...param,
        fileName,
        updatedBy: appContext.user?.sub,
        updatedByName: appContext.user?.name,
      }); // Your API call logic here
      setLoading(false);
      if (response && response.data) {
        const doc = response.data;
        setDocuments((prev) => {
          const prevlist = [...prev];
          const index = prevlist.findIndex((f) => f.id === doc.id);
          if (index !== -1) {
            prevlist.splice(index, 1, doc);
            return prevlist;
          }
        });
        await api.addLogs(logObject);
        appContext.showSuccessMessage("Document updated Successfully");
        return;
      }
      appContext.showErrorMessage("Document Not Updated");
      return null;
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  const handleUploadDocument = async () => {
    const doc = { ...newDocument, file, fileType, selectedFileName, fileSize };
    if (validateDocumentForm(doc)) {
      if (doc.isNew) {
        await createDocumentAsync({ ...doc, employeeID: emp.id });
      } else {
        await updateDocumentAsync({ ...doc, employeeID: emp.id });
      }

      handleClose();
    }
    console.log("Save File");
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      className={cssClass ?? ""}
      onHide={() => handleClose()}
      centered
      size={"md"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Document Details
        </Modal.Title>{" "}
      </Modal.Header>

      <Modal.Body className="table-full-width desktop-noScroll">
        <div className="row">
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document Type: </label>
              <EditableDropdown
                data={documentTypes}
                title="Select Document Type"
                selectedOptionLabel={newDocument.docType}
                onOptionChange={(val) => setNewDocument({ ...newDocument, docType: val.label })}
                addNewItem={(item) => setDocumentTypes([...documentTypes, item])}
              />
              {/* <input
                value={newDocument.docType || ""}
                className="modalInput w-100"
                placeholder="Enter Document Type"
                onChange={(e) =>
                  setNewDocument({
                    ...newDocument,
                    docType: getValidName(e.target.value),
                  })
                }
              /> */}
            </div>
          </div>
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document Label: </label>
              <input
                value={newDocument.docLabel || ""}
                className="modalInput w-100"
                placeholder="Enter Document Label"
                onChange={(e) =>
                  setNewDocument({
                    ...newDocument,
                    docLabel: getValidName(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <h4 className="profile-title">Additional Notes</h4>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  style={{ minHeight: "60px" }} // Set the minimum height
                  value={newDocument?.notes || ""}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      notes: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-sm-12 testAdmin createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document File: </label>
              <FileInput
                setFileType={setFileType}
                setFile={setFile}
                setSelectedFileName={setSelectedFileName}
                selectedFileName={selectedFileName}
                setFileSize={setFileSize}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer className="my-2 d-flex justify-content-between">
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleUploadDocument}>
          {newDocument.isNew ? "Upload" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentForm;
