import React from 'react';

const SwitchToggle  = (props) => {
    const {id, name, handleToogleChange, checked, disabled} = props;
    return(
        <label className="switch">
            <input type="checkbox" id={id} name={name} onChange={handleToogleChange} checked={checked} disabled={disabled}  />
                <span className={`slider round ${disabled ? 'disabled' : ''}`} />
        </label>
    )
}
export default SwitchToggle;