import React from "react";

const TestInputField = ({ label, value, onChange }) => (
  <div>
    <p className="modalLineHeaders">{label}</p>
    <input className="modalInput" value={value} placeholder={label} style={{ width: "100%" }} onChange={onChange} />
  </div>
);

export default TestInputField;
