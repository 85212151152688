import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SYSTEM_TIME_ZONE } from "utils";
import api from "../../api";
import { CONFIG } from "../../constant";
import DiagnosisAccordionView from "views/TestTypeSetting/TestDiagnosisView/DiagnosisAccordionView";
import { customCloneDeep } from "util/customLodash";
import CreateTestItem from "./CreateTestItem";
import CreateTestHeader from "./CreateTestHeader";

const CreateOrderTest = (props) => {
  const { item, handleCloseCreateTest, appContext, userLabName } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [order, setOrder] = useState(item);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [testDone, setTestDone] = useState(false);

  const [ictList, setIctList] = useState(null);
  const [editIndexIct, setEditIndexIct] = useState(null);
  const [testDoneList, setTestDoneList] = useState([]);

  useEffect(() => {
    getOrdersTests();
  }, []);

  const getOrdersTests = async () => {
    try {
      if (!item?.id || item.status === "Processed") return;

      const resData = await api.getOrderTestList(item.id);

      setTestDoneList(resData.rows);
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleCreateTest = () => {
    const isAnyTestSelected = getTestListToCreate();

    if (!isAnyTestSelected) {
      appContext.showErrorMessage("Please select Test");
      return;
    }
    setShowConfirmation(true);
  };

  const handleItemChange = (index, updatedItem) => {
    const updatedTests = order.testsToDo.test.map((item, i) => (i === index ? { ...item, ...updatedItem } : item));

    setOrder({
      ...order,
      testsToDo: { ...order.testsToDo, test: updatedTests },
    });
  };

  const handleConfirmation = async (isConfirm) => {
    setShowConfirmation(false);
    if (!isConfirm) return;

    setLoading(true);

    try {
      const { eligibilityStatus } = order;

      const res = await api.createMulipleTests({
        labName: userLabName,
        order: {
          ...order,
          employee_demographics: {
            ...order.employee_demographics,
            dob: moment(order.employee_demographics.dob).format("MMDDYYYY"),
            worker: "",
            possibleexposure: "",
            confirmedexposure: "",
            relatedsymptoms: "",
            symptoms: "",
            othercomment: "",
            comment: "",
            docUploaded: false,
            isReview: false,
            venipuncture: false,
            distance: 0,
            isEligible: eligibilityStatus?.status?.toLowerCase() === "eligibile",
            stat: false,
          },
          labID: CONFIG.whiteLabelID,
          labName: appContext.userLabName,
          createSource: "portal",
          timezone: SYSTEM_TIME_ZONE,
          createdByPhone: appContext?.user?.phone_number,
          testCreatedAt: moment().toISOString(),
          testDate: moment().format("YYMMDD"),
          createdBy: appContext?.user?.sub,
          createdByName: appContext?.user?.name,
          tester_name: appContext?.user?.name,
        },
      });

      const {
        testsToDo: { test },
      } = order;

      const updatedTests = order.testsToDo.test.map((item, i) => {
        const processedTest = res[item.label];
        if (processedTest) {
          return { ...item, barcode: processedTest.test.barcode };
        }
        return item;
      });

      setOrder({
        ...order,
        testsToDo: { ...order.testsToDo, test: updatedTests },
      });

      setLoading(false);
      setTestDone(true);
      handleCloseCreateTest("Test created successfully");
    } catch (ex) {
      console.log("[handleConfirmation]", ex);
      setLoading(false);
      appContext.showErrorMessage("Fail to create the test");
    }
  };

  const getTestListToCreate = () =>
    order.testsToDo.test
      .filter((t) => t.isSelected)
      .map((t) => t.label || t.name)
      .join(",");

  const handleEditTest = (item, index) => {
    setIctList(item.ictCodes);
    setEditIndexIct(index);
  };

  const saveDiagnosisCodes = (item) => {
    const copyOrder = customCloneDeep(order);

    if (copyOrder && copyOrder.testsToDo && copyOrder.testsToDo.test && copyOrder.testsToDo.test[editIndexIct]) {
      copyOrder.testsToDo.test[editIndexIct].ictCodes = item;
    }
    setOrder(copyOrder);
    setIctList(null);
    setEditIndexIct(null);
  };

  return (
    <>
      {ictList && (
        <DiagnosisAccordionView
          handleClose={() => setIctList(null)}
          handelSaveDiagnosisPanel={saveDiagnosisCodes}
          ictList={ictList}
          setIctList={setIctList}
          isNew={false}
        />
      )}

      <Modal
        show
        animation={true}
        onHide={handleCloseCreateTest}
        size="xl"
        style={{ paddingLeft: "0" }}
        centered
        className="seperateModal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {order.firstName} {order.lastName} - Date of Birth:{" "}
            {moment(order?.employee_demographics?.dob).format("MM-DD-YYYY")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <table className="custom-separate-border-tr table w-100">
            <thead>
              <CreateTestHeader showSelection={true} testDone={testDone} />
            </thead>
            <tbody>
              {order.testsToDo?.test.map((c, index) => (
                <CreateTestItem
                  key={index}
                  item={c}
                  index={index}
                  showSelection={true}
                  testDoneList={testDoneList}
                  onItemChange={handleItemChange}
                  handleEditTest={handleEditTest}
                  appContext={appContext}
                  order={order}
                />
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          <div>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              disabled={loading || testDone}
              className="modalButtons mx-2"
              onClick={handleCreateTest}
            >
              Create Test
            </Button>
          </div>
          {loading && <Loader />}
          {showConfirmation && (
            <ConfirmationModal
              show={showConfirmation}
              title="Confirmation"
              message={`Are you sure you want to create the ${getTestListToCreate()}`}
              handleConfirm={handleConfirmation}
              cssClass="seperateModal"
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOrderTest;
