import React, { useEffect, useState, useMemo } from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { formatDateOfBirthDOB, formatDateMDY, formatAddress } from "utils";
import ArialRegular from "assets/fonts/arial.ttf";
import ArialBold from "assets/fonts/arial_bold.ttf";
import tickIcon from "assets/img/tick-icon.png";
import { SYMPTOMS, logoImage } from "constant";
import { getAMPM } from "utils";
import { formatPhone, formatTimeFromDate, isLabImageExist } from "utils";
import {
  PROFILE_REQUESTED,
  INDIVIDUAL_REQUESTED,
  MICROBIOLOGY,
  CYTOLOGY,
  BIOPSY,
  PRESUMPTIVE_ELIGIBILITY,
} from "biolabRequisitionFormConstant";
import { PHY_SIGN } from "constant";
import { capitalizeLetter } from "utils";
import { patientInsuranceType } from "utils";
import api from "api";
import { isTestCodeMatch } from "utils";
import { getTestName } from "utils";
import moment from "moment";
import { formatReportDate } from "utils";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialRegular, fontWeight: "normal" },
    { src: ArialBold, fontWeight: "bold" },
  ],
});
const BioLabBiologicalPDF = ({ test, lab, signature, demographics, parseSrNoObjectRecviedDate, site, barcode }) => {
  const [labInfo, setLabInfo] = useState();

  const testReceivedDate = useMemo(() => {
    return formatReportDate(parseSrNoObjectRecviedDate(test));
  }, [test]);

  const [testCode, setTestCode] = useState("");

  const [hasImage, setHasImage] = useState(false);

  const insuranceType = useMemo(() => {
    return patientInsuranceType(test);
  }, [demographics]);

  const getTestCode = async () => {
    try {
      const response = await api.getTestTypesByID(test?.employee_demographics?.testTypeID);
      setTestCode(response?.code);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getTestCode();
  }, [test]);

  const getDiagonosisCode = useMemo(() => {
    const defaultDiagnosis = test?.test_type === "PCR" ? "Z20822" : "";
    if (!test?.order) {
      return defaultDiagnosis;
    }

    const { testsToDo: testDone } = test.order;

    if (!testDone) return defaultDiagnosis;

    let testsToDo = typeof testDone === "string" ? JSON.parse(testDone) : testDone;

    const isArray = testsToDo && Array.isArray(testsToDo);
    const testList = isArray ? testsToDo : testsToDo?.test;

    const orderTestObj = testList.find(
      (t) => t.label === test.test_type || t.id === test?.employee_demographics?.testTypeID
    );

    if (orderTestObj?.ictCodes) {
      return orderTestObj.ictCodes.map((item) => item.code).join(",");
    }
    return defaultDiagnosis;
  }, [test]);

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["a", "b", "c", "d"],
    datasets: [
      {
        data: [300, 50, 100, 50],
        backgroundColor: ["red", "white"],
        hoverBackgroundColor: ["red", "white"],
      },
    ],
  };

  useEffect(() => {
    if (signature) {
      fetch(signature)
        .then((response) => {
          // Check if the response status is OK (200)
          if (response.status === 200) {
            setHasImage(true);
          } else {
            setHasImage(false);
          }
        })
        .catch((error) => {
          console.error("Error checking image:", error);
          setHasImage(false);
        });
    } else {
      setHasImage(false);
    }
  }, [signature]);

  useEffect(() => {
    setLabInfo(test.lab);
  }, [test]);

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  const formatIDNumber = () => {
    const val = demographics?.idNumber || demographics?.id_number || "00000";
    return /^[0]+$/.test(val) ? "" : val;
  };

  const testsName = useMemo(() => getTestName(test), [test]);

  return (
    <Document title={`${demographics?.firstName} ${demographics?.lastName}`}>
      <Page size="A4" style={styles.page}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          General Lab Test Requisition
        </Text>
        <Image style={{ maxWidth: "150px", height: "25px", position: "absolute", top: 5, left: 50 }} src={barcode} />
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 0.1, fontSize: 8, fontWeight: "bold", color: "#566696" }}>
            <Text>C</Text>
            <Text>L</Text>
            <Text>I</Text>
            <Text>E</Text>
            <Text>N</Text>
            <Text>T</Text>
          </View>
          <View style={{ flex: 1.5 }}>
            <View style={{ flex: 5, flexDirection: "row", alignItems: "center" }}>
              <View style={{ ...styles.infoCol }}>
                <Text style={{ ...styles.label, fontSize: 9, margin: "0px 2px" }}>
                  CLIENT #{site?.practiceID || ""} - {site?.name}
                </Text>
                <Text style={{ fontSize: 9, margin: "2px 2px" }}>
                  {site.street}, {formatAddress(site)}
                </Text>
                <Text style={{ fontSize: 9, margin: "0px 2px" }}>
                  Phone: {formatPhone(site.phone_number)} | Fax: {formatPhone(site.fax)}
                </Text>
                <Text style={{ fontSize: 9, marginTop: "2px", marginLeft: "2px" }}>
                  PHYSICIAN: {site?.setting?.lab_director || labInfo?.lab_director}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flex: 2, textAlign: "right", padding: "2px 5px 5px 5px", width: "100%" }}>
            <Image
              style={{
                width: "150px",
                marginLeft: "auto",
                height: "30px",
                objectFit: "contain",
                textAlign: "right",
              }}
              src={isLabImageExist(labInfo) ? require(`assets/img/${labInfo?.id}.png`).default : logoImage}
            />
            <Text style={{ fontSize: 10, margin: "2px 2px" }}>{`${labInfo?.street || ""}, ${
              labInfo?.city_state_zip || ""
            }`}</Text>
            <Text style={{ fontSize: 10, margin: "0px 2px" }}>
              {`Phone: ${formatPhone(labInfo?.phone_number)} | Fax ${formatPhone(labInfo?.fax_number)}`}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", backgroundColor: "#566696" }}>
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 12,
              fontWeight: "bold",
              width: "100%",
            }}
          >
            PATIENT'S INFORMATION
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderWidth: 1,
            padding: 0,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <View style={{ flex: 1 }}>
            <View>
              <View style={{ flexDirection: "row", borderBottom: 1, justifyContent: "space-between", padding: "2px" }}>
                <View>
                  <Text style={{ ...styles.label }}>Last Name</Text>
                  <Text style={{ ...styles.field }}>{demographics?.lastName}</Text>
                </View>
                <View>
                  <Text style={{ ...styles.label }}>First Name</Text>
                  <Text style={{ ...styles.field }}>{demographics?.firstName}</Text>
                </View>
                <View>
                  <Text style={{ ...styles.label }}>Middle Initial</Text>
                  <Text style={{ ...styles.field }}>{demographics?.middleName || ""}</Text>
                </View>
              </View>
              <View style={{ padding: "2px", borderBottom: 1 }}>
                <Text style={{ ...styles.label }}>Address</Text>
                <Text style={{ ...styles.field }}>{demographics?.street || ""}</Text>
              </View>
              <View style={{ flexDirection: "row", borderBottom: 1, padding: "2px", justifyContent: "space-between" }}>
                <View style={{ width: "55%" }}>
                  <Text style={{ ...styles.label }}>City</Text>
                  <Text style={{ ...styles.field }}>{demographics?.city || ""}</Text>
                </View>
                <View style={{ width: "50px" }}>
                  <Text style={{ ...styles.label }}>State</Text>
                  <Text style={{ ...styles.field }}>{demographics?.state || ""}</Text>
                </View>
                <View style={{ width: "50px" }}>
                  <Text style={{ ...styles.label }}>Zip</Text>
                  <Text style={{ ...styles.field }}>{demographics?.zip || ""}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#566696",
                width: "100%",
                padding: 2,
                flexDirection: "row",
                maxHeight: "40px",
              }}
            >
              <Text style={{ color: "white", textAlign: "center", ...styles.label }}>
                BILLING INFORMATION. If bill is not complete & legible: client/doctor will be billed.
              </Text>
            </View>
            <View style={{ flexDirection: "row", flex: 4.5 }}>
              <View style={{ flex: 1, borderRightWidth: 1 }}>
                <View style={{ flex: 1, padding: "0px 10px", textAlign: "center" }}>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.CASH && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>CASH/CHECK</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.CLIENT && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>DOCTOR</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.INSURANCE && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>INSURANCE</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>IPA GROUP</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.MEDICARE && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>MEDICARE</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.MEDICADE && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>MEDI-CAL/CAID</Text>
                  </View>
                  <View style={{ ...styles.billingList, marginBottom: "3px" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {insuranceType?.PATIENT && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ fontSize: 8, paddingLeft: "5px" }}>PATIENT</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <View style={{ borderBottomWidth: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>INSURANCE COMPANY</Text>
                  <Text style={{ fontSize: 7 }}>{demographics?.insurance_name || ""}</Text>
                </View>
                <View style={{ borderBottomWidth: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>ADDRESS</Text>
                  <Text style={{ fontSize: 7 }} />
                </View>
                <View style={{ borderBottomWidth: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>CITY/STATE/ZIP</Text>
                  <Text style={{ fontSize: 7 }} />
                </View>
                <View
                  style={{
                    borderBottomWidth: 1,
                    flex: 1,
                    padding: "2px",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    fontSize: 7,
                  }}
                >
                  <View style={{}}>
                    <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>SUBSCRIBER #</Text>
                    <Text style={{ fontSize: 7 }}>{insuranceType.INSURANCE}</Text>
                  </View>
                  <View>
                    <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>GROUP No.</Text>
                    <Text style={{ fontSize: 7 }} />
                  </View>
                </View>
                <View style={{ borderBottomWidth: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>MEDI-CAL No.</Text>
                  <Text style={{ fontSize: 7 }}>{insuranceType.MEDICADE}</Text>
                </View>
                <View style={{ flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label, fontWeight: "bold", fontSize: 7 }}>MEDICARE No.</Text>
                  <Text style={{ fontSize: 7 }}>{insuranceType.MEDICARE}</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "column", flex: 1.2 }}>
              <View style={{ width: "100%", backgroundColor: "#566696", flex: 0.5, paddingTop: "3px" }}>
                <Text style={{ color: "white", ...styles.label, textAlign: "center" }}>
                  ICD-10 CODE(S) / DX SYMPTOMS
                </Text>
              </View>
              <View style={{ flexDirection: "row", flex: 1, backgroundColor: "white" }}>
                <View style={{ flex: 1, padding: "2px", justifyContent: "center" }}>
                  <Text style={{ ...styles.label, textAlign: "center" }}>{getDiagonosisCode}</Text>
                </View>
                <View style={{ borderLeft: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label }}></Text>
                </View>
                <View style={{ borderLeft: 1, flex: 1, padding: "2px" }}>
                  <Text style={{ ...styles.label }}></Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ flex: 1, borderLeft: 1 }}>
            <View style={{ flexDirection: "row", padding: "2px" }}>
              <View style={{ flex: 0.9 }}>
                <Text style={{ ...styles.label }}>Date of Birth</Text>
                <Text style={{ ...styles.field }}>{formatDateOfBirthDOB(demographics?.dob)}</Text>
              </View>
              <View style={{ flex: 0.6 }}>
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                  <Text style={{ ...styles.label, marginRight: "2px" }}>Sex</Text>
                  <View style={{ flexDirection: "row", marginLeft: "6px" }}>
                    <View>
                      <Text style={{ ...styles.label }}>M</Text>
                      <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                        {demographics?.sex === "M" && (
                          <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                        )}
                      </View>
                    </View>
                    <View style={{ marginLeft: "6px" }}>
                      <Text style={{ ...styles.label }}>F</Text>
                      <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                        {" "}
                        {demographics?.sex === "F" && (
                          <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                        )}
                      </View>
                    </View>
                    <View style={{ marginLeft: "6px" }}>
                      <Text style={{ ...styles.label }}>X</Text>
                      <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                        {" "}
                        {demographics?.sex === "X" && (
                          <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1.1, marginLeft: "10px" }}>
                <Text style={{ ...styles.label }}>Date Collected</Text>
                <Text style={{ ...styles.field }}>{formatDateMDY(test.createdAt)}</Text>
              </View>
              <View style={{ flex: 1.6, flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <Text style={{ ...styles.label }}>Time Collected</Text>
                  <Text style={{ ...styles.field }}>{formatTimeFromDate(test?.createdAt)}</Text>
                </View>
                <View>
                  <View style={{ flexDirection: "row", marginLeft: "5px", alignItems: "center" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {getAMPM(test?.createdAt) === "AM" && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ ...styles.label, textAlign: "center" }}>AM</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginLeft: "5px", alignItems: "center" }}>
                    <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }}>
                      {getAMPM(test?.createdAt) === "PM" && (
                        <Image src={tickIcon} style={{ height: 7, width: 7, textAlign: "center" }} />
                      )}
                    </View>
                    <Text style={{ ...styles.label, textAlign: "center" }}>PM</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ flexDirection: "row", borderTop: 1 }}>
              <View style={{ flex: 1, padding: "2px", flexDirection: "row" }}>
                <Text style={{ ...styles.label, flex: 1 }}>{"PATIENT \n I.D."}</Text>
                <Text style={{ ...styles.field, flex: 1 }}>{demographics.idNumber || test.empID || ""}</Text>
              </View>
              <View style={{ flex: 1, borderLeft: 1, padding: "2px", flexDirection: "row" }}>
                <Text style={{ ...styles.label, flex: 1 }}>{"COLLECTED By"}</Text>
                <Text style={{ ...styles.field, flex: 1 }}>{test.tester_name || ""}</Text>
              </View>
              <View style={{ flex: 1, borderLeft: 1, padding: "2px" }}>
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label, marginLeft: "5px" }}>TRAVEL</Text>
                </View>
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label, marginLeft: "5px" }}>VENIPUNCTURE</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", borderTop: 1 }}>
              <View style={{ flex: 1, padding: "2px", flexDirection: "row" }}>
                <Text style={{ ...styles.label, flex: 1 }}>{"PATIENT'S PHONE NO."}</Text>
                <Text style={{ ...styles.field, flex: 1 }}>{formatPhone(test.phoneNumber)}</Text>
              </View>
              <View style={{ flex: 1, borderLeft: 1, padding: "2px" }}>
                <Text style={{ ...styles.label }}>FAX/ CALL RESULTS TO:</Text>
              </View>
              <View style={{ flex: 1, borderLeft: 1, padding: "2px" }}>
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <Text style={{ ...styles.label }}>MILES TRAVELED:</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", borderTop: 1 }}>
              <View style={{ flex: 1, padding: "2px", flexDirection: "row" }}>
                <Text style={{ ...styles.label, color: "red", flex: 1 }}>{"PHYSICIANS'S \n NAME"}</Text>
                <Text style={{ ...styles.field, flex: 1 }}>{labInfo?.lab_director || ""}</Text>
              </View>
              <View
                style={{ flex: 1, flexDirection: "row", justifyContent: "space-around", borderLeft: 1, padding: "2px" }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label }}>STAT</Text>
                </View>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label }}>FAX</Text>
                </View>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label }}>CALL</Text>
                </View>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label }}>FASTING</Text>
                </View>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <View style={{ borderWidth: 1, height: 8, width: 8, ...styles.label }} />
                  <Text style={{ ...styles.label }}>RANDOM</Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", borderTop: 1 }}>
              <View style={{ flex: 0.7, padding: "2px" }}>
                <Text style={{ ...styles.label, color: "red" }}>{"NPI"}</Text>
                <Text style={{ ...styles.field }}>1003876038</Text>
              </View>
              <View
                style={{
                  flex: 1.3,
                  flexDirection: "row",
                  borderLeft: 1,
                  padding: "2px",
                }}
              >
                <Text style={{ ...styles.label, flex: 1, color: "red" }}>{"PHYSICIANS'S \n SIGNATURE"} </Text>

                {/* <Image
                  style={{
                    textAlign: "center",
                    marginHorizontal: 10,
                    flex: 1,
                    height: 20,
                  }}
                  src={PHY_SIGN}
                /> */}
              </View>
            </View>
            <View style={{ borderTop: 1, flexDirection: "column", padding: "2px" }}>
              <View>
                <Text style={{ ...styles.label, fontSize: 5.5 }}>
                  <Text style={{ ...styles.label, color: "red", fontSize: 6 }}>ADVANCE BENEFICIARY NOTICE:</Text>
                  if the medicare does not pay for{" "}
                  <Text style={{ ...styles.label, fontSize: 6, borderBottom: 1, width: "10px" }}></Text> you may have to
                  pay. Medicare does not pay for everything, even some care that you or your health care provider have
                  good reason to think you need. We expect Medicare may not pay for the{" "}
                  <Text style={{ borderBottom: 1 }}></Text>
                </Text>
              </View>
              <View>
                <Text style={{ ...styles.label, fontSize: 5.5 }}>
                  <Text style={{ ...styles.label, color: "red", fontSize: 6 }}>ASSIGNMENT OF BENEFITS:</Text>
                  it is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                  default model text,
                </Text>
              </View>
              <View>
                <Text style={{ ...styles.label, fontSize: 5.5 }}>
                  <Text style={{ ...styles.label, color: "red", fontSize: 6 }}>APPEAL AUTHORIZATION:</Text>
                  it is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                  default model text,
                </Text>
              </View>
              <View style={{ borderBottom: 1, flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ ...styles.label, color: "red" }}>PATIENT SIGNATURE:</Text>
                {hasImage && (
                  <Image
                    style={{
                      height: 25,
                      textAlign: "center",
                      marginHorizontal: 10,
                    }}
                    src={signature}
                  />
                )}
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", border: 1, borderRadius: "5px" }}>
          <View style={{ flex: 2.5, borderBottom: 1 }}>
            <View style={{ backgroundColor: "#DE6262", borderTopLeftRadius: "5px" }}>
              <Text style={{ color: "white", ...styles.label, textAlign: "center" }}>
                MARK <Image src={tickIcon} style={{ height: 7, width: 7 }} /> FOR PROFILE REQUESTED (See back for
                components)
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {PROFILE_REQUESTED[0].map((m, prIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`pr0_${prIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2.3 }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderLeft: 1,
                        padding: 2.3,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>
              <View style={{ flex: 1 }}>
                {PROFILE_REQUESTED[1].map((m, prIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderLeft: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`pr1_${prIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2.3 }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderLeft: 1,
                        padding: 2.3,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>
              <View style={{ flex: 1 }}>
                {PROFILE_REQUESTED[2].map((m, prIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderLeft: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`pr2_${prIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2.3 }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        borderLeft: 1,
                        justifyContent: "space-between",
                        padding: 1.3,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={{ backgroundColor: "#DE6262" }}>
              <Text style={{ color: "white", ...styles.label, textAlign: "center" }}>
                MARK <Image src={tickIcon} style={{ height: 7, width: 7 }} /> FOR INDIVIDUAL TEST(S) REQUESTED
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {INDIVIDUAL_REQUESTED[0].map((m, irIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`ir0_${irIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2 }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>

                    <View
                      style={{
                        flex: 1.5,
                        flexDirection: "row",
                        justifyContent: "space-between",

                        padding: 2,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      {m.sub && (
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.internalCode}</Text>
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.title}</Text>
                        </View>
                      )}
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>

              <View style={{ flex: 1 }}>
                {INDIVIDUAL_REQUESTED[1].map((m, irIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderLeft: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`ir1_${irIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2 }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>

                    <View
                      style={{
                        flex: 1.5,
                        flexDirection: "row",
                        justifyContent: "space-between",

                        padding: 2,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      {m.sub && (
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.internalCode}</Text>
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.title}</Text>
                        </View>
                      )}
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>
              <View style={{ flex: 1 }}>
                {INDIVIDUAL_REQUESTED[2].map((m, irIndex) => (
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: 1,
                      borderLeft: 1,
                      backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                    }}
                    key={`ir2_${irIndex}`}
                  >
                    <View style={{ flex: 0.3, flexDirection: "row", padding: 2.2 }}>
                      <View style={{ borderWidth: 1, height: 6, width: 6 }}>
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>
                    <View
                      style={{
                        flex: 1.5,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: m.internalCode === testCode ? "black" : "",
                        padding: 2.2,
                      }}
                    >
                      <Text style={{ ...styles.listField }}>{m.title}</Text>
                      {m.sub && (
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.internalCode}</Text>
                          <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.sub.title}</Text>
                        </View>
                      )}
                      <Text style={{ ...styles.listField }}>{m.type}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={{ flex: 1, borderLeft: 1 }}>
            <Text style={{ color: "red", textAlign: "center", ...styles.label, fontSize: 6, padding: 1 }}>
              MICROBIOLOGY (CULTURES & SENSITIVITY){" "}
            </Text>
            <Text style={{ ...styles.label, fontSize: "5", padding: 1 }}>
              SENSITIVITIES & IDS WILL BE PERFORMED IF INDICATED SOURCE:
            </Text>
            {MICROBIOLOGY.map((m, micIndex) => (
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: 1,
                  paddingLeft: 1,
                  paddingRight: 1,
                  backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                }}
                key={`mic_${micIndex}`}
              >
                <View style={{ flex: 0.3, flexDirection: "row" }}>
                  <View
                    style={{
                      borderWidth: 1,
                      height: 6,
                      width: 6,
                      marginLeft: 1,
                    }}
                  >
                    {isTestCodeMatch(test, m) && (
                      <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                    )}
                  </View>
                  <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ ...styles.listField }}>{m.title}</Text>
                  <Text style={{ ...styles.listField }}>{m.type}</Text>
                </View>
              </View>
            ))}

            <View>
              <Text style={{ color: "red", textAlign: "center", ...styles.label, fontSize: 6 }}>CYTOLOGY</Text>
              {CYTOLOGY[0].map((m, cytIndex) => (
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  key={`cyt0_${cytIndex}`}
                >
                  {m.hasOwnProperty("internalCode") && (
                    <View style={{ flex: 0.3, flexDirection: "row" }}>
                      <View
                        style={{
                          borderWidth: 1,
                          height: 6,
                          width: 6,
                        }}
                      >
                        {isTestCodeMatch(test, m) && (
                          <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                        )}
                      </View>
                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                    </View>
                  )}
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ ...styles.listField }}>{m.title}</Text>
                    <Text style={{ ...styles.listField, borderBottom: 1, flex: 0.6 }}></Text>
                  </View>
                </View>
              ))}
              <View style={{ flexDirection: "row", paddingLeft: 1, paddingRight: 1 }}>
                <View style={{ flex: 1.2 }}>
                  {CYTOLOGY[1].map((m, cytIndex) => (
                    <View style={{ flexDirection: "row" }} key={`cyt1_${cytIndex}`}>
                      <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />

                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.title}</Text>
                    </View>
                  ))}
                </View>
                <View style={{ flex: 1.5 }}>
                  {CYTOLOGY[2].map((m, cytIndex) => (
                    <View style={{ flexDirection: "row", marginLeft: "2px" }} key={`cyt2_${cytIndex}`}>
                      <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />

                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.title}</Text>
                    </View>
                  ))}
                </View>
                <View style={{ flex: 1 }}>
                  {CYTOLOGY[3].map((m, cytIndex) => (
                    <View style={{ flexDirection: "row", marginLeft: "4px" }} key={`cyt3_${cytIndex}`}>
                      <View style={{ borderWidth: 1, height: 6, width: 6, ...styles.label }} />

                      <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.title}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View
              style={{
                color: "red",
                flexDirection: "row",
                justifyContent: "center",
                borderTop: 1,
                alignItems: "center",
              }}
            >
              <View style={{ borderWidth: 1, height: 7, width: 7, ...styles.label, padding: 1, borderColor: "red" }} />
              <Text style={{ ...styles.label, fontSize: 6, padding: 1 }}>805 B I O P S Y </Text>
            </View>
            {BIOPSY.map((m, biIndex) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
                key={`bi_${biIndex}`}
              >
                <Text style={{ ...styles.listField }}>{m.title}</Text>
                <Text style={{ ...styles.listField, borderBottom: 1, flex: 0.6 }}></Text>
              </View>
            ))}
            <Text style={{ color: "red", borderTop: 1, textAlign: "center", ...styles.label, fontSize: 6, padding: 1 }}>
              PE (PRESUMPTIVE ELIGIBILITY)
            </Text>
            {PRESUMPTIVE_ELIGIBILITY.map((m, PEIndex) => (
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: 1,
                  paddingLeft: 1,
                  paddingRight: 1,
                  backgroundColor: isTestCodeMatch(test, m) ? "yellow" : "white",
                }}
                key={`PE_${PEIndex}`}
              >
                <View style={{ flex: 0.3, flexDirection: "row" }}>
                  <View
                    style={{
                      borderWidth: 1,
                      height: 6,
                      width: 6,
                      marginLeft: 1,
                    }}
                  >
                    {isTestCodeMatch(test, m) && (
                      <Image src={tickIcon} style={{ height: 6, width: 6, textAlign: "center" }} />
                    )}
                  </View>
                  <Text style={{ ...styles.listField, marginLeft: "2px" }}>{m.internalCode}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ ...styles.listField }}>{m.title}</Text>
                  <Text style={{ ...styles.listField }}>{m.type}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={{ border: 1, borderRadius: "5px", flex: 1, padding: 1, position: "relative" }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 4 }}>
              <Text style={{ textTransform: "uppercase", ...styles.label }}>
                special diagnosis and additional test requests | comments instructions
              </Text>
              <Text style={{ ...styles.label }}>Source: {capitalizeLetter(demographics?.swabtype)}</Text>
              <Text style={{ ...styles.label }}>Lab Comments: {demographics?.comment}</Text>
              <Text style={{ ...styles.label }}>Tests: {testsName}</Text>
            </View>
            <View style={{ flex: 1.5 }}>
              <Text style={{ ...styles.label, fontSize: 8 }}>Received Date: </Text>
              <Text style={{ fontSize: 9 }}>
                {/* {`${new Date(parseSrNoObjectRecviedDate(test, "dateOnly")).toDateString()} ${new Date(
                  parseSrNoObjectRecviedDate(test, "dateOnly")
                ).toLocaleString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}`} */}
                {testReceivedDate}
              </Text>
              <Text style={{ ...styles.label, fontSize: 8 }}>Received By:</Text>
              <Text style={{ fontSize: 9 }}>{test?.scanByLabUserName}</Text>
            </View>
          </View>

          <Text
            style={{
              ...styles.label,
              position: "absolute",
              fontWeight: "normal",
              bottom: "0",
              paddingLeft: 2,
              paddingRight: 2,
              fontSize: 7,
            }}
          >
            For any patient of any major insurance (including medical and medicare), only test that are MEDICALLY
            NECESSARY for the DIAGNOSIS or TREATEMENT of the PATIENT.
          </Text>
        </View>

        <View style={{ flexDirection: "row", marginTop: 2 }}>
          <View>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                SST
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>
              Serium SEP
            </Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                LV
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>
              lavender
            </Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                GR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>gray</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                BL
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>BLUE</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                UR
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>urine</Text>
          </View>
          <View style={{ marginLeft: 2 }}>
            <View style={{ flexDirection: "row", border: 1, alignItems: "center" }}>
              <View style={{ borderRightWidth: 1, marginLeft: 2, height: 8, width: 8, ...styles.label }} />
              <Text style={{ ...styles.label, marginLeft: "2px", fontSize: 6, paddingLeft: 2, paddingRight: 2 }}>
                OTHERS.
              </Text>
            </View>
            <Text style={{ textTransform: "uppercase", ...styles.label, fontSize: 6, textAlign: "center" }}>
              OTHERS
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontFamily: "Arial", // Use the Arial font for the entire document
  },
  infoText: {
    fontSize: 10,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  hr: {
    borderLeftColor: "black",
    borderLeftWidth: 1,
    // width: "100%",
  },
  address: {
    marginLeft: 50,
    marginVertical: 20,
  },
  label: {
    fontSize: 7,
    fontWeight: "bold",
  },
  field: {
    fontSize: 7,
  },
  listField: {
    fontSize: 5.5,
    fontWeight: "bold",
  },
  billingList: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default BioLabBiologicalPDF;
