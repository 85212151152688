import React from "react";

const OrderTestHeader = ({ showSelection, testDone }) => {
  return (
    <tr>
      {showSelection && <th></th>}
      <th className="text-center cursor-pointer">Test Name</th>

      <th className="">Source</th>
      <th className="text-center">Procedure Code</th>
      <th>Accession No</th>
      <th className="text-center">Clinical Comments</th>

      {!showSelection && <th className="text-center">Remove</th>}
    </tr>
  );
};

export default OrderTestHeader;
