import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = (props) => {
  const { title, message, handleConfirm, isPreRegister, handleClose, cssClass } = props;
  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose ? handleClose(false) : handleConfirm(false);
      }}
      centered
      size={"md"}
      className={cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {message}
        </Modal.Title>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="modalButtons"
          variant="secondary"
          onClick={() => {
            handleConfirm(false);
          }}
        >
          {isPreRegister ? "No" : "Go back"}
        </Button>
        <Button
          className="modalButtons"
          variant="primary"
          onClick={() => {
            handleConfirm(true);
          }}
        >
          {isPreRegister ? "Yes" : " Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
