import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import MainTable from "components/Table/MainTable";
import API from "api";
import { CONFIG } from "constant";
import { TEST_DONE_MODAL_COLUMN_DETAILS } from "constants/personalization";
import { useDispatch } from "react-redux";
import { setTestPrintView } from "../../store/features/general/generalSlice";

const TestDoneModal = (props) => {
  const { patient, order, showResult, handleClose } = props;

  const [tests, setTests] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    loadTestData();
  }, []);

  const loadTestData = async () => {
    if (patient) {
      const tests = await API.getAdminTestList(patient.mdID);
      setTests(
        tests.filter((t) => {
          if (t._deleted) return false;
          if (CONFIG.siteID) t.site !== CONFIG.siteID;
          return true;
        })
      );
    } else if (order) {
      const testsToDo = order.testsToDo || {};
      const test = testsToDo.test || [];

      const ids = test.filter((t) => t.testID).map((t) => t.testID);
      if (ids.length === 0) {
        setTests(
          test.map((t) => {
            return { ...t, createdAt: order.orderDate, test_type: t.label, barcode: "" };
          })
        );
      } else {
        const tests = await API.getMultipleTests({ ids });
        setTests(tests || []);
      }
    }
  };

  const handleCellClick = (item, row) => {
    if (!row) return;

    if (item === "result" && (row.result || row.resultDetails)) showResult(row);
    else if (item === "req_form" && row.barcode) appContext.showRequisitionForm({ testID: row.id });
    else if (item === "print") {
      dispatch(setTestPrintView(row));
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const getName = () => {
    if (patient) return `Test Info - ${patient?.firstName} ${patient?.lastName}`;
    if (order) return `${order?.firstName} ${order?.lastName} - Test Details`;
    return "Test Info";
  };

  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} size={"xl"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {getName()}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          <>
            <div className="shows-screen-parent">
              <Row className="mx-0">
                <Col md="12">
                  <Card className="striped-tabled-with-hover bg-transparent">
                    <Card.Body
                      className="table-full-width px-0 desktop-noScroll mt-0 pt-0"
                      style={{ backgroundColor: "#f8f8f8" }}
                    >
                      <MainTable
                        columns={TEST_DONE_MODAL_COLUMN_DETAILS}
                        rows={tests}
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        handleCellClick={handleCellClick}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse">
          <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestDoneModal;
