import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH } from "constant";
import moment from "moment";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import PreviousNotes from "./PreviousNotes";

const TestNoteModal = (props) => {
  const { data, handleClose, user } = props;

  const [notes, setNotes] = useState(data.note || data.notes);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onHandleSave = async () => {
    setLoading(true);
    const obj = {
      id: data.id,
      note: notes,
      updatedBy: user.sub,
      updatedByName: user.name,
    };

    try {
      setLoading(true);

      if (data.objectType === "test") {
        const { employee_demographics } = data;
        const demos =
          typeof employee_demographics === "string" ? JSON.parse(employee_demographics) : employee_demographics;

        demos.clinicalcomment = notes;
        const objToUpdate = { employee_demographics: JSON.stringify(demos) };
        await api.updateTest(obj.id, objToUpdate);
      }
      if (data.handleUpdateNoteReocrd) {
        data.handleUpdateNoteReocrd(obj);
      }
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"lg"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Notes {data && `for ${data.title}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={"Note!"}
                className="MessageInput"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                maxLength={NOTES_MAX_CHARACTER_LENGTH}
              />
            </div>
          </div>
          <PreviousNotes id={data.id} user={user} />
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div className="d-flex">
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              Save
            </Button>
          </div>
          {loading && <Loader />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestNoteModal;
