import React, { useState } from "react";
import Styles from "./FilledCheckBox.module.css";

const FilledCheckBox = (props) => {
  const { id, label, isChecked, handleChange, disabled, cssClass } = props;


  return (
    <div className={cssClass ? cssClass : `${Styles.filledCheckBox}  `}>
      <input
        id={id}
        className={`${Styles.checkbox}`}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={id} className={`${Styles.customLabel}`}>
        <span className={`checkbox ${isChecked ? "checkbox--active" : ""}`} />
        <span>{label}</span>
      </label>
    </div>
  );
};
export default FilledCheckBox;
