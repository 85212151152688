import api from "api";
import Loader from "components/Loader/Loader";
import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS } from "constant";
import { RESULT_SETTINGS_OPT } from "constant";
import { SHOW_TEST_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import React, { useEffect, useState, useCallback, useRef, memo } from "react";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { customIsJsonString } from "util/customLodash";
import { customIsObject } from "util/customLodash";

const TableRow = memo(({ row, onUpdateTestResult }) => {
  const inputRef = useRef(null);

  const handleBlur = (event) => {
    onUpdateTestResult(event.target.value, row.id);
  };

  return (
    <td>
      {row.possibleValues && row.possibleValues.length > 0 ? (
        <Select
          className="w-100 siteSelector"
          options={row?.possibleValues || RESULT_SETTINGS_OPT}
          blurInputOnSelect={true}
          defaultValue={null}
          menuPlacement="auto"
          value={customIsJsonString(row.testResult) && JSON.parse(row.testResult)}
          placeholder="Select Result"
          onChange={(e) => onUpdateTestResult(e, row.id)}
        />
      ) : (
        <input
          className="w-100"
          ref={inputRef}
          defaultValue={row.testResult || ""}
          onBlur={handleBlur}
          onFocus={() => inputRef.current && inputRef.current.select()}
          key={row.id}
        />
      )}
    </td>
  );
});

const AssignResultsTestModal = ({ handleClose, selectedTest, testTypeResult }) => {
  const [resultsData, setResultsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const appContext = useContext(AppContext);

  const compareTestResults = (array1, array2) => {
    if (!array1 || !array2) return true;
    return (
      array1.length !== array2.length || array1.some((item, index) => item?.testResult !== array2[index]?.testResult)
    );
  };

  useEffect(() => {
    const resultRows = selectedTest?.resultDetails ? selectedTest.resultDetails : testTypeResult;

    setResultsData(resultRows);
  }, [testTypeResult, selectedTest]);

  const handleUpdateTestResult = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.id === id);
      if (index !== -1) {
        const newData = [...currentResultsData];
        newData[index] = { ...newData[index], testResult: customIsObject(value) ? JSON.stringify(value) : value };
        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const isEdit = compareTestResults(resultsData, selectedTest.resultDetails);
      if (isEdit) {
        const updatedObj = { id: selectedTest.id, resultDetails: resultsData };
        await api.assingTestResult(updatedObj);
      }
      handleClose();
      appContext.showSuccessMessage(`${selectedTest.name} result marked.`);
    } catch (error) {
      console.log("Error", error);
      appContext.showErrorMessage(`Error in ${selectedTest.name} result.`);
    } finally {
      setIsLoading(false);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "testResult") {
      return <TableRow row={row} onUpdateTestResult={handleUpdateTestResult} />;
    }
    if (item.itemKey === "unit") {
      return <td key={`unit_${item.id}`}>{row?.possibleValues ? "" : row.unit}</td>;
    }
  };

  return (
    <Modal
      show
      animation={true}
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result for {selectedTest.name}-{selectedTest.barcode}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        {isLoading && <Loader />}

        <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
          <MainTable
            cssClass="table-noresponsive"
            trDataWrapperHeader={"trDataWrapperHeader"}
            customColumnCellRenderer={customRenderTD}
            columns={SHOW_TEST_PERSONALIZE}
            rows={resultsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignResultsTestModal;
