import { TABLE_COLUMN_TYPES, GET_CUST_COLOR } from "constant";

export const UNPROCESSED_LOCATION_COLUMN_DETAILS = [
  {
    itemKey: "createdAt",
    title: "Date Collected",
    textAlign: "left",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 1,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 2,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 3,
  },
  {
    itemKey: "dob",
    title: "DOB",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "sex",
    title: "Gender",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "street2",
    title: "Room No",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "test_type",
    title: "Test Type",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    id: 7,
  },
  {
    itemKey: "barcode",
    title: "Accession No",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    customCellRenderer: true,
    id: 8,
  },

  {
    itemKey: "pcrStatus",
    title: "Cust Status",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    colorObj: GET_CUST_COLOR,
    id: 9,
  },
  {
    itemKey: "tester_name",
    title: "Tester",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 10,
  },
];


export const COMPLETED_TEST_LOCATION_COLUMN_DETAILS = [
  {
    itemKey: "createdAt",
    title: "Date Collected",
    textAlign: "left",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 1,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 2,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 3,
  },
  {
    itemKey: "dob",
    title: "DOB",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "sex",
    title: "Gender",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "street2",
    title: "Room No",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "test_type",
    title: "Test Type",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    id: 7,
  },
  {
    itemKey: "barcode",
    title: "Accession No",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    id: 8,
  },

  {
    itemKey: "result",
    title: "Result",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.result,
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "tester_name",
    title: "Tester",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 10,
  },
];

export const UNPROCESSED_COLUMN_DETAILS = [
  {
    itemKey: "createdAt",
    title: "Date Collected",
    textAlign: "left",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 1,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 2,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 3,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.phone,
    isCheck: true,
    id: 4,
  },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "test_type",
    title: "Test Type",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "barcode",
    title: "Accession No",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    customCellRenderer: true,
    id: 7,
  },
  {
    itemKey: "site_name",
    title: "Location",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "pcrStatus",
    title: "Cust Status",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    colorObj: GET_CUST_COLOR,
    id: 9,
  },
  {
    itemKey: "tester_name",
    title: "Tester",
    textAlign: "center",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    id: 10,
  },
];
