import api from "api";
import { createRef, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataStore } from "@aws-amplify/datastore";
import { medFlowInLocalStorage, showsInSS } from "utils";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import ResendOTP from "./ResendOTP";
import { formatTwoFaErrorMessage } from "utils";

const TwoFa = ({ goToPortal, setLoading, setTwoFa }) => {
  const inputRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);

  const [digits, setDigits] = useState(Array(6).fill(""));

  useEffect(() => {
    if (digits.join("").length === 6) {
      handelOtpVerification();
    }
  }, [digits]);

  let history = useHistory();

  const [twoFaError, setTwoFaError] = useState(null);

  const handleChange = (value, index) => {
    if (value !== "" && !/^\d$/.test(value)) {
      return;
    }

    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    if (value) {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === "Backspace" && !digits[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handelOtpVerification = async () => {
    console.log("digitBeforeJOin", digits);

    const code = digits.join("");

    console.log("CodeAfterJoin", code);
    setLoading(true);

    try {
      const result = await api.verifyOtp(code);
      console.log("Result", result);
      if (result?.verified) {
        goToPortal();
        setLoading(false);
      }
    } catch (error) {
      console.log({ error });

      const errorMessage = formatTwoFaErrorMessage(error);
      setTwoFaError(errorMessage);
      setLoading(false);
    }
  };

  const backToLogin = async () => {
    await DataStore.clear();
    await Auth.signOut();
    medFlowInLocalStorage.clear();
    showsInSS.clear();
    setTwoFa(false);
    history.push("/login");
  };

  return (
    <>
      <SweetAlert show={twoFaError ? true : false} error title="Error" onConfirm={() => setTwoFaError(null)}>
        {twoFaError}
      </SweetAlert>

      <div className="registerFormWrapper">
        <div className="registerWrapper">
          <div className="codeScannerWrapper codeScannerPhoneInput">
            <div className="registered-successful">
              <h3 className="w-100 text-center mt-2">
                <b>Authenticate Your Account</b>
              </h3>
              <p style={{ fontSize: "17px" }}>
                Please confirm your account by entering the authorization code sent to your mobile / email address.
              </p>
              <div className="custom-radio-btn-wrapper">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={digits[index]}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleKeyUp(e, index)}
                    style={{ width: "45px", textAlign: "center", margin: "0 5px" }}
                  />
                ))}
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={digits.join("").length !== 6}
                  className="text-uppercase w-50 btn signin-btn"
                  onClick={handelOtpVerification}
                >
                  Verify
                </button>
                <ResendOTP setTwoFaError={(value) => setTwoFaError(value)} setLoading={(value) => setLoading(value)} />
                <p className="mt-2" style={{ cursor: "pointer", fontSize: "15px" }} onClick={backToLogin}>
                  Back to Login?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFa;
