import deleteIcon from "assets/img/trash-icon.png";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { TEST_SOURCE_OPTIONS } from "constant";
import Status from "components/Status/Status";

import { parseJSONObject } from "utils";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import { eligibilityMessage } from "utils";
import { eligibilityMessageColor } from "utils";

const CreateTestItem = (props) => {
  const {
    item,
    index,
    onItemChange,
    onItemRemove,
    showSelection,
    testDone,
    handleEditTest,
    testDoneList,
    appContext,
    order,
  } = props;
  const [newItem, setNewItem] = useState(item);

  useEffect(() => {
    setNewItem(item);
  }, [item]);

  const handleChangeSource = (e) => {
    const updatedItem = { ...newItem, selectedSource: e.label };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const handleInputComment = (e) => {
    const updatedItem = { ...newItem, clinicalcomment: e.target.value };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const handleItemSelection = (e) => {
    const updatedItem = { ...newItem, isSelected: !newItem.isSelected };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const isTestDone = useMemo(() => {
    if (item?.isDone) return item;
    const testInTestDoneList = testDoneList?.find(
      (t) => parseJSONObject(t.employee_demographics)?.testTypeID === item.id
    );
    if (testInTestDoneList) {
      return { isDone: true, barcode: testInTestDoneList.barcode };
    }
    return { isDone: false, barcode: null };
  }, [item, testDoneList]);

  const eligMessage = eligibilityMessage(order["eligibilityStatus"], order);

  const showEligibilityModal = () => {
    appContext.showEligibilityDetails({
      ...order,
      ...order.eligibilityStatus,
      ins_name_f: order.firstName,
      ins_name_l: order.lastName,
      ins_dob: order.dob,
    });
  };

  const handelPa = (e) => {
    const updatedItem = { ...newItem, isPA: !newItem.isPA };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  return (
    <tr key={index}>
      <td className="text-center cursor-pointer">
        <Form.Check>
          <Form.Check.Input
            type="checkbox"
            checked={item.isSelected}
            value={item.value}
            disabled={isTestDone.isDone}
            onChange={(event) => handleItemSelection(event, index)}
          />
        </Form.Check>
      </td>

      <td className="text-center cursor-pointer">{newItem.label || newItem.name}</td>

      <td className="">
        <Select
          options={newItem.sources || TEST_SOURCE_OPTIONS}
          blurInputOnSelect={true}
          menuPlacement="auto"
          className="w-100 siteSelector site-select-test"
          placeholder="Select Source"
          // isMulti
          value={newItem.selectedSource ? { label: newItem.selectedSource, value: newItem.selectedSource } : null}
          onChange={handleChangeSource}
          // components={{ ValueContainer: ReactSelectCustomContainer }}
        />
      </td>

      <td>
        <span>{isTestDone.barcode} </span>
      </td>

      <td className="text-center">
        <input
          className="modalInput"
          style={{
            width: "100%",
            flexShrink: "initial",
          }}
          value={newItem?.clinicalcomment}
          onChange={handleInputComment}
        />
      </td>

      <td>
        <div className="star-icon-wrapper cursor-pointer text-center" onClick={showEligibilityModal}>
          <Status
            type="circle"
            size="md"
            color={eligibilityMessageColor(eligMessage)}
            crossIcon="Yes"
            title={eligMessage}
          />
        </div>
      </td>

      <td className="text-center cursor-pointer">
        <Form.Check>
          <Form.Check.Input type="checkbox" checked={item.isPA} onChange={(event) => handelPa(event, index)} />
        </Form.Check>
      </td>

      <td className="text-center cursor-pointer">
        <i className="fas fa-print mx-2 cursor-pointer" onClick={() => appContext.printBarcode(item)}></i>
      </td>
    </tr>
  );
};

export default CreateTestItem;
