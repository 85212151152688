import API from "api";
import { Auth } from "aws-amplify";

import { CONFIG, HEALTH_AND_SAFTEY, defaultPersonalization } from "constant";
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  connectedIDInLocalStorage,
  medFlowInLocalStorage,
  medFlowPortalTitle,
  personalisationInLC,
  roleInLocalStorage,
  sortingFilterInLC,
} from "utils";
import loginLogo from "../../assets/img/login_logo.png";
import LoginUi from "./LoginUi";
import MultiRole from "./MultiRole";
import TwoFa from "./TwoFa";
import useLocalStorageState from "util/useLocalStorageState";
import Loader from "components/Loader/Loader";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userId, setUserId] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [multiRole, setMultiRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [twoFa, setTwoFa] = useLocalStorageState("twoFa", false);

  const [rolesData, setRolesData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useLocalStorageState("userData", null);

  let history = useHistory();

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) redirectToAdmin();
    };
    getUSer();
  }, []);

  const handleMultiRole = () => {
    setMultiRole(true);
  };

  const handleRoleSelection = async (obj, uid) => {
    let role = obj.role;
    let type = "A";
    if (role === "Labs" || role === "Lab") {
      type = "L";
    } else if (role === "LabTechs") {
      type = "LT";
    } else if (role === "Employers" || role === "Employer" || role === HEALTH_AND_SAFTEY) {
      type = "E";
    } else if (role === "Clients" || role === "Client") {
      type = "C";
    }

    // get connected Id
    medFlowInLocalStorage.save(type);
    medFlowPortalTitle.save(obj.title);
    connectedIDInLocalStorage.save(obj.relatedId);
    sortingFilterInLC.save(sortingFilterInLC.get());
    roleInLocalStorage.save(role);

    if (CONFIG.isWhiteLabel) {
      CONFIG.whiteLabelID = obj.labID;
      CONFIG.clientID = obj.clientID;
      CONFIG.siteID = obj.siteID;
    }

    if (type) {
      let data = null;
      try {
        data = await API.getUserPersonalisation(userId || uid);
      } catch (e) {
        console.log(e);
      }
      if (data && data.length > 0) {
        personalisationInLC.save(
          "",
          typeof data[0].personalisation === "string" ? JSON.parse(data[0].personalisation) : data[0].personalisation
        );
      } else {
        personalisationInLC.save("", defaultPersonalization());
      }
    }
    setLoading(false);
    setTwoFa(false);
    redirectToAdmin();
  };

  const GET_USER_ROLES = {
    Employers: "Employer",
    Admins: "Admin",
    Labs: "Lab",
    Clients: "Client",
  };

  const handleSubmit = async () => {
    setMultiRole(false);
    setTwoFa(false);
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPhoneError("please enter the password");
      return;
    }

    try {
      setDisableBtn(true);
      const user = await Auth.signIn(phone, password);

      if (user && user.attributes["custom:is2FaEnabled"] === "y") {
        setUserData(user);
        setTwoFa(true);
      } else {
        goToPortal(user);
      }
    } catch (error) {
      console.log("Error", error);
      setDisableBtn(false);
      setUserId("");
      setUserRoles([]);
      setRolesData([]);
      setPhoneError(error.message);
    } finally {
      setDisableBtn(false);
    }
  };





  const goToPortal = async (user) => {

    if (!user) return;

    const roles = user.signInUserSession.accessToken.payload["cognito:groups"] || [];

    let defaultRole = GET_USER_ROLES[roles[0]];

    let getUserRoles = await API.getEmployeeRoles(user?.username);

    let availableRoles = getUserRoles.length > 0 ? JSON.parse(getUserRoles[0]?.roles) : [{ role: defaultRole }];

    let LabAdmin = getUserRoles.length > 0 ? getUserRoles[0].labAdmin : null;

    if (CONFIG.url && CONFIG.isWhiteLabel) {
      availableRoles = availableRoles.filter(
        (role) =>
          role.role === "Lab" || role.role?.includes("LabTech") || role.role === HEALTH_AND_SAFTEY || role.role === "Employers"
      );
      if (availableRoles.length === 0) {
        console.log("User is not authorized");

        setDisableBtn(false);
        setPhoneError("Not Authorized!!!");
        await Auth.signOut();
        return;
      }
    } else {
      availableRoles = availableRoles
        .filter((role) => !role.isWhiteLabelLab)
        .filter((value, index, self) => index === self.findIndex((t) => t.relatedId === value.relatedId));
    }

    getUserRoles = availableRoles;
    let connectedID = user.attributes["custom:connectedID"];

    const { clientID, labID, siteID } = getUserRoles[0];

    if (getUserRoles.length > 0) {
      let userRole = getUserRoles;
      setUserRoles(userRole);

      if (LabAdmin) {
        const AdminRole = userRole.filter((f) => f.role === "Admin");
        const clientRole = userRole.filter((f) => f.role === "Client");
        handleRoleSelection({ ...AdminRole[0], relatedId: LabAdmin }, user?.username);
        clientIDInLocalStorage.save(CONFIG.isWhiteLabel ? CONFIG.clientID : clientRole[0].relatedId);
        return;
      }
      if (userRole?.length > 1) {

        setUserId(user?.username);
        let rolesGet = userRole.filter((role) =>
          ["Lab", "LabTech", "Admin", "Client", HEALTH_AND_SAFTEY].includes(role.role)
        );
        let roleArrFormat = [];
        if (rolesGet.length === 1) {
          handleRoleSelection(rolesGet[0], user?.username);
          return;
        }

        if (rolesGet) {
          roleArrFormat = rolesGet.map((element) => {
            if (element.role === HEALTH_AND_SAFTEY || element.role === "Employers") {
              return { ...element, role: "Employers" };
            }
            return { ...element, role: `${element.role}s` };
          });
        }
        setRolesData(roleArrFormat);
        handleMultiRole();

        return;
      } else if (userRole?.length === 1 && clientID) {

        connectedID = userRole[0]?.relatedId;
        if (CONFIG.isWhiteLabel) {
          CONFIG.whiteLabelID = labID;
          CONFIG.clientID = clientID;
          CONFIG.siteID = siteID;
        }
        setRolesData([]);
      } else {
        setRolesData([]);
      }
    }


    let type = "A";

    if (getUserRoles?.some((s) => s.role === "Lab")) {
      type = "L";
    }
    if (getUserRoles?.some((s) => s.role === "LabTech")) {
      type = "LT";
    } else if (
      getUserRoles.some((s) => s.role === "Employer" || s.role === HEALTH_AND_SAFTEY || s.role === "Employers")
    ) {
      type = "E";
    } else if (getUserRoles.some((s) => s.role === "Client")) {
      type = "C";
    }
    medFlowPortalTitle.save(getUserRoles[0]?.title || "");
    medFlowInLocalStorage.save(type);
    connectedIDInLocalStorage.save(connectedID);
    sortingFilterInLC.save(sortingFilterInLC.get());

    if (type) {
      let data = null;
      try {
        data = await API.getUserPersonalisation(user.username);
      } catch (e) {
        console.log(e);
      }
      if (data && data.length > 0) {
        personalisationInLC.save(
          "",
          typeof data[0].personalisation === "string" ? JSON.parse(data[0].personalisation) : data[0].personalisation
        );
      } else {
        personalisationInLC.save("", defaultPersonalization());
      }
    }
    setLoading(false);
    setTwoFa(false);
    redirectToAdmin();
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (!twoFa) {
      if (history) history.push("/admin");
      else window.location.reload();
    }
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={loginLogo} alt="logo" className="logo-width" />}</div>
        {phoneError && (
          <SweetAlert show={phoneError ? true : false} error title="Error" onConfirm={() => setPhoneError("")}>
            {phoneError}
          </SweetAlert>
        )}
        {loading && <Loader />}
      </div>
      {multiRole ? (
        <MultiRole roles={rolesData} handleRoleSelection={handleRoleSelection} />
      ) : twoFa ? (
        <TwoFa
          goToPortal={() => goToPortal(userData)}
          setLoading={(value) => setLoading(value)}
          setTwoFa={(value) => setTwoFa(value)}
        />
      ) : (
        <LoginUi
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          handleKeyDownEvent={handleKeyDownEvent}
          disableBtn={disableBtn}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Login;
