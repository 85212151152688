import { TESTS_UNITS_SET, TEST_CATEGORY_OPTIONS, TEST_TYPE_OPTIONS, DISCLAIMER_TYPE, DISCLAIMER_KEYS } from "constant";
import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { generateRefInterval } from "utils";
import TestDetailsSelector from "./TestDetailsSelector";
import MainTable from "components/Table/MainTable";
import { CREATE_TEST_PERSONALIZE } from "constants/personalization";
import Icon from "components/Icon";
import DiagnosisAccordionView from "./TestDiagnosisView/DiagnosisAccordionView";
import { AppContext } from "context/app-context";
import api from "api";
import TextEditor from "views/TextEditor";
import { REQ_FORM_TESTS_NAME } from "biolabRequisitionFormConstant";
import TestInputField from "./TestInputField";
import { TABLE_QUICK_TOOLS } from "constant";
import TestSelector from "./TestUnitSelector/TestSelector";
import CptList from "components/CPT/CptList";
import { CPT_CODE_SEARCH_TYPE } from "constant";
import moment from "moment";
import InputField from "components/InputField/InputField";
import TestTextAreaField from "./TestTextAreaField";
import LabelIdSelector from "./LabelIDSelector/LabelIdSelector";
import { TEST_SETTING_TYPES } from "constant";
import ProcedureCodeView from "./ProcedureCode/ProcedureCodeView";
import ProcedureCodeDetailView from "./ProcedureCode/ProcedureCodeDetailView";

const TestTypeModal = ({ item, handleSave, handleClose, openDetailModal, mainTests, setTestPanelData }) => {
  const [newItem, setNewItem] = useState(item);
  const [ictCodes, setIctCodes] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const appContext = useContext(AppContext);
  const [showEditor, setShowEditor] = useState(null);
  const [showDiagModal, setShowDiagModal] = useState(false);
  const [showProcedureCodeModal, setShowProcedureCodeModal] = useState(false);

  const [filteredTestList, setFilteredTestList] = useState([]);
  const [cptList, setCptList] = useState([]);

  useEffect(() => {
    if (item.targetRanges) {
      setFilteredTestList(item.targetRanges);
    }
    if (item.ictCodes && item.ictCodes.length > 0) {
      setIctCodes(item.ictCodes);
      setCptList(item.ictCodes[0]?.cptCodes || item.cptCodes || []);
    }
  }, [item]);

  const validateInput = () => {
    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid test name");

      return;
    }
    if (!newItem.code) {
      appContext.showErrorMessage("Enter valid test code");
      return;
    }
    if (!newItem.category) {
      appContext.showErrorMessage("Select the valid category");
      return;
    }
    if (!newItem.sources || newItem.sources.length === 0) {
      appContext.showErrorMessage("Select the valid category");
      return;
    }
    if (!newItem.typeOfTest) {
      appContext.showErrorMessage("Select the valid Result Methodology");
      return;
    }
    if (!newItem.units) {
      appContext.showErrorMessage("Select test unit");
      return;
    }
    if (newItem.typeOfTest === "Quantitative" && filteredTestList.length === 0) {
      appContext.showErrorMessage("Add Atleast One Associated Panels");
      return;
    }
    const refInt = generateRefInterval(newItem);

    const testObj = {
      ...newItem,
      ictCodes: ictCodes && ictCodes.length > 0 ? [{ ...ictCodes[0], cptCodes: cptList }] : [],
      refInterval: newItem?.typeOfTest === "Quantitative" ? refInt : "NEGATIVE",
      targetRanges: newItem?.typeOfTest === "Quantitative" ? filteredTestList : null,
      minValue: newItem?.typeOfTest === "Quantitative" ? newItem.minValue : 0,
      maxValue: newItem?.typeOfTestl === "Quantitative" ? newItem.maxValue : 0,
      cptCodes: ictCodes && ictCodes.length === 0 ? cptList : [],
      isActive: true,
    };
    if (newItem.isNew) {
      const isAlreadyExist = mainTests.some(({ name }) => name.toLowerCase().includes(newItem.name.toLowerCase()));
      if (isAlreadyExist) {
        appContext.showErrorMessage("Test with same name already exists");
        return;
      }
    }

    handleSave(testObj);
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "action") {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: "right" }}>
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            handleClick={() => handleTestRemove(row)}
          />
        </td>
      );
    }
    if (item.itemKey === "units") {
      return <td key={`unit_${item.id}`}>{row?.units?.label ? row.units.label : ""}</td>;
    }
  };

  const handleTestRemove = (item) => {
    setFilteredTestList((prevList) => prevList.filter((x) => x.id !== item.id));
  };

  const handleTestSelect = (item) => {
    setFilteredTestList((prevList) => {
      const ids = new Set(prevList.map((x) => x.id));
      return ids.has(item.id) ? prevList : [...prevList, item];
    });
  };

  // const saveDiagnosisCodes = async (cpt) => {
  //   setIctCodes(cpt);
  //   setShowDiagModal(false);
  //   if (!newItem.hasOwnProperty("isNew")) {
  //     await api.updateTestTypes({ ...newItem, ictCodes: cpt });
  //   }
  // };

  const handleDisclaimerSave = (val, type) => {
    setShowEditor(null);
    setNewItem({ ...newItem, [DISCLAIMER_KEYS[type]]: val });
  };

  const handelAddTestName = (selectedTest) => {
    if (selectedTest) {
      setNewItem({ ...newItem, name: selectedTest.label, code: selectedTest.value });
    }
  };

  const handleInputChange = (inputValue) => {
    if (inputValue) {
      setNewItem({ ...newItem, name: inputValue });
    }
  };

  const handleInputValueChange = (field, value) => {
    setNewItem((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEditAssociatedPanels(row);
        break;
      default:
        break;
    }
  };

  const handleEditAssociatedPanels = (accPanel) => {
    setTestPanelData({ isNew: false, ...accPanel });
    openDetailModal();
  };

  const handelEffectiveDate = (e) => {
    const effectiveDate = moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "";
    setNewItem({ ...newItem, effectiveDate });
  };

  const handleDiagCode = (e) => {
    setIctCodes([e.ictCodes[0]]);
  };
  const handleCptCode = (e) => {
    const cptObj = e.cptCodes[0];
    setCptList([...cptList, cptObj]);
  };

  const removeCptCode = (index) => {
    setCptList((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"2xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test and Assay Management
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div style={{ display: "flex", gap: "16px" }}>
          {/* First Column */}
          <div style={{ flex: 1 }}>
            <p className="modalLineHeaders">Test Name</p>
            <Select
              className="w-100 siteSelector"
              options={REQ_FORM_TESTS_NAME}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              value={(() => {
                const foundItem = REQ_FORM_TESTS_NAME.find((item) => item.label === newItem.name);
                return foundItem ? foundItem : newItem.name ? { label: newItem.name, value: newItem.code } : null;
              })()}
              placeholder="Test Name"
              onChange={handelAddTestName}
              tabIndex={1}
              onInputChange={handleInputChange}
            />

            <TestSelector
              value={newItem.sources}
              tabIndex={3}
              onChange={(e) => setNewItem({ ...newItem, sources: e })}
              settingType={TEST_SETTING_TYPES.SOURCE}
              label={"Source"}
              title={"Create Source"}
              placeholder="Enter Source Name"
              updateMainTest={(value) => setNewItem({ ...newItem, sources: { label: value, value: value } })}
            />

            <TestSelector
              value={newItem.units ? { label: newItem.units, value: newItem.units } : null}
              tabIndex={6}
              onChange={(e) => setNewItem({ ...newItem, units: e.label })}
              settingType={TEST_SETTING_TYPES.UNIT}
              label={"Units"}
              title={"Create Unit"}
              placeholder="Enter Unit Name"
              updateMainTest={(value) => setNewItem({ ...newItem, units: value })}
            />

            <p className="modalLineHeaders">Result Methodology</p>
            <Select
              className="w-100 siteSelector"
              options={TEST_TYPE_OPTIONS}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              value={newItem.typeOfTest ? TEST_TYPE_OPTIONS.find((f) => f.label === newItem.typeOfTest) : null}
              placeholder="Result Methodology"
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  typeOfTest: e.label,
                })
              }
              tabIndex={5}
            />

            {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && (
              <TestInputField
                label="High Value"
                value={newItem.maxValue}
                type="number"
                onChange={(e) => setNewItem({ ...newItem, maxValue: Number(e.target.value) })}
              />
            )}

            <InputField
              type="datePicker"
              inputStyle="w-100 orderTestDob"
              labelStyle="modalLineHeaders mt-0 text-capitalize"
              label="Effective Date"
              index="ed"
              placeholder="Effective Date"
              disabled={false}
              groupWrapper={`simple-form-group-wrapper mt-1`}
              handleChange={handelEffectiveDate}
              value={newItem?.effectiveDate || ""}
            />

            <TestInputField
              label="Requirement"
              value={newItem?.setting?.requirements || ""}
              type="text"
              onChange={(e) =>
                setNewItem({ ...newItem, setting: { ...newItem.setting, requirements: e.target.value } })
              }
            />

            {/* 
            {newItem?.typeOfTest && newItem?.typeOfTest === "Qualitative" && (
              <>
                <p className="modalLineHeaders">Possible Values</p>
                <Select
                  className="w-100 siteSelector"
                  options={RESULT_SETTINGS_OPT}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  isMulti
                  value={newItem.possibleValues}
                  placeholder="Select Possible Result"
                  onChange={handleSelectPossibleResults}
                />
              </>
            )} */}
          </div>

          {/* Second Column */}
          <div style={{ flex: 1 }}>
            <p className="modalLineHeaders">Internal Test Code</p>
            <input
              className="modalInput"
              value={newItem.code}
              placeholder="Internal Code"
              style={{ width: "100%" }}
              onChange={(e) => setNewItem({ ...newItem, code: e.target.value })}
              tabIndex={2}
            />

            <TestSelector
              value={newItem.category ? { label: newItem.category, value: newItem.category } : null}
              tabIndex={4}
              onChange={(e) => setNewItem({ ...newItem, category: e.label })}
              settingType={TEST_SETTING_TYPES.DEPARTMENT}
              label={"Department"}
              title={"Create Department"}
              placeholder="Enter Department Name"
              updateMainTest={(value) => setNewItem({ ...newItem, category: value })}
            />

            <LabelIdSelector
              value={newItem?.setting?.labelID || ""}
              tabIndex={3}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, labelID: e } })}
              updateMainTest={(value) => setNewItem({ ...newItem, setting: { ...newItem.setting, labelID: value } })}
            />

            <TestInputField
              label="LOINC Code"
              value={newItem?.lonicCode || ""}
              type="number"
              onChange={(e) => setNewItem({ ...newItem, lonicCode: e.target.value })}
            />

            {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && (
              <TestInputField
                label="Low Value"
                value={newItem.minValue}
                type="number"
                onChange={(e) => setNewItem({ ...newItem, minValue: Number(e.target.value) })}
              />
            )}

            {/* <TestTextAreaField
              label="Reagent"
              value={newItem?.setting?.reagent || ""}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, reagent: e.target.value } })}
            
            /> */}

            <TestInputField
              label="Reagent"
              value={newItem?.setting?.reagent || ""}
              onChange={(e) => setNewItem({ ...newItem, setting: { ...newItem.setting, reagent: e.target.value } })}
            />

            {/* {newItem?.typeOfTest && newItem?.typeOfTest === "Qualitative" && (
              <>
                <p className="modalLineHeaders">Reference Interval</p>
                <Select
                  className="w-100 siteSelector"
                  options={RESULT_SETTINGS_OPT}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={newItem.refInterval ? RESULT_SETTINGS_OPT.find((f) => f.label === newItem.refInterval) : null}
                  placeholder="Select Possible Result"
                  onChange={(e) => setNewItem({ ...newItem, refInterval: e })}
                />
              </>
            )} */}

            {showEditor && (
              <TextEditor
                text={showEditor.message ?? ""}
                handleSave={handleDisclaimerSave}
                handleClose={() => setShowEditor(null)}
                type={showEditor.type}
              />
            )}

            {/* {showDiagModal && (
              <DiagnosisAccordionView
                handleClose={() => setShowDiagModal(false)}
                handelSaveDiagnosisPanel={saveDiagnosisCodes}
                ictList={ictCodes}
                setIctList={setIctCodes}
                // secondModalClass="secondModal"
              />
            )} */}

            {/* {showProcedureCodeModal && (
              <ProcedureCodeView
                handleClose={() => setShowProcedureCodeModal(false)}
                cptCodes={cptList}
                saveCptCodes={(data) => {
                  setCptList(data);
                  setShowProcedureCodeModal(false);
                }}
              />
            )} */}
          </div>

          {/* Third Column */}
          <div style={{ flex: 1 }}>
            <CptList
              item={ictCodes.length > 0 ? ictCodes[0] : {}}
              setItem={handleDiagCode}
              title="ICD / Diagnosis Code"
              searchtype={CPT_CODE_SEARCH_TYPE.ICT}
              groupWrapper="mt-1 form-group-wrapper margin-bottom-1"
              showLabelOnTop={true}
              showInput={true}
              showLabel={true}
              showCross={false}
            />
            <div style={{ border: "1px solid gray", marginTop: "5px", borderRadius: "5px" }}>
              <h4
                style={{
                  margin: "0px",
                  background: "lightGray",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  padding: "10px",
                }}
              >
                Linked Procedure Code
              </h4>
              <div style={{ padding: "10px" }}>
                <CptList
                  item={{}}
                  setItem={handleCptCode}
                  title="Lookup Procedure Code"
                  searchtype={CPT_CODE_SEARCH_TYPE.CPT}
                  groupWrapper="form-group-wrapper margin-bottom-1"
                  showLabelOnTop={true}
                  showInput={false}
                />
                {cptList && cptList.length > 0 && (
                  <ProcedureCodeDetailView cptCodeList={cptList} handelDeleteCpt={removeCptCode} />
                )}
              </div>
            </div>

            {/* <div className="createShowWrapper h4 ">
              <div> Diagnosis and CPT:</div>
              <div onClick={() => setShowDiagModal(true)} className="text-primary cursor-pointer">
                Manage
              </div>
            </div> */}

            {/* <div className="createShowWrapper h4 ">
              <div>Procedure Code:</div>
              <div onClick={() => setShowProcedureCodeModal(true)} className="text-primary cursor-pointer">
                Manage
              </div>
            </div> */}

            <div className="createShowWrapper h4 ">
              <div>Disclaimer:</div>
              <div
                onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.CUSTOM, message: newItem.custom_notes })}
                className="text-primary cursor-pointer"
              >
                {newItem.isNew ? "Edit Text" : "Update Text"}
              </div>
            </div>
          </div>
        </div>

        {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && (
          <TestDetailsSelector
            label="Select Associated Panels"
            openDetailModal={() => openDetailModal()}
            onChange={handleTestSelect}
            mainTests={mainTests}
            selectedTest={newItem}
          />
        )}

        {newItem?.typeOfTest && newItem?.typeOfTest === "Quantitative" && filteredTestList.length > 0 && (
          <>
            <p className="mt-2 h4">Associated Panels</p>
            <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
              <MainTable
                columns={CREATE_TEST_PERSONALIZE}
                rows={filteredTestList}
                customColumnCellRenderer={customRenderTD}
                // trDataWrapperHeader={"trDataWrapperHeader"}
                tools={quickTools}
                widthToSkip={155}
                handleCellClick={handleEditCellClick}
              />

              {/* <MainTable
                    columns={DICTIONARY_PERSONALIZATION}
                    rows={procedureList}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                  /> */}
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          {item.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestTypeModal;
