import { TEST_KEYS_PARSE } from "constant";
import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { Card, Table, Row, Col, Button } from "react-bootstrap";

const PopoverSticks= (props) => {
  const { item, columns, values, cssClass, changeObject } = props;



  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  let setTimeoutConst = null;
  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 1000);
  };
  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const TableRow = ({ rowItem, index }) => {
    return (
      <tr key={index}>
        <td className="popOver-centered border-0">{rowItem.name}</td>
        <td className="popOver-centered border-0">{rowItem.oldResult}</td>
        <td className="popOver-centered border-0">{rowItem.newResult}</td>
      </tr>
    );
  };

  return (
    <>
      <td
        className={cssClass}
        ref={childNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowPopover(true)}
        style={{
          cursor: "pointer",
            display:"flex",
            width:"100%", 
            justifyContent:"center"
        }}
      >
       View Details
      </td>
      {Array.isArray(values) &&  values.length > 0 && showPopover && (
        <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
          <Popover
            onMouseEnter={() => {
              setShowPopover(true);
            }}
            onMouseLeave={handleMouseLeave}
            id="popover"
            className="popOverSchedule"
          >
            <Popover.Header className="fw-bold">Details</Popover.Header>
            <Card className="border-0 mb-1">
              <Card.Body className="p-0">
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="popOver-centered border-0">Label</th>
                          <th className="popOver-centered border-0">Old Value</th>
                          <th className="popOver-centered border-0">New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(values) && values.map((val, i) => {
                          return <TableRow key={i} index={i} rowItem={val} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Popover>
        </Overlay>
      )}
    </>
  );
};

export default PopoverSticks;
