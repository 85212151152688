import React, { useState, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { INSURANCE_PROVIDER, MEDICATRE_IDS } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { convertToUpper } from "utils";
import { CUSTOM_INS } from "constant";
import { isValidIDNumber } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import InputField from "components/InputField/InputField";

const InsuranceDetailsModal = (props) => {
  const { handleClose, hanndleAddIns, handleUpdateIns, title, type, insuranceDetails, insuranceList } = props;
  const [insuranceData, setInsuranceData] = useState(insuranceDetails);
  const [showError, setShowError] = useState("");

  const InsuranceObject = useMemo(() => {
    if (insuranceData.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceData.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [insuranceData]);

  const alreadyExistIns = (ins, isUpdate) => {
    const existIns = insuranceList?.filter((f) => {
      if (isUpdate)
        return (
          f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo && f.id !== insuranceDetails.id
        );
      return f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo;
    });
    if (existIns && existIns.length > 0) {
      return true;
    }
    return false;
  };
  const handleInsValidation = (insuranceData) => {
    if (!insuranceData.insuranceCompany) {
      setShowError("Insurance is required");
      return;
    }

    if (!CUSTOM_INS.includes(insuranceData.insuranceCompany)) {
      if (!insuranceData.medicalNo || !insuranceData.medicalNo.trim()) {
        setShowError(
          `${insuranceData.insuranceCompany !== "01192" ? "Insurance Number" : "Medicare Number"}  is required`
        );
        return;
      }

      if (!isValidIDNumber(insuranceData.medicalNo)) {
        setShowError(
          `${insuranceData.insuranceCompany !== "01192" ? "Insurance Number" : "Medicare Number"}  is invalid`
        );
        return;
      }

      if (insuranceData.isMedicare === "Y" && !insuranceData.medicareNo) {
        setShowError("Medicare Number Not be Empty");
        return;
      }
      if (insuranceData.isMedicare === "Y" && !isValidIDNumber(insuranceData.medicareNo)) {
        setShowError("Medicare Number is invalid");
        return;
      }
      if (alreadyExistIns(insuranceData, insuranceDetails.id ? true : false)) {
        setShowError("Already add Same Insurance!");
        return;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!handleInsValidation(insuranceData)) return;
    const insData = { ...insuranceData };

    if (insuranceData.isNew) {
      Object.assign(insData, { id: "prefix_" + Math.random().toString(36).slice(2, 9) });
      handleUpdateIns(insData);
    } else {
      handleUpdateIns(insData);
    }
    handleClose();
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="testAdmin createClientsWrapper d-block">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 mt-4"
              inputStyle="w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Insurance"
              options={INSURANCE_PROVIDER}
              value={InsuranceObject}
              index="insuranceCompany"
              placeholder="Select Insurance"
              handleChange={(e) => {
                setInsuranceData({
                  ...insuranceData,
                  insuranceCompany: e.value,
                  insuranceCompanyCode: e.label,
                  insuranceType: e.payer_type,
                  insuranceGroupId: "",
                  isMedicare: "N",
                });
              }}
              required
            />

            {insuranceData.insuranceCompany && MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) === -1 && (
              <>
                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Insurance Number"
                  value={insuranceData.medicalNo}
                  index="medicalNo"
                  maxLength={50}
                  placeholder="Enter Insurance Number"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicalNo: convertToUpper(e.target.value),
                    })
                  }
                />

                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Group ID"
                  value={insuranceData.insuranceGroupId}
                  index="insuranceGroupId"
                  maxLength={50}
                  placeholder="Enter Group ID"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      insuranceGroupId: convertToUpper(e.target.value),
                    })
                  }
                />

                <Form.Group className="form-group-wrapper">
                  <label className="modalLieHeaders emp-gender-mb text-capitalize">
                    Is the Insurance Information Entered an Advantage Plan?
                  </label>
                  <div className="d-flex justify-content-between w-100 flex-wrap">
                    <RadioButton
                      name="secondaryMedicare"
                      id="syes"
                      label="Yes"
                      checked={insuranceData.isMedicare === "Y" ? true : false}
                      handleRadioButtonChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          isMedicare: e.target.checked ? "Y" : "",
                        })
                      }
                    />
                    <RadioButton
                      name="secondaryMedicare"
                      id="sno"
                      checked={insuranceData?.isMedicare === "N" ? true : false}
                      label="No"
                      handleRadioButtonChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          isMedicare: e.target.checked ? "N" : "",
                          medicareNo: e.target.checked ? "" : insuranceData.medicareNo,
                        })
                      }
                    />
                  </div>
                </Form.Group>
              </>
            )}

            {insuranceData.isMedicare === "Y" && (
              <InputField
                type="text"
                groupWrapper="form-group-wrapper me-2"
                inputStyle="modalInput"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="Medicare Number"
                value={insuranceData.medicareNo}
                index="medicareNo"
                maxLength={50}
                placeholder="Enter Medicare Number"
                handleChange={(e) =>
                  setInsuranceData({
                    ...insuranceData,
                    medicareNo: convertToUpper(e.target.value),
                  })
                }
              />
            )}

            {insuranceData.insuranceCompany && MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) !== -1 && (
              <InputField
                type="text"
                groupWrapper="form-group-wrapper me-2"
                inputStyle="modalInput"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="Medicare Number"
                value={insuranceData.medicareNo}
                index="medicareNo"
                maxLength={50}
                placeholder="Enter Medicare Number"
                handleChange={(e) =>
                  setInsuranceData({
                    ...insuranceData,
                    medicalNo: convertToUpper(e.target.value),
                    medicareNo: convertToUpper(e.target.value),
                  })
                }
              />
            )}

            {insuranceData.insuranceCompany && (
              <>
                <InputField
                  type="number"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Copay"
                  value={insuranceData.copay}
                  index="copay"
                  maxLength={50}
                  placeholder="Enter Copay"
                  handleChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove leading zeros if present
                    const cleanedValue = inputValue.replace(/^0+/, "");
                    const val = parseFloat(cleanedValue);

                    setInsuranceData({
                      ...insuranceData,
                      copay: val,
                    });
                  }}
                />

                <InputField
                  type="number"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Deductible"
                  value={insuranceData.deductible}
                  index="deductible"
                  maxLength={50}
                  placeholder="Enter Deductible"
                  handleChange={(e) => {
                    const inputValue = e.target.value;

                    // Remove leading zeros if present
                    const cleanedValue = inputValue.replace(/^0+/, "");
                    const val = parseFloat(cleanedValue);
                    setInsuranceData({
                      ...insuranceData,
                      deductible: val,
                    });
                  }}
                />
              </>
            )}
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {showError && <ErrorMessage error={showError} handleChange={() => setShowError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleSave();
            }}
          >
            {insuranceDetails.isNew ? "Add" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InsuranceDetailsModal;
