import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { TEST_SOURCE_OPTIONS } from "constant";

import { parseJSONObject } from "utils";
import Icon from "components/Icon";

const OrderTestItem = (props) => {
  const { item, index, onItemChange, onItemRemove, showSelection, testDone, handleEditTest, testDoneList } = props;
  
  const [newItem, setNewItem] = useState(item);

  useEffect(() => {
    setNewItem(item);
  }, [item]);

  const handleChangeSource = (e) => {
    const updatedItem = { ...newItem, selectedSource: e.value };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const handleInputComment = (e) => {
    const updatedItem = { ...newItem, clinicalcomment: e.target.value };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const handleItemSelection = (e) => {
    const updatedItem = { ...newItem, isSelected: !newItem.isSelected };
    setNewItem(updatedItem);
    if (onItemChange) onItemChange(index, updatedItem);
  };

  const isTestDone = useMemo(() => {
    if (item?.isDone) return item;
    const testInTestDoneList = testDoneList?.find(
      (t) => parseJSONObject(t.employee_demographics)?.testTypeID === item.id
    );
    if (testInTestDoneList) {
      return { isDone: true, barcode: testInTestDoneList.barcode };
    }
    return { isDone: false, barcode: null };
  }, [item, testDoneList]);

  return (
    <tr key={index}>
      {showSelection && (
        <td>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              checked={item.isSelected}
              value={item.value}
              disabled={isTestDone.isDone}
              onChange={(event) => handleItemSelection(event, index)}
            />
          </Form.Check>
        </td>
      )}
      <td className="text-center cursor-pointer">{newItem.label || newItem.name}</td>
      {testDone ? (
        <td>
          <input
            className="modalInput"
            style={{
              width: "100%",
              flexShrink: "initial",
            }}
            value={newItem?.barcode}
            disabled={true}
          />
        </td>
      ) : (
        <>
          <td className="">
            {
              <Select
                options={newItem.sources || TEST_SOURCE_OPTIONS}
                blurInputOnSelect={true}
                menuPlacement="auto"
                className="w-100 siteSelector site-select-test"
                placeholder="Select Source"
                value={newItem.selectedSource ? { label: newItem.selectedSource, label: newItem.selectedSource } : null}
                onChange={handleChangeSource}
              />
            }
          </td>
          <td className="text-center">
            <span
              className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
              onClick={() => handleEditTest(item, index)}
            >
              View / Edit
            </span>
          </td>

          <td>
            <span>{isTestDone.barcode} </span>
          </td>

          <td className="text-center">
            <input
              className="modalInput"
              style={{
                width: "100%",
                flexShrink: "initial",
              }}
              value={newItem?.clinicalcomment}
              onChange={handleInputComment}
            />
          </td>
        </>
      )}
      <td className="text-center cursor-pointer">
        {!showSelection && (
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            handleClick={() => {
              if (onItemRemove) onItemRemove(index);
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default OrderTestItem;
