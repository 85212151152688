import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { TEST_DICTIONARY, GENERAL_SETTINGS_FETCH } from "./testDictionaryConstant";

// Async thunk to fetch all clients
export const fetchGeneralSettings = createAsyncThunk(GENERAL_SETTINGS_FETCH, async () => {
  const response = await api.getAllGeneralSetting();
  return response;
});

const generalSettingsSlice = createSlice({
  name: TEST_DICTIONARY,
  initialState: {
    settings: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneralSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
  },
});

export default generalSettingsSlice.reducer;
