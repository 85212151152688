import Filter from "components/Filter";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ProcedureImportModal from "components/Modal/ProcedureImportModal";
import MainTable from "components/Table/MainTable";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TEST_DICTIONARY_PERSONALIZE } from "constants/personalization";

import InputField from "components/InputField/InputField";
import useOptions from "../TestTypeSetting/useOptions";
import CreateOptions from "../TestTypeSetting/TestUnitSelector/CreateOptions";
import CreateLabelOption from "../TestTypeSetting/LabelIDSelector/CreateLabelOption";
import { TEST_SETTING_TYPES, DICTIONARY_COLUMNS, DICTIONARY_OPTIONS, SOURCE_DICTIONARY_COLUMNS } from "constant";
import { fetchGeneralSettings } from "store/features/testDictionary/testDictionarySlice";
import { generalSettings } from "store/features/testDictionary/testDictionarySelectors";
import "../../../src/tableStyling.css";
import { TABLE_QUICK_TOOLS } from "../../constant";
import { t } from "../../stringConstants";

const TestDictionary = () => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [openImportModal, setOpenImportModal] = useState(false);
  const dispatch = useDispatch();
  const generalSettingsData = useSelector(generalSettings);
  const quickTools = [TABLE_QUICK_TOOLS.edit];
  const [createTestDictionary, setCreateTestDictionary] = useState(false);
  const [selectedTDItems, setSelectedTDItems] = useState();
  const [settingType, setSettingType] = useState(TEST_SETTING_TYPES.SOURCE);
  const [filterValues, setFilterValues] = useState([]);
  const { addOption, updateOption } = useOptions(settingType.value);
  const [searchTerms, setSearchTerms] = useState(["Code", "Title"]);
  const [filterColumns, setFilterColumns] = useState(DICTIONARY_COLUMNS);

  const fetchSettings = () => {
    dispatch(fetchGeneralSettings());
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    setSettingType(DICTIONARY_OPTIONS[0]);
  }, []);

  const handleCellClick = (key, row) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        setSelectedTDItems({ item: row });
        setCreateTestDictionary(true);
        break;

      default:
        break;
    }
  };
  const handelAddOption = (value) => {
    const id = selectedTDItems?.item?.id;
    const action = id ? updateOption : addOption;
    action(value, id);
    setCreateTestDictionary(false);
  };

  useEffect(() => {
    setFilterValues(generalSettingsData?.filter((item) => item?.settingType === settingType?.value));
  }, [settingType, generalSettingsData]);

  const searchRecords = (records, searchParams) => {
    return records.filter((record) => {
      return (
        record?.settingType === settingType?.value &&
        Object.keys(searchParams).every((key) => {
          if (!searchParams[key]) {
            return true; // If the search param is an empty string, include all records
          } else {
            return record[key] && record[key].toString().toLowerCase().includes(searchParams[key].toLowerCase());
          }
        })
      );
    });
  };

  useEffect(() => {
    const filterRecords = searchRecords(generalSettingsData, filter);
    setFilterValues(filterRecords);
  }, [filter]);

  useEffect(() => {
    const sourceDictionaryValues = [
      DICTIONARY_OPTIONS[0].value,
      DICTIONARY_OPTIONS[2].value,
      DICTIONARY_OPTIONS[1].value,
    ];
    if (sourceDictionaryValues.includes(settingType.value)) {
      setSearchTerms(["Title"]);
      setFilterColumns(SOURCE_DICTIONARY_COLUMNS);
    } else {
      setFilterColumns(DICTIONARY_COLUMNS);
      setSearchTerms(["Code", "Title"]);
    }
  }, [settingType]);

  const renderCreateOptions = () => {
    if (!createTestDictionary) return null;

    if (settingType.label === DICTIONARY_OPTIONS[3].label) {
      return (
        <CreateLabelOption
          handleClose={() => setCreateTestDictionary(false)}
          handleSaveChange={handelAddOption}
          selectedValue={{ label: selectedTDItems?.item?.label, code: selectedTDItems?.item?.value }}
        />
      );
    }

    return (
      <CreateOptions
        handleClose={() => setCreateTestDictionary(false)}
        handleSaveChange={handelAddOption}
        title={settingType.label}
        selectedValue={selectedTDItems?.item?.label}
        label={settingType.label}
      />
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("testDictionary")}
                </Card.Title>
                <div className="buttonHeader d-flex justify-content-between flex-direction-row flex-md-direction-column">
                  <div className="d-flex">
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                    <Icon
                      handleClick={() => {
                        setSelectedTDItems();
                        setCreateTestDictionary(true);
                      }}
                      title={"Create"}
                      label={"Create"}
                      iconType={"addUserIcon"}
                    />
                  </div>
                  <div className="dictionary_search">
                    <InputField
                      type="dropDown"
                      groupWrapper="w-100"
                      options={DICTIONARY_OPTIONS}
                      labelStyle="text-capitalize"
                      label="Select Setting Type:"
                      value={settingType}
                      index="Name"
                      isClearable={true}
                      placeholder="Select Setting Type:"
                      handleChange={(e) => {
                        if (e) {
                          setSettingType(e);
                        } else {
                          setSettingType("");
                        }
                      }}
                    />
                  </div>
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    personalisationData={TEST_DICTIONARY_PERSONALIZE}
                  />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <MainTable
                    columns={filterColumns}
                    rows={filterValues || []}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    // customColumnCellRenderer={renderTd}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          {renderCreateOptions()}
          {openImportModal && (
            <ProcedureImportModal handleClose={() => setOpenImportModal(false)} title={t("importProcedureDataTitle")} />
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TestDictionary;
