import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { getFloatVal } from "utils";
import { callAmplifyAPI } from "views/GraphQL/gqApi";
import { callAmplifyAPIPut } from "views/GraphQL/gqApi";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  CPT_CODE,
  CPT_CODE_FETCH,
  CPT_CODE_CREATE,
  CPT_CODE_UPDATE,
  CPT_CODE_DELETE,
  ICT_CODE_FETCH,
  ICT_CODE_CREATE,
  ICT_CODE_DELETE,
  ICT_CODE_UPDATE,
} from "./cptCodeConstant";

export const fetchCPTCodeAsync = createAsyncThunk(CPT_CODE_FETCH, async (param, { dispatch }) => {
  const response = await api.searchCptCode(param); // Your API call logic here
  return response;
});

export const fetchICTCodeAsync = createAsyncThunk(ICT_CODE_FETCH, async (param, { dispatch }) => {
  const response = await api.searchICTCode(param); // Your API call logic here
  return response;
});

export const updateCPTCodeAsync = createAsyncThunk(CPT_CODE_UPDATE, async (param, { dispatch }) => {
  dispatch(setLoader(true));
  const response = await callAmplifyAPIPut("/cpt", {
    id: param.id,
    code: param.code,
    title: param.description,
    charge: param.charges,
    isActive: param.isActive,
    internalCode: param.internalCode,
    referenceCharge: getFloatVal(param.referenceCharge),
  });
  dispatch(setLoader(false));

  if (response && response.status) {
    dispatch(setMessage("CPT Code Update successfully", MESSAGE_MODES.success));
    return param;
  } else {
    dispatch(setMessage(`${response?.error || "CPT Code not Update"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const updateICTCodeAsync = createAsyncThunk(ICT_CODE_UPDATE, async (param, { dispatch }) => {
  const response = await callAmplifyAPIPut("/ict", {
    id: param.id,
    code: param.code,
    title: param.title,
    isActive: param.isActive,
    internalCode: param.internalCode,
  });

  if (response && response.status) {
    dispatch(setMessage("ICD Code Update successfully", MESSAGE_MODES.success));
    return param;
  } else {
    dispatch(setMessage(`${response?.error || "ICD Code not Update"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const createCPTCodeAsync = createAsyncThunk(CPT_CODE_CREATE, async (param, { dispatch }) => {
  dispatch(setLoader(true));
  const response = await callAmplifyAPI("/cpt", {
    code: param.code,
    title: param.description,
    charge: param.charges,
    isActive: param.isActive,
    internalCode: param.internalCode,
    referenceCharge: getFloatVal(param.referenceCharge),
  }); // Your API call logic here
  dispatch(setLoader(false));

  if (response && response.rows && response.rows.length > 0) {
    dispatch(setMessage("CPT Code Add Successfully", MESSAGE_MODES.success));
    return response.rows[0];
  } else {
    dispatch(setMessage(`${response?.error || "CPT Code not Add"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const createICTCodeAsync = createAsyncThunk(ICT_CODE_CREATE, async (param, { dispatch }) => {
  const response = await callAmplifyAPI("/ict", {
    code: param.code,
    title: param.title,
    isActive: param.isActive,
    internalCode: param.internalCode,
  });

  if (response && response.rows && response.rows.length > 0) {
    dispatch(setMessage("ICD Code Add Successfully", MESSAGE_MODES.success));
    return response.rows[0];
  } else {
    dispatch(setMessage(`${response?.error || "ICD Code not Add"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const deleteCPTCodeAsync = createAsyncThunk(CPT_CODE_DELETE, async (defaultSetting) => {
  const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return response;
});

const cptCodeSlice = createSlice({
  name: CPT_CODE,
  initialState: {
    procedureList: [],
    openCreateCPTModal: false,
    selectedCPTItem: null,
    diagnosisList: [],
    openCreateICTModal: false,
    selectedICTItem: null,
  },
  reducers: {
    setProcedureList: (state, action) => {
      state.procedureList = action.payload;
    },
    setOpenCreateCPTModal: (state, action) => {
      state.openCreateCPTModal = action.payload;
    },
    setSelectedCPTItem: (state, action) => {
      state.selectedCPTItem = action.payload;
    },
    setDiagnosisList: (state, action) => {
      state.diagnosisList = action.payload;
    },
    setOpenCreateICTModal: (state, action) => {
      state.openCreateICTModal = action.payload;
    },
    setSelectedICTItem: (state, action) => {
      state.selectedICTItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCPTCodeAsync.fulfilled, (state, action) => {
        state.procedureList = action.payload;
      })
      .addCase(fetchICTCodeAsync.fulfilled, (state, action) => {
        state.diagnosisList = action.payload;
      })
      .addCase(createCPTCodeAsync.fulfilled, (state, action) => {
        if (action.payload) state.procedureList.unshift(action.payload);
      })
      .addCase(createICTCodeAsync.fulfilled, (state, action) => {
        if (action.payload) state.diagnosisList.unshift(action.payload);
      })
      .addCase(updateCPTCodeAsync.fulfilled, (state, action) => {
        const updatedCPT = action.payload;
        if (updatedCPT) {
          const existingclientIndex = state.procedureList.findIndex((code) => code.id === updatedCPT.id);
          if (existingclientIndex !== -1) {
            state.procedureList[existingclientIndex] = updatedCPT;
          }
        }
      })
      .addCase(updateICTCodeAsync.fulfilled, (state, action) => {
        const updatedICT = action.payload;
        if (updatedICT) {
          const existingclientIndex = state.diagnosisList.findIndex((code) => code.id === updatedICT.id);
          if (existingclientIndex !== -1) {
            state.diagnosisList[existingclientIndex] = updatedICT;
          }
        }
      })
      .addCase(deleteCPTCodeAsync.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.procedureList = state.procedureList.filter((code) => code.id !== deletedId);
      });
  },
});

export const {
  setProcedureList,
  setSelectedCPTItem,
  setOpenCreateCPTModal,
  setDiagnosisList,
  setOpenCreateICTModal,
  setSelectedICTItem,
} = cptCodeSlice.actions;

export default cptCodeSlice.reducer;
