import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import { useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import { INSURANCE_PERSONALIZE } from "../../constants/personalization/InsPersonalization";
import { insurnaceSelector } from "../../store/features/insurances/insuranceSelectors";
import { selectedCompanySetting } from "../../store/features/companySetting/companySettingSelectors";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { t } from "../../stringConstants";
import Filter from "components/Filter";
import PersonalizationModal from "components/Modal/personalizationModal";
import ExportToExcel from "components/ExportToExcel";
import {
  fetchInsurancesAsync,
  setInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
} from "../../store/features/insurances/insuranceSlice";
// import InsuranceModal from "../../components/Insurances/InsuranceModal;
import { debounce } from "debounce";
import InsuranceModal from "components/Insurances/InsuranceModal";
import { INSURANCE_EMPTY_OBJECT, PAGE_LIMIT, TABLE_QUICK_TOOLS } from "../../constant";
import MainTable from "../../components/Table/MainTable";
import { draggablePersonalizationLocalStorage, userCompanyID } from "../../utils";
import api from "../../api";
import { AppContext } from "context/app-context";

const Insurances = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  const { insurancesList, filteredInsurances } = useSelector(insurnaceSelector);
  const setting = useSelector(selectedCompanySetting);
  const appContext = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const quickTools = [TABLE_QUICK_TOOLS.edit];
  const loginUser = appContext.user;
  const searchTerms = [
    "Ins Name",
    "Ins Alias",
    "Electronic Claims",
    "Electronic Eligibility",
    "Electronic ERA",
    "Insurance Company",
  ];

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending) => {
      dispatch(fetchInsurancesAsync({ ...filter, orderBy: sortBy, orderByDirection: sortDescending }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(loginUser, "insurance", INSURANCE_PERSONALIZE);

    setPersonalize(response);
  }, [setting]);

  
  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setOpenInsuranceModal(true));
        dispatch(setSelectedInsurance({ ...row, isNew: false }));
        break;

      default:
        break;
    }
  };
  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "insurance");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.saveUserPersonalisation(loginUser.sub, personalisationData);
  };

  const currentRecords = (items) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return items.slice(indexOfFirstUser, indexOfLastUser);
  };

  let itemsToMap = currentRecords(filteredInsurances);

  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("payerManagement")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredInsurances.map((e) => {
                      return {
                        ...e,
                      };
                    })}
                    filter={filter}
                    title={t("payerManagement")}
                    sortBy={sortBy}
                  />

                  <Icon
                    handleClick={() => {
                      dispatch(setOpenInsuranceModal(true));
                      dispatch(setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() }));
                    }}
                    title={"Create Insurance"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />
                </div>
                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredInsurances.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={itemsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"insurance"}
                  tools={quickTools}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={130}
                  handleCellClick={handleCellClick}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {openPersonalizationModal && (
          <PersonalizationModal
            data={personalize}
            handleChange={handlePersonalization}
            show={openPersonalizationModal}
            handleClose={() => setOpenPersonalizationModal(false)}
          />
        )}
      </>
    </div>
  );
};

export default Insurances;
