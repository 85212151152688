import React, { useState, useEffect, useMemo } from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import {
  getDemoGraphics,
  formatDateOfBirthDOB,
  formatReportDate,
  formatDateMDY,
  formatPDFName,
  toTitleCase,
  isLabImageExist,
  analyzeResultValue,
  formatPhone,
} from "utils";
import { logoImage, GENDER_TEXT, TEST_TYPE_DISCLAIMER_KEY } from "constant";
import qrImage from "assets/img/patient_portal.png";
import ArialRegular from "assets/fonts/arial.ttf";
import ArialBold from "assets/fonts/arial_bold.ttf";
import api from "api";

import moment from "moment";

// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialRegular, fontWeight: "normal" },
    { src: ArialBold, fontWeight: "bold" },
  ],
});

const parseSrNoObjectRecviedDate = (test) => {
  try {
    const arr = JSON.parse(test.sr_no);
    let dateOfReceive = arr[0]?.date;
    return dateOfReceive;
  } catch (error) {
    return moment(test.createdAt).toISOString();
  }
};

const AltalabPdf = ({ test, testsLab, parseTestResultForPDF, employeeID, site }) => {
  const { resultDetails, test_type, employee_demographics } = test;

  const { testTypeID } = employee_demographics;

  const [disclaimerText, setDisclaimerText] = useState("");

  const [clientInfo, setClientInfo] = useState();

  let demos = getDemoGraphics(test);

  const isPCR = resultDetails && resultDetails.find((item) => item.label === "PCR");

  const fetchDisclaimer = async () => {
    try {
      const result = await api.getTestTypesByID(testTypeID);

      setDisclaimerText(result?.custom_notes || "");
    } catch (error) {
      console.error("Error fetching disclaimer:", error);
    }
  };

  useEffect(() => {
    fetchDisclaimer();
  }, [testTypeID]);

  useEffect(() => {
    if (test?.clientID) {
      getClientByID(test);
    }
  }, [test]);

  const getClientByID = async (test) => {
    let clientModal = await api.getClientById(test?.clientID);
    setClientInfo(clientModal);
  };

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  const getLabDisclaimer = useMemo(() => {
    if (testsLab) {
      const disclaimerKey = TEST_TYPE_DISCLAIMER_KEY[test.test_type.replace(/(:|\s+)/g, "_")] || "testing_notes";
      const notes = testsLab[disclaimerKey];
      if (notes) return notes;
    }
    return "N/A";
  }, [test, testsLab]);

  const testTypeLabel = () => {
    const { test_type } = test;
    if (test_type === "RapidFlu" || test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    if (test_type === "PCR") return "COVID-19 MOLECULAR RT-PCR";
    return test_type;
  };

  const baseTestTypeLabel = () => {
    const { test_type } = test;
    if (test_type === "RapidFlu" || test_type === "Rapid Flu") return "2022 - INFLUENZA A/B - Accula";
    if (test_type === "PCR") `2019-nCoV`;
    return "";
  };

  const testHeaderStyles = {
    margin: "0",
    backgroundColor: "#3500CD",
    fontWeight: "bold",
    color: "white",
    fontSize: "11px",
    textAlign: "start",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "2px",
  };

  const baseCellStyle = {
    fontSize: "10px",
    fontWeight: "normal",
    textAlign: "start",
  };

  const sarcCellStyle = {
    ...baseCellStyle,
    fontSize: "11px",
    paddingLeft: "10px",
  };

  const testCell1Style = {
    ...baseCellStyle,
    paddingLeft: "30px",
  };

  const testCell2Style = {
    ...baseCellStyle,
    paddingLeft: "9px",
  };
  const testCell2StyleBold = {
    ...baseCellStyle,
    paddingLeft: "9px",
    fontWeight: "bold",
    color: "red",
  };

  const testCell2PCRStyle = {
    ...baseCellStyle,
    paddingLeft: "9px",
    fontWeight: "bold",
    color: "red",
  };

  const testCell3Style = {
    ...baseCellStyle,
    paddingLeft: "20px",
  };

  const mainTestNameStyle = {
    fontSize: "11px",
    margin: "0px",
    backgroundColor: "#C8C8C8",
    fontWeight: "bold",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "2px",
    textTransform: "uppercase",
  };

  const testRowMainViewStyle = { flexDirection: "row", justifyContent: "space-between", marginTop: "3px" };

  const testRowInnerViewStyle = { width: "32.33%" };

  const testRowInnerViewStyleResult = {
    width: "32.33%",
    display: "flex",
    justifyContent: "space-start",
    flexDirection: "row",
  };

  const {
    color: pcrColor,
    fontWeight: pcrFontWeight,
    orginalResult: pcrOriginalResult,
  } = analyzeResultValue(null, isPCR?.testResult, isPCR?.refInterval);

  return (
    <Document title={`${demos.firstName} ${demos.lastName}`}>
      <Page size="A4" style={styles.page} scale={30.0}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image
            style={{ width: "250px", height: "60px", opacity: 1, marginRight: "9px" }}
            src={isLabImageExist(testsLab) ? require(`assets/img/alta.png`).default : logoImage}
          />
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "100%",
                border: "1px solid #000",
                height: "55px",
                marginTop: "9px",
                padding: "9px",
                fontSize: "11px",
                fontWeight: "600",
              }}
            >
              <Text>
                {testsLab?.street || ""}, {testsLab?.city_state_zip || ""}
              </Text>
              <View style={{ display: "flex", flexDirection: "column", lineHeight: "1.2" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "50%" }}>Phone: {formatPhone(testsLab?.phone_number) || ""}</Text>
                  <Text style={{ width: "50%" }}>Fax: {formatPhone(testsLab?.fax_number)}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ width: "50%" }}>CLIA #: {testsLab?.clia_number || ""}</Text>
                  <Text style={{ width: "50%" }}>Director: {testsLab?.lab_director || ""}</Text>
                </View>
              </View>
            </View>
            <View style={{ marginLeft: "10px" }}>
              <Image style={{ width: "70px", opacity: 1 }} src={qrImage} />
            </View>
          </View>
        </View>

        <View style={{ marginTop: "20px" }}>
          <Text
            style={{
              fontSize: "16px",
              marginTop: "5px",
              marginBottom: "5px",
              textTransform: "uppercase",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Laboratory Report
          </Text>
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <View style={{ border: "1px solid #000", width: "32.33%" }}>
              <Text
                style={{
                  margin: "0",
                  fontWeight: "bold",
                  backgroundColor: "#3500CD",
                  color: "white",
                  fontSize: "11px",
                  textAlign: "center",
                  paddingTop: "2px",
                }}
              >
                PATIENT
              </Text>
              <View style={{ padding: "7px", margin: "0" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>Name:</Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {formatPDFName(demos)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Address:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {demos.street}
                    {"\n"}
                    {demos.state}, {demos.zip}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>Phone:</Text>
                  <Text style={{ fontSize: "10px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {formatPhone(demos?.phoneNumber) || ""}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>DOB:</Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {formatDateOfBirthDOB(demos.dob)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Gender:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {GENDER_TEXT[demos?.sex || "X"]}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ border: "1px solid #000", width: "32.33%" }}>
              <Text
                style={{
                  margin: "0",
                  backgroundColor: "#3500CD",
                  color: "white",
                  fontSize: "11px",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingTop: "2px",
                }}
              >
                SPECIMEN
              </Text>
              <View style={{ padding: "7px", margin: "0" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>
                    Specimen Type:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>
                    {toTitleCase(demos?.swabtype || "")}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>
                    Collection Date:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>
                    {formatDateMDY(test.createdAt)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>
                    Received Date:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>
                    {formatReportDate(parseSrNoObjectRecviedDate(test))}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>
                    Reported Date:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>
                    {formatReportDate(test.resultDateTime)}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>&nbsp;</Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>&nbsp;</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "70px", minWidth: "70px" }}>
                    Accession #:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 70px)" }}>
                    {test.barcode}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ border: "1px solid #000", width: "32.33%" }}>
              <Text
                style={{
                  margin: "0",
                  backgroundColor: "#3500CD",
                  color: "white",
                  fontSize: "11px",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingTop: "2px",
                }}
              >
                CLIENT INFORMATION
              </Text>
              <View style={{ padding: "7px", margin: "0" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Client:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>{site?.name}</Text>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Client ID:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {site?.practiceID}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Provider:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>NANCY BARR</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>
                    Address:
                  </Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {site?.street}
                    {"\n"}
                    {site?.city} {site?.state}, {site?.zipcode}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "normal", fontSize: "9px", width: "45px", minWidth: "45px" }}>Phone:</Text>
                  <Text style={{ fontSize: "9px", fontWeight: "bold", width: "calc(100% - 45px)" }}>
                    {formatPhone(site?.phone_number) || ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {resultDetails && test_type !== "PCR" && (
          <View style={{ marginTop: "9px", width: "100%" }}>
            <Text style={mainTestNameStyle}>{test_type}</Text>
            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Test Name</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Result</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Reference Range</Text>
              </View>
            </View>

            {resultDetails &&
              resultDetails.length > 0 &&
              resultDetails.map((item, index) => {
                const { color, label, fontWeight, orginalResult } = analyzeResultValue(
                  { minValue: item.minValue, maxValue: item.maxValue },
                  item.testResult,
                  item.refInterval
                );

                return (
                  <View style={testRowMainViewStyle} key={`testDet_${index}`}>
                    <View style={testRowInnerViewStyle}>
                      <Text style={testCell1Style}>{item?.name || ""}</Text>
                    </View>
                    <View style={testRowInnerViewStyleResult}>
                      <View style={{ paddingLeft: "9px", flex: 1 }}>
                        <Text style={{ ...baseCellStyle, fontWeight, color }}>{orginalResult}</Text>
                      </View>
                      <View style={{ paddingRight: "9px", flex: 1 }}>
                        <Text style={{ ...baseCellStyle, fontWeight, color }}>{label}</Text>
                      </View>
                    </View>
                    <View style={testRowInnerViewStyle}>
                      <Text style={testCell3Style}>{item?.refInterval || ""}</Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}

        {/* {!resultDetails && (
          <View style={{ marginTop: "9px", width: "100%" }}>
            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Test Name</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Result</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Reference Range</Text>
              </View>
            </View>

            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={testCell1Style}>{test?.test_type}</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text
                  style={
                    isPCR
                      ? { ...testCell2PCRStyle, fontWeight: pcrFontWeight, color: pcrColor }
                      : test.result === "Positive"
                      ? testCell2StyleBold
                      : testCell2StyleBold
                  }
                >
                  {test.result.toUpperCase()}
                </Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testCell3Style}>NEGATIVE</Text>
              </View>
            </View>
          </View>
        )} */}

        {(test_type === "PCR" || isPCR) && (
          <View style={{ marginTop: "9px", width: "100%" }}>
            <Text style={mainTestNameStyle}> SARS-CoV-2 (COVID-19)</Text>
            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Test Name</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Result</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testHeaderStyles}>Reference Range</Text>
              </View>
            </View>

            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={sarcCellStyle}>SARS-CoV-2 (COVID-19) RT-PCR</Text>
              </View>
            </View>

            <View style={testRowMainViewStyle}>
              <View style={testRowInnerViewStyle}>
                <Text style={testCell1Style}>SARS-CoV-2 (COVID-19) PCR</Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text
                  style={
                    isPCR
                      ? { ...testCell2PCRStyle, fontWeight: pcrFontWeight, color: pcrColor }
                      : test.result === "Positive"
                      ? testCell2StyleBold
                      : testCell2Style
                  }
                >
                  {isPCR ? pcrOriginalResult : test.result.toUpperCase()}
                </Text>
              </View>
              <View style={testRowInnerViewStyle}>
                <Text style={testCell3Style}>NEGATIVE</Text>
              </View>
            </View>
          </View>
        )}

        <View style={{ maxWidth: "440px", marginLeft: "auto", marginRight: "auto", paddingTop: "9px" }}>
          <Text style={{ fontSize: "11px", lineHeight: "1.2" }}>{disclaimerText || getLabDisclaimer}</Text>

          <Text style={{ maxWidth: "320px", marginLeft: "auto", marginRight: "auto", paddingTop: "20px" }}>
            Fact Sheet for providers https://www.fda.gov/media/136599/download Fact Sheet for patients
            https://www.fda.gov/media/136600/download
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 12,
    fontFamily: "Arial", // Use the Arial font for the entire document
  },
  qrCode: {
    width: 70,
    marginTop: 5,
    marginLeft: 15,
  },
  bold: {
    fontWeight: "bold",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  infoText: {
    fontSize: 11,
  },
  infoTextRightBorder: {
    fontSize: 11,
    fontStyle: "bold",
  },
  italicText: {
    fontSize: 11,
    fontStyle: "italic",
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 9,
    bottom: 15,
    right: 22,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderLeftWidth: 1,
    borderLeftColor: "black",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-around",
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%",
  },
  hrTwo: {
    borderBottomColor: "black",
    borderBottomWidth: 2,
    width: "100%",
  },
  address: {
    // marginLeft: 50,
    marginVertical: 20,
  },
  borderBottom: {
    borderBottom: 1,
    borderColor: "black",
  },
  waterMark: {
    opacity: 0.05,
    fontSize: "70px",
    color: "black",
    background: "#ccc",
    position: "absolute",
    cursor: "default",
    top: "45%",
    left: "30%",
    transform: "Translate(-50%, -50%)",
  },
  container: {
    width: "60%",
    marginHorizontal: "auto",
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "black",
    display: "inline-block",
    width: "40%",
    flex: 1,
  },
  text: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 11,
    flex: 1,
  },
  textUppercase: {
    textTransform: "uppercase",
  },
});

export default AltalabPdf;
