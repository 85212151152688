import React from "react";

const CreateTestHeader = ({ showSelection, testDone }) => {
  return (
    <tr>
      <th className="text-center cursor-pointer">Select</th>
      <th className="text-center cursor-pointer">Test Name</th>
      <th className="">Source</th>
      <th>Accession No</th>
      <th className="text-center">Comments</th>
      <th className="text-center">Eligible</th>
      <th className="text-center">PA</th>
      <th className="text-center">Print Labels</th>
    </tr>
  );
};

export default CreateTestHeader;
