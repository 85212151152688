import Filter from "components/Filter";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ProcedureImportModal from "components/Modal/ProcedureImportModal";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import { PROCEDURE_CODE_PERSONALIZE } from "constants/personalization";
import { debounce } from "debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { setMessage } from "store/features/general/generalAction";
import { setLoader } from "store/features/general/generalSlice";
import "../../../src/tableStyling.css";
import AddProcedureCodeSettingsModal from "../../components/GeneralSettings/AddProcedureCodeSettingsModal";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS } from "../../constant";
import {
  cptCodeSelector,
  openCreateCPTModalSelector,
  selectedCPTItemSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import {
  fetchCPTCodeAsync,
  setOpenCreateCPTModal,
  setSelectedCPTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { t } from "../../stringConstants";
import { userCompanyID } from "../../utils";

const ProcedureCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const procedureList = useSelector(cptCodeSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [openImportModal, setOpenImportModal] = useState(false);
  const dispatch = useDispatch();
  const selectedCPTItem = useSelector(selectedCPTItemSelector);
  const openCreateCPTModal = useSelector(openCreateCPTModalSelector);
  const user = useSelector(selectLoginUser);
  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const searchTerms = ["Procedure Code", "Alias", "Procedure Code Desc", "Internal Charge","Active"];

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending) => {
      dispatch(
        fetchCPTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : { code: "a" }),
          orderBy: sortBy,
          orderByDirection: sortDescending,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleImport = async (procedureData) => {
    setOpenImportModal(false);
    try {
      if (procedureData.length === 0) return;
      dispatch(setLoader(true));
      const arr = [];
      const notNpiFound = [];
      const ttlLength = procedureData.length;
      const alphanumericRegex = /^[A-Za-z0-9]+$/;
      const uniqueProcedureCodes = new Set();
      const duplicateCounts = new Map();
      for (let index = 0; index < ttlLength; index++) {
        const { data } = procedureData[index];
        const procedure = { ...data };
        if (!procedure.proc_code || !procedure.proc_code.trim() || !alphanumericRegex.test(procedure.proc_code)) {
          notNpiFound.push(procedure);
          continue;
        }
        if (uniqueProcedureCodes.has(procedure.proc_code)) {
          const count = duplicateCounts.get(procedure.proc_code) || 0;
          duplicateCounts.set(procedure.proc_code, count + 1);
          continue;
        }
        uniqueProcedureCodes.add(procedure.proc_code);
        if (!procedure.alias || !procedure.alias.trim() || !alphanumericRegex.test(procedure.procedure_code)) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.proc_desc || !procedure.proc_desc.trim()) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.charge || !procedure.charge.trim()) {
          notNpiFound.push(procedure);
          continue;
        }
        if (!procedure.active || !procedure.active.trim()) {
          notNpiFound.push(procedure);
          continue;
        }

        arr.push({
          ...procedure,
          clientID: userCompanyID.get(),
          requestedBy: user.sub,
          requestedByName: user.name,
        });
      }

      const fileName = `procedure_code/procedure_import${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      console.log("import Array", arr);
      // await api.saveFileOnBucket(arr, fileName);
      dispatch(setLoader(false));
      const duplicateMessage = Array.from(duplicateCounts)
        .map(([code, count]) => {
          return `${count} duplicate(s) of procedure code "${code}" found.`;
        })
        .join(" ");

      if (notNpiFound.length > 0 || duplicateMessage) {
        const message = `${notNpiFound.length} Procedure(s) is Invalid Not Found or Already Exist. ${duplicateMessage}`;
        dispatch(setMessage(message, MESSAGE_MODES.info));
      }
    } catch (err) {
      console.log(err);
      dispatch(setMessage(err, MESSAGE_MODES.error));
      dispatch(setLoader(false));
    }
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedCPTItem({ item: row }));
        dispatch(setOpenCreateCPTModal(true));
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("procedureCodes")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => dispatch(setOpenCreateCPTModal(true))}
                    title={"Create Procedure"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />
                </div>
                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} personalisationData={PROCEDURE_CODE_PERSONALIZE} />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <MainTable
                    columns={PROCEDURE_CODE_PERSONALIZE}
                    rows={procedureList}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={155}
                    tools={quickTools}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          {openCreateCPTModal && (
            <AddProcedureCodeSettingsModal
              handleClose={() => {
                dispatch(setOpenCreateCPTModal(false));
                dispatch(setSelectedCPTItem(null));
              }}
              selectedItem={selectedCPTItem?.item || null}
            />
          )}
          {openImportModal && (
            <ProcedureImportModal
              handleClose={() => setOpenImportModal(false)}
              handleImport={handleImport}
              title={t("importProcedureDataTitle")}
            />
          )}
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ProcedureCodeSettings;
