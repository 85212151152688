import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESULTS_TEST, SET_SELECTED_TEST } from "./resultsTestConstant";
import api from "api";

export const setSelectedTestAsync = createAsyncThunk(SET_SELECTED_TEST, async (tests) => {
  let simpleTestList = [];
  let detailTestList = [];

  const processArray = (array) => {
    return array.reduce((acc, obj) => {
      if (obj.testType === "mainTest") {
        return acc.concat(obj.targetRanges);
      } else {
        return acc.concat(obj);
      }
    }, []);
  };

  for (const test of tests) {
    if (!test?.employee_demographics?.testTypeID) {
      simpleTestList.push(test);
      continue;
    }

    const response = await api.getTestTypesByID(test.employee_demographics.testTypeID);

    if (!response) continue;

    if (response.typeOfTest === "Quantitative") {
      detailTestList.push({
        detailTest: test,
        testResultTypeData: processArray(response.targetRanges),
      });
    } else {
      simpleTestList.push(test);
    }
  }

  const showDetailModal = !simpleTestList.length && detailTestList.length === 1;
  const showSimpleModal = simpleTestList.length > 0;

  return { simpleTestList, detailTestList, showDetailModal, showSimpleModal };
});

const initialState = {
  simpleTestList: null,
  detailTestList: null,
  showDetailModal: false,
  showSimpleModal: false,
  listRemovedTest: [],
};

const resultsTestSlice = createSlice({
  name: RESULTS_TEST,
  initialState,
  reducers: {
    resetStateResultState: () => initialState,
    setTestIdsToRemove: (state, action) => {
      state.listRemovedTest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedTestAsync.fulfilled, (state, action) => {
      Object.assign(state, initialState);

      const { simpleTestList, detailTestList, showDetailModal, showSimpleModal } = action.payload;

      state.simpleTestList = simpleTestList;
      state.detailTestList = detailTestList;
      state.showDetailModal = showDetailModal;
      state.showSimpleModal = showSimpleModal;
    });
  },
});
export const { resetStateResultState, setTestIdsToRemove } = resultsTestSlice.actions;
export default resultsTestSlice.reducer;
