import api from "api";
import Filter from "components/Filter";
import FilterSelect from "components/FilterSelect";
import Icon from "components/Icon";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PGPagination from "components/Pagination/PGPagination";
import Status from "components/Status/Status";
import MainTable from "components/Table/MainTable";
import TimeFilter from "components/TimeFilter";
import { useInitialRender } from "components/useInitialRender";
import {
  CONFIG,
  NEW_DISCOVERY_ID,
  ORDER_EMPTY_OBJECT,
  ORDER_STATUS,
  PAGE_LIMIT,
  stringConstant,
  TABLE_QUICK_TOOLS,
} from "constant";
import { ORDER_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import moment from "moment";
import { useLocation } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "react-activity/dist/Bounce.css";
import { Card, Col, Row } from "react-bootstrap";
import {
  eligibilityMessageColor,
  formatOrders,
  getSelectedColumn,
  parseBooleanValue,
  sortingFilterInLC,
  sortTestList,
  getClientSpecificString,
} from "utils";
import gqApi from "views/GraphQL/gqApi";
import Loader from "../../components/Loader/Loader";
import RedirectLink from "components/RedirectLink";
import { ALTA_ID } from "constant";
import { personalisationInLC } from "utils";
import { PERSONALISATION_KEY } from "constant";
import PersonalizationModal from "components/Modal/personalizationModal";

const options = [
  {
    title: "Edit",
  },
  {
    title: "Delete",
  },
  {
    title: "View/Update",
  },
];

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
  // Custom comparison logic for props
  // Return true if the props are equal, otherwise return false
};

const OrdersView = () => {
  const [error, setError] = useState("");

  const appContext = useContext(AppContext);
  const location = useLocation();

  const [orderTests, setOrderTests] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [openCreator, setOpenCreator] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [newOrder, setNewOrder] = useState(ORDER_EMPTY_OBJECT);
  const [orderToDelete, setOrderToDelete] = useState("");
  const [openHipaa, setOpenHipaa] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState();
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [filter, setFilter] = useState({});
  const initialRender = useInitialRender();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const { filterDates } = TimeFilter;
  const [personalize, setPersonalize] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes];
  const searchTerms = [
    "First Name",
    "Last Name",
    `${stringConstant.sequenceNo}`,
    "Date of Birth",
    "Gender",
    `${stringConstant.show}`,
    "Ord Status",
    "Cancelled Reason",
    "Eligibility Status",
  ];
  const resultTypeOptions = [
    { value: "P/N", label: "P/N" },
    { value: "P/F", label: "P/F" },
  ];
  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
    setOpenHipaa(false);
    setSelectedOrder({});
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredOrders.map((t) => t.id) : []);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadOrders(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  useEffect(() => {
    setPageNo(1);

    fetchFromApi({ ...filter }, timeFilter, 1, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending, timeFilter]);

  useEffect(() => {
    if (pageNo > 1) {
      loadOrders(filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  useEffect(() => {
    if (initialRender) return;

    if (appContext.eventOrder && appContext.eventOrder !== 0) {
      const eventType = appContext.eventOrder.opType;

      const model = formatOrders(appContext.eventOrder.element);
      const newList = [...orders];

      if (!model.isActive) {
        const prevLength = orders.length;
        const newOrderList = orders.filter((t) => t.id !== model.id);
        setOrders(newOrderList);
        setFilteredOrders(filteredOrders.filter((t) => t.id !== model.id));
        if (totalRecord > 0 && prevLength !== newOrderList.length) {
          setTotalRecord(totalRecord - 1);
        }
        return;
      }

      if (eventType === "INSERT") {
        newList.unshift(model);
        setTotalRecord(totalRecord + 1);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        }
      }
      let activeOrders = newList.filter((f) => f.isActive);
      setOrders(activeOrders);
      refreshList(activeOrders);
    }
  }, [appContext.eventOrder]);

  useEffect(() => {
    if (location.state) {
      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      setFilter({ ...filter, [filterTerm]: filterTermName });
    }
  }, [location.state]);

  const handleEditOrder = (order) => {
    appContext.showOrderModal({
      ...order,
      orderDate: moment(order.orderDate).format("YYYY-MM-DDTHH:mm"),
      employee_demographics: {
        ...order.employee_demographics,
        phoneNumber: `${order.countryCode}${order.phoneNumber}`,
      },
      isNew: false,
    });
  };

  const handleUpdateNoteReocrd = async (row) => {
    try {
      setLoading(true);
      await gqApi.updateOrderStatus(row);
      setFilteredOrders((prevTests) => {
        const prevIndex = prevTests.findIndex((t) => t.id === row.id);
        if (prevIndex !== -1) {
          prevTests[prevIndex].notes = row.note;
        }
        return [...prevTests];
      });
      appContext.showSuccessMessage("Note Added Successfully!");
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  const ordersToMap = useMemo(() => {
    const list = filteredOrders ? [...filteredOrders] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredOrders, currentPage]);

  const isDisableCreateTest = (orders, orderId) => {
    const isExist = orders.some((item) => item.id === orderId && item?.barcode);
    return isExist;
  };

  const parseSelectedOption = (id) => {
    if (!id) return null;
    const data = resultTypeOptions.find((f) => f.value === id);
    if (data) return data.label;
    return id;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const getOrderStatusTitle = (value, row) => {
    if (value !== "Cancelled") {
      return value;
    }
    return row.cancelledReason;
  };

  const tdFormat = (item, row) => {
    if (item === "barcode") {
      return (
        <RedirectLink
          pathname={row["status"] === "Processed" ? "senttest" : "senttest"}
          value={{ name: row["id"], term: "orderID" }}
        >
          {row[item]}
        </RedirectLink>
      );
    } else if (item === "status") {
      const orderStatus = row[item] || "Pending";
      return (
        <div className="star-icon-wrapper cursor-pointer">
          <Status
            type="circle"
            size="md"
            title={getOrderStatusTitle(orderStatus, row)}
            color={ORDER_STATUS[orderStatus]}
            crossIcon={orderStatus}
          />
        </div>
      );
    }

    if (item === "Tests") {
      return (
        <div
          className="cursor-pointer"
          onClick={() => appContext.openPatientTestViewModal({ order: row, type: "order" })}
        >
          {row?.testsToDo?.test?.length || 1}
        </div>
      );
    }
    if (item === "siteID") {
      return (
        <a
          style={{ color: "#42cef5" }}
          onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          title={appContext.getSiteName(row.siteID)}
        >
          {appContext.getSiteName(row.siteID)}
        </a>
      );
    }

    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "Tests") {
      return row?.testsToDo?.test?.length || 1;
    }
    if (item === "eligibilityStatus") return row[item]?.message || "Not Checked";
    return row[item];
  };

  const customRenderTD = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case "eligibilityStatus":
        appContext.showEligibilityDetails({
          ...row,
          ...row.eligibilityStatus,
          ins_name_f: row.firstName,
          ins_name_l: row.lastName,
          ins_dob: row.dob,
        });
        break;
      case "firstName":
        appContext.showPatient({ mdID: row.employeeID, isNew: false });
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.edit:
        handleEditOrder(row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        appContext.openNoteModal({
          ...row,
          objectType: "order",
          handleUpdateNoteReocrd,
          title: `${row.firstName} ${row.lastName}`,
        });
        break;
      default:
        break;
    }
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "resultType") {
          return obj[key] && parseSelectedOption(obj[key]).toLowerCase().includes(filters[key]);
        }
        if (key === "whiteLabel") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.orders?.sortBy) {
      setSortDescending(sortingFilters.orders.sortDescending);
      setSortBy(sortingFilters.orders.sortBy);
    }

    setPersonalize(personalisationInLC.get(PERSONALISATION_KEY.ORDER_PERSONALIZE));
  }, []);

  useEffect(() => {
    refreshSummary(orders, orderTests);
  }, [orderTests]);

  const loadOrderSummary = async () => {
    const orderSummary = await api.getSummaryFromPG({ summary: "order" });
    setOrderTests(orderSummary.rows);
  };

  const addDateFilter = (dateFilter) => {
    let dateParams = {};

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start) Object.assign(dateParams, { startDate: start.format("YYYY-MM-DD") });
      if (end) Object.assign(dateParams, { endDate: end.format("YYYY-MM-DD") });
    }
    return dateParams;
  };

  const loadOrders = async (params, dateFilter, page, sortBy, sortByDirection) => {
    setLoading(true);
    const dateParams = addDateFilter(dateFilter);
    const filterParam = { ...params, ...dateParams };
    try {
      Object.assign(filterParam, {
        page: page,
        clientID: CONFIG.clientID,
        siteID: params.Show,
        gender: params.sex,
      });

      if (sortBy) {
        Object.assign(filterParam, { orderBy: sortBy, orderByDirection: sortByDirection ? "desc" : "asc" });
      }

      if (CONFIG.siteID) {
        Object.assign(filterParam, { siteID: CONFIG.siteID });
      }

      const pgList = await api.getOrderListFromPG(filterParam);

      setLoading(false);

      let ttlOrders = orders.concat(pgList.rows);
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
        ttlOrders = pgList.rows;
      }

      setOrders(ttlOrders);
      refreshList(ttlOrders);
    } catch (err) {
      console.log("Load Employees Error:-", err);
      setLoading(false);
    }
  };

  const refreshList = (list) => {
    if (sortBy) {
      setFilteredOrders(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredOrders(list);
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    try {
      const updateSelectedTestResult = filteredOrders.filter((test) => checkboxes.includes(test.id));
      const ttlTest = updateSelectedTestResult.length;
      if (!ttlTest) {
        return;
      }
      setLoading(true);
      const orderToArchive = updateSelectedTestResult.map((o) => ({
        id: o.id,
        userID: appContext?.user?.sub,
        userName: appContext?.user?.name,
      }));

      if (allSelected) {
        const dateParams = addDateFilter(timeFilter);

        await api.archiveAllOrder({
          ...filter,
          ...dateParams,
          userID: appContext?.user?.sub,
          userName: appContext?.user?.name,
        });
      } else {
        await api.archiveOrder(orderToArchive);
      }
      const newOrders = orders.filter((test) => !checkboxes.includes(test.id));

      setOrders(newOrders);
      setFilteredOrders(filteredOrders.filter((test) => !checkboxes.includes(test.id)));
      if (totalRecord > 0) {
        setTotalRecord((prev) => {
          let newTotal = prev - checkboxes.length;
          if (newTotal < 0) newTotal = 0;
          return newTotal;
        });
      }
      setCheckboxes([]);
      if (allSelected) {
        setFilter({});
        setAllSelected(false);
      }

      appContext.showSuccessMessage("Record Archive successfully");

      // if no order to show after archive then call the api and load remaining order
      loadOrders(filter, timeFilter, 1, sortBy, sortDescending);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const refreshSummary = (data, summary) => {
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredOrders(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    setFilteredOrders(newList);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredOrders.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleExportOrder = async () => {
    try {
      setLoading(true);

      const dateParams = addDateFilter(timeFilter);
      const params = { ...filter, ...dateParams, selectedColumn: getSelectedColumn(getPersonalization()) };
      const apiRes = await api.exportOrder(params);

      if (apiRes?.fileName) {
        await api.downloadRequisitionZip(
          `${apiRes?.fileName}`,
          `orders_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.xlsx`
        );
      }

      setLoading(false);
      appContext.showSuccessMessage("File download!!!");
    } catch (ex) {
      console.log("Ex", ex);
      setLoading(false);
      appContext.showErrorMessage(error.message);
    }
  };

  const defaultConfig = useMemo(() => {
    return getClientSpecificString();
  }, []);

  // const getPersonalization = () => {
  //   return ORDER_PERSONALIZE.filter((item) => {
  //     if (item.itemKey === "street2" && !defaultConfig.isShowRoom) return false;
  //     if (item.itemKey === "floor" && !defaultConfig.isShowFloor) return false;
  //     return true;
  //   }).map((f) => {
  //     if (f.itemKey === "street2") {
  //       return { ...f, title: defaultConfig.roomno };
  //     }
  //     return f;
  //   });
  // };

  const handleCreateTestFromOrder = () => {
    const order = filteredOrders.find((f) => f.id === checkboxes[0]);

    appContext.createTestFromOrder({
      ...order,
      orderDate: moment(order.orderDate).format("YYYY-MM-DDTHH:mm"),
      employee_demographics: {
        ...order.employee_demographics,
        phoneNumber: `${order.countryCode}${order.phoneNumber}`,
      },
    });
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);

    const personalisationData = personalisationInLC.saveAs(data, "orderpersonalize");

    setOpenPersonalizationModal(false);

    await api.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  return (
    <div className="container-fluid">
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={() => {
                          if (filteredOrders && filteredOrders.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                      <Icon
                        handleClick={() => setOpenPersonalizationModal(true)}
                        title={"Edit Column"}
                        label={"Edit Column"}
                        iconType={"personalizeIcon"}
                      />
                      <Icon
                        disabled={checkboxes.length === 0}
                        handleClick={() => setOpenDeleteConfirmation(true)}
                        title={"Archive"}
                        label={"Archive"}
                        iconType={"binIcon"}
                      />
                      <Icon
                        disabled={ordersToMap.length === 0}
                        handleClick={handleExportOrder}
                        title={"Export to Excel"}
                        label="Export"
                        iconType={"XLSIcon"}
                      />

                      <Icon
                        handleClick={() => appContext.showLoadOrderModal(true)}
                        iconType={"importIcon"}
                        label={"Load Orders"}
                        title={"Load Orders"}
                      />
                      <Icon
                        handleClick={() => appContext.showOrderModal(newOrder)}
                        title={"Create Order"}
                        label={"Create Order"}
                        iconType={"createIcon"}
                      />
                      <Icon
                        handleClick={handleCreateTestFromOrder}
                        title={"Create Test"}
                        label={"Create Test"}
                        iconType={"createIcon"}
                        // disabled={checkboxes.length === 0}
                        disabled={
                          checkboxes.length === 0 ||
                          checkboxes.length > 1 ||
                          isDisableCreateTest(ordersToMap, checkboxes[0])
                        }
                      />
                    </div>
                  </div>
                  <div>{showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md="6">
                      <Card.Title>
                        <div className="">
                          Orders:
                          <span className="ps-2"> {totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredOrders.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>

                    {/* <div className="buttonHeader ordersButtonHeader justify-content-between mb-1">
                      <div className="ordersBtnWrapper">
                        <div className="buttonHeader ordersButtonHeader">
                          <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                            Filter
                          </Button>
                        </div>
                      </div>
                      <Button className="createButtons" onClick={() => setOpenCreator(true)}>
                        Create New Order
                      </Button>
                    </div>
                    <div>
                      {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                    </div> */}
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <MainTable
                    // columns={getPersonalization()}
                    columns={personalize}
                    rows={ordersToMap}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    selectedRows={checkboxes}
                    tools={quickTools}
                    personalisationKey={"orderpersonalize"}
                    individualRowCssClass={(row) =>
                      row.employee_demographics?.stat && !row.result ? "trDataWrapper isStatRow" : "trDataWrapper"
                    }
                    dropDownOptions={[{ value: "Logs", title: "Audit Trail" }]}
                    handleDropDownClick={(type, row) => {
                      if (type === "Audit Trail") {
                        appContext.showLogs({ ...row, title: "Order Logs", ordID: row.id });
                      }
                    }}
                    customColumnCellRenderer={customRenderTD}
                    handleCellClick={handleCellClick}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {/* {openCreator && (
        <OrderModal user={[newOrder]} appContext={appContext} handleClose={() => setOpenCreator(false)} />
      )} */}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Archive Record"
          message={`Are you sure you want to archive the ${allSelected ? totalRecord : checkboxes.length} record${
            checkboxes.length > 1 ? "s" : ""
          }?`}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
};

export default React.memo(OrdersView, arePropsEqual);
