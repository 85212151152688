import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import LabBarCode from "components/BarCode/LabBarCode";
import { PDFViewer } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import { getRecviedDateFromSrNo, getDemoGraphics } from "utils";
import API from "api";
import BioLabBiologicalPDF from "components/BioLabBiologicalPDF";
import BioLabRequisitionPDF from "components/BioLabRequisitionPDF";
import { Storage } from "aws-amplify";

const RequisitionFormViewModal = ({ handleClose, requisitionView }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [requisitionData, setRequisitionData] = useState(null);
  const [barcodeImage, setBarcodeImage] = useState("");
  const [signature, setSignature] = useState();

  const barcodeRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      if (requisitionView.testID) {
        try {
          const data = await API.getTestByID(requisitionView.testID, "test");
          setRequisitionData({ test: data });
          const siteID = data.baseSiteID || data.siteID;
          const fileName = `${siteID}&&${data.employeeID}&&sig.png`;
          const sigt = await Storage.get(fileName);
          setSignature(sigt);
        } catch (error) {
          console.error("Failed to fetch or process data:", error);
        }
      } else {
        setRequisitionData(requisitionView);
        setBarcodeImage(requisitionView.barcodeBase64);
        setSignature(requisitionView.signature);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [requisitionView]);

  useEffect(() => {
    if (!isLoading && barcodeRef.current) {
      html2canvas(barcodeRef.current)
        .then((canvas) => {
          const base64String = canvas.toDataURL();
          setBarcodeImage(base64String);
        })
        .catch((error) => {
          console.error("Error generating barcode image:", error);
        });
    }
  }, [isLoading]);

  const showRequisitionForm = () => {
    if (isLoading || !requisitionData) return <p>Loading...</p>;
    const { test } = requisitionData;
    const { lab, site } = test;
    const demographics = getDemoGraphics(test);
    const renderProps = {
      barcode: barcodeImage,
      test,
      lab,
      site,
      signature,
      demographics,
      parseSrNoObjectRecviedDate: getRecviedDateFromSrNo,
    };

    const FormComponent = site && site.resultTemplate === "covid" ? BioLabRequisitionPDF : BioLabBiologicalPDF;

    return (
      <PDFViewer className="pdfWrapper">
        <FormComponent {...renderProps} />
      </PDFViewer>
    );
  };

  return (
    <Modal show={true} onHide={handleClose} size={"2x2"} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Requisition Form - {requisitionData?.test?.barcode}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: "absolute", left: "-10000px" }} ref={barcodeRef}>
          <LabBarCode code={requisitionData?.test?.barcode} />
        </div>
        {showRequisitionForm()}
      </Modal.Body>
    </Modal>
  );
};

export default RequisitionFormViewModal;
