import React from "react";
import { currencyformatter } from "constant";

const PlanItem = (props) => {
  const { item, title, newUser, benefitLabel, handleCollapse, compareStrings } = props;

  if (!item) return <></>;

  return (
    <>
      <div
        className={`boxborder testAdmin createClientsWrapper ${handleCollapse && "cursor-pointer"}`}
        onClick={() => handleCollapse && handleCollapse()}
      >
        <label className="modalLineHeaders fw-bold">{title} </label>

        <div className="crew-profile-column-equal form-group-eligibity-wrapper">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Plan Start Date: </label>
            <span>{item?.planStartDate || newUser.planStartDate || "-"}</span>
          </div>
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Plan End Date: </label>
            <span>{item?.planEndDate || newUser.planEndDate || "-"}</span>
          </div>
        </div>

        <div className="form-group-eligibity-wrapper crew-profile-column-equal eligibility-columns-full">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Insurance Plan: </label>
            <div className="flex-1">
              {item.insurance_plan && <span>{item.insurance_plan || "-"}</span>}
              <div className="form-group-eligibity-wrapper w-100">{item.insurance_type_description || ""}</div>
            </div>
          </div>
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold w-50">Plan Number: </label>
            <div className="flex-1">
              <span>{item.plan_number || "-"}</span>
            </div>
          </div>
        </div>
        {benefitLabel && (
          <>
            {" "}
            <div className="crew-profile-column-equal form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Deductible Amount: </label>
                <span className={compareStrings("deductible", "deductibleInYear") ? "error-text" : ""}>
                  {currencyformatter.format(item.deductible.deductibleInYear?.benefit_amount || 0)}
                </span>
                {compareStrings("deductible", "deductibleInYear") || ""}
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Deductible Remaining: </label>
                <span>{currencyformatter.format(item.deductible.deductibleRemaning?.benefit_amount || 0)}</span>
              </div>
            </div>
            <div className="crew-profile-column-equal form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">CoInsurance%: </label>
                <span>{item.deductible.coInsurance?.benefit_percent || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Co Payment: </label>
                <span className={compareStrings("copay", "coPayment") ? "error-text" : ""}>
                  {currencyformatter.format(item.deductible.coPayment?.benefit_amount || 0)}
                </span>
                {compareStrings("copay", "coPayment")}
              </div>
            </div>
          </>
        )}
        <div className="crew-profile-column-equal form-group-eligibity-wrapper">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Hospice: </label>
            <span>
              {newUser?.benefit?.find((f) => f.benefit_description.toLowerCase() === "hospice") ? "YES" : "NO"}
            </span>
          </div>
        </div>
        <div className="form-group-eligibity-wrapper crew-profile-column-reverse w-100">
          <label className="modalLineHeaders fw-bold w-25">Notes: </label>
          <span>{item?.benefit_notes || item?.insurance_type_description || "-"}</span>
        </div>
      </div>
    </>
  );
};

export default PlanItem;
