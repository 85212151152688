import { RESULT_COLOR } from "constant";
import React from "react";

const ResultCell = ({ test, appContext, displayPdf }) => {
  const parsedResult = appContext.parseResult(test);
  const resultColor = test.resultDetails ? RESULT_COLOR["detail"] : RESULT_COLOR[test.result?.toLowerCase()];

  return (
    <td className="ellipsis" style={{ textAlign: "center" }} title={parsedResult}>
      <span
        className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
        onClick={() => displayPdf(test)}
        style={{
          color: resultColor,
        }}
      >
        {parsedResult}
      </span>
    </td>
  );
};

export default ResultCell;
