import deleteIcon from "assets/img/trash-icon.png";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import { CPT_CODE_SEARCH_TYPE } from "constant";

const CptList = (props) => {
  const {
    item,
    setItem,
    cssClass,
    showSelection,
    title,
    searchtype,
    groupWrapper,
    showLabelOnTop = false,
    showCptSelectedCode = true,
    showLabel = true,
    searchCodeKey,
    showInput = true,
    showCross = true,
  } = props;
  let codeKey = searchCodeKey || `${searchtype}Codes`;

  const removeCPTCode = (codeToRemove) => {
    setItem((prevItem) => ({
      ...prevItem,
      codeKey: prevItem[codeKey].filter((c) => c.code !== codeToRemove),
    }));
  };

  const showSelectedCPTCodes = () => {
    if (!item || !item[codeKey]?.length) {
      return "";
    }
    return item[codeKey].map((c, index) => (
      <span key={index} title={c.title} className="cptCodeTag mt-0 mx-1 mb-1">
        {c.code}
        <img src={deleteIcon} alt="delete" onClick={() => removeCPTCode(c.code)} />
      </span>
    ));
  };

  const handleAddCPT = (opt) => {
    if (!opt?.code) return;

    const isItemExists = item[codeKey]?.some((item) => item.code === opt.code);
    
    if (!isItemExists) {
      setItem({
        ...item,
        [codeKey]: [
          ...(item[codeKey] ?? []),
          {
            code: opt.code,
            internalCode: opt.internalCode,
            title: opt.title,
            charges: opt.charges,
            ...(searchtype === CPT_CODE_SEARCH_TYPE.CPT && { referenceCharge: opt.referenceCharge }),
          },
        ],
      });
    }
  };
  return (
    <div className={cssClass}>
      {!showSelection && (
        <InputCPTCodeDropDown
          type="text"
          inputStyle="modalInput"
          labelStyle=" text-capitalize"
          groupWrapper={groupWrapper ? groupWrapper : "mt-4 "}
          label={title}
          placeholder={title}
          optionList={[]}
          handleChange={handleAddCPT}
          searchtype={searchtype}
          selectedDiagnosis={[]}
          showLabelOnTop={showLabelOnTop}
          showLabel={showLabel}
          showInput={showInput}
          value={item ? item.code : ""}
          showCross={showCross}
        />
      )}
      {showCptSelectedCode && <p className="d-flex flex-wrap m-0 w-full">{showSelectedCPTCodes()}</p>}
    </div>
  );
};

export default CptList;
