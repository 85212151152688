export const PROFILE_REQUESTED = [
  [
    {
      internalCode: "921",
      title: "Acute Hepatitis Panel",
      type: "SST",
    },
    {
      internalCode: "907",
      title: "Basic Metabolic Panel",
      type: "SST",
    },
    {
      internalCode: "904",
      title: "Electrolytes Panel",
      type: "SST",
    },
    {
      internalCode: "912",
      title: "Comprehensive Metabolic Panel",
      type: "SST",
    },
    {
      internalCode: "1085",
      title: "Urine Drug Screen Panel",
      type: "UR",
    },
  ],
  [
    {
      internalCode: "1020",
      title: "Urine Drug Scrn w/Alcohol Panel",
      type: "UR",
    },
    {
      internalCode: "913",
      title: "Hepatic Function Panel",
      type: "SST",
    },
    {
      internalCode: "P175",
      title: "Hepatitis ABC Panel",
      type: "SST",
    },
    {
      internalCode: "P169",
      title: "Athritis Panel",
      type: "SST/LV",
    },
    {
      internalCode: "915",
      title: "Lipid Panel",
      type: "SST",
    },
  ],
  [
    {
      internalCode: "916",
      title: "Obstetric Panel",
      type: "LV+R",
    },
    {
      internalCode: "917",
      title: "Renal Function Panel",
      type: "SST",
    },
    {
      internalCode: "A100",
      title: "Food Allergy Panel",
      type: "SST",
    },
    {
      internalCode: "A200",
      title: "Respiratiry Allergy Panel",
      type: "SST",
    },
    {
      internalCode: "7225",
      title: "Lupus Assessment Panel",
      type: "SST",
    },
    {
      internalCode: "7190",
      title: "Vaginosis",
      type: "SW",
    },
  ],
];

export const INDIVIDUAL_REQUESTED = [
  [
    {
      internalCode: "3435",
      title: "ABO & RH",
      type: "LV",
    },
    {
      internalCode: "2030",
      title: "AFP (NON-PREGNANT)",
      type: "SST",
    },
    {
      internalCode: "110",
      title: "AMMONIA",
      type: "LV",
    },
    {
      internalCode: "173",
      title: "AMYLASE",
      type: "SST",
      sub: {
        internalCode: "143",
        title: "LIPASE",
      },
    },
    {
      internalCode: "7219",
      title: "ANA SCREEN",
      type: "SST",
    },
    {
      internalCode: "402",
      title: "ASO SCREEN",
      type: "SST",
    },
    {
      internalCode: "640",
      title: "BHCG QUANT. (Pregnancy)",
      type: "SST",
    },
    {
      internalCode: "114",
      title: "BILIRUBIN (TOTAL)",
      type: "SST",
    },
    {
      internalCode: "9910",
      title: "BNP",
      type: "LV",
      sub: {
        internalCode: "1013",
        title: "NT PROBNP",
      },
    },
    {
      internalCode: "822",
      title: "CA 125",
      type: "SST",
    },
    {
      internalCode: "821",
      title: "CA 19-9",
      type: "SST",
      sub: {
        internalCode: "820",
        title: "CA 15-3",
      },
    },
    {
      internalCode: "7170",
      title: "CANDIDA SP",
      type: "SW",
      sub: {
        internalCode: "7188",
        title: "C. GLABRATA",
      },
    },
    {
      internalCode: "829",
      title: "CARBAMAZEPINE / TEGRETOL",
      type: "R",
    },
    {
      internalCode: "301",
      title: "CBC W/AUTO DIFF.",
      type: "LV",
      sub: {
        internalCode: "302",
        title: "M DIFF.",
      },
    },
    {
      internalCode: "162",
      title: "CCP ANTIBODY",
      type: "SST",
    },
    {
      internalCode: "211",
      title: "CEA",
      type: "SST",
    },
    {
      internalCode: "124",
      title: "CHOLESTEROL",
      type: "SST",
    },
    {
      internalCode: "7228",
      title: "CELIAC PANEL 1gA",
      type: "SST",
      sub: {
        internalCode: "7236",
        title: "1gG",
      },
    },

    {
      internalCode: "6990",
      title: "CK-MB",
      type: "LV",
    },
    {
      internalCode: "125",
      title: "CK-TOTAL",
      type: "SST",
    },
    {
      internalCode: "7060",
      title: "COVID-19, PCR",
      type: "SW",
    },
    {
      internalCode: "1262",
      title: "CRP HS",
      type: "SST",
      sub: {
        internalCode: "1265",
        title: "CRP QT",
      },
    },
    {
      internalCode: "7605",
      title: "D-DIMER",
      type: "LV",
    },
    {
      internalCode: "827",
      title: "DIGOXIN",
      type: "R",
      sub: {
        internalCode: "828",
        title: "DILANTIN",
      },
    },
    {
      internalCode: "319",
      title: "ESR",
      type: "LV",
    },
    {
      internalCode: "6571",
      title: "ESTRADIOL",
      type: "SST",
    },
    {
      internalCode: "659",
      title: "FERRITIN",
      type: "SST",
    },
    {
      internalCode: "7251",
      title: "FLU A + V w/ RSV PCR",
      type: "SW",
    },
    {
      internalCode: "7275",
      title: "FLUVID (FLU A+B and COVID)",
      type: "SW",
    },
  ],
  [
    {
      internalCode: "660",
      title: "FOLATE",
      type: "SST",
    },
    {
      internalCode: "134",
      title: "GLUCOSE FASTING",
      type: "GR",
      sub: {
        internalCode: "135",
        title: "RANDOM",
      },
    },
    {
      internalCode: "2731",
      title: "GLYCOHGB A1C, HPLC",
      type: "LV",
    },
    {
      internalCode: "731",
      title: "H. PYLORI, IgG AB Screen",
      type: "SST",
    },
    {
      internalCode: "237",
      title: "HDL",
      type: "SST",
    },
    {
      internalCode: "676",
      title: "HEP A IgG",
      type: "GR",
      sub: {
        internalCode: "678",
        title: "HEP A IgM",
      },
    },
    {
      internalCode: "670",
      title: "HEP B Surf Ag",
      type: "GR",
      sub: {
        internalCode: "671",
        title: "HBS Ab",
      },
    },
    {
      internalCode: "672",
      title: "HEP B Code Ab Tot",
      type: "GR",
      sub: {
        internalCode: "680",
        title: "Core IgM",
      },
    },
    {
      internalCode: "9224",
      title: "HEP B DNA, Quant. (NAAT)",
      type: "SST",
    },
    {
      internalCode: "677",
      title: "HEP C Antibody (HCV)",
      type: "SST",
    },
    {
      internalCode: "9222",
      title: "HEP C Virus RNA Quant.",
      type: "SST",
    },
    {
      internalCode: "7199",
      title: "HERPES 1 & 2 (NAAT)",
      type: "SW/TP",
    },
    {
      internalCode: "0209",
      title: "HSV I, IgG",
      type: "SST",
      sub: {
        internalCode: "0201",
        title: "HSV II, IgG",
      },
    },
    {
      internalCode: "469",
      title: "HIV Ag + HIV Ab 1 & 2 Screen",
      type: "SST",
    },
    {
      internalCode: "7075",
      title: "HV CONFIRMATION",
      type: "SST",
    },
    {
      internalCode: "2161",
      title: "HOMOCYSTEINE",
      type: "SST",
    },
    {
      internalCode: "174",
      title: "IRON",
      type: "SST",
      sub: {
        internalCode: "140A",
        title: "TIBC",
      },
      sub1: {
        internalCode: "140B",
        title: "UIBC",
      },
    },
    {
      internalCode: "TX44",
      title: "KEPPRA",
      type: "SST",
    },
    {
      internalCode: "5004",
      title: "LEAD",
      type: "RB",
    },
    {
      internalCode: "665",
      title: "LH",
      type: "SST",
      sub: {
        internalCode: "661",
        title: "FSH",
      },
    },
    {
      internalCode: "1451",
      title: "LITHIUM",
      type: "SST/R",
    },
    {
      internalCode: "146",
      title: "NEGNESIUM",
      type: "SST",
    },
    {
      internalCode: "1531",
      title: "MICROOALBUMIN/CREA PROFILE",
      type: "UR",
    },
    {
      internalCode: "7177",
      title: "MMR",
      type: "SST",
    },
    {
      internalCode: "7206",
      title: "MYCOPLASMA",
      type: "UR/TP",
    },
    {
      internalCode: "312",
      title: "PT",
      type: "BL",
      sub: {
        internalCode: "310",
        title: "PTT",
      },
    },
    {
      internalCode: "5011",
      title: "PHENOBARBITAL",
      type: "R",
    },
    {
      internalCode: "1032",
      title: "PRELABUMIN",
      type: "SST",
    },
    {
      internalCode: "1044",
      title: "Nail Fungal Panel",
      type: "FFP",
    },
  ],
  [
    {
      internalCode: "605",
      title: "PREGNANCY Qual.(S)",
      type: "SST/UR",
      sub: {
        internalCode: "604",
        title: "(U)",
      },
    },
    {
      internalCode: "246",
      title: "PROGESTERONE",
      type: "SST",
    },
    {
      internalCode: "255",
      title: "PROCLATIN",
      type: "SST",
    },
    {
      internalCode: "2701",
      title: "PSA TOTAL",
      type: "SST",
      sub: {
        internalCode: "2801",
        title: "PSA FREE",
      },
    },
    {
      internalCode: "150",
      title: "POTASSIUM",
      type: "SST",
    },
    {
      internalCode: "40",
      title: "PTH INTACT",
      type: "SST",
    },
    {
      internalCode: "61133",
      title: "QUANTIFERON TB Gold",
      type: "GRN",
    },
    {
      internalCode: "4011",
      title: "RA Latex / Rheumatoid Factor",
      type: "SST",
    },
    {
      internalCode: "314a",
      title: "RETICULOCYE COUNT",
      type: "LV",
    },
    {
      internalCode: "415",
      title: "RPR",
      type: "SST",
    },
    {
      internalCode: "4140",
      title: "RUBELLA, IgG",
      type: "SST",
    },
    {
      internalCode: "1003",
      title: "SYPHILIS TOTAL",
      type: "SST",
    },
    {
      internalCode: "346",
      title: "T-3 FREE",
      type: "SST",
      sub: {
        internalCode: "166",
        title: "T-3 TOTAL",
      },
    },
    {
      internalCode: "192",
      title: "T3 UPTAKE",
      type: "SST",
    },
    {
      internalCode: "342",
      title: "T-4 FREE",
      type: "SST",
      sub: {
        internalCode: "166",
        title: "T-4 TOTAL",
      },
    },
    {
      internalCode: "1684",
      title: "T-CELL LYMPH ENYN",
      type: "LV",
    },
    {
      internalCode: "601",
      title: "TETOSTERONE TOTAL",
      type: "SST",
      sub: {
        internalCode: "6013",
        title: "FREE",
      },
    },
    {
      internalCode: "4382",
      title: "TROPONIN I",
      type: "LV",
    },
    {
      internalCode: "663",
      title: "TSH (Ulta Sensitive)",
      type: "SST",
    },
    {
      internalCode: "168",
      title: "URIC ACID",
      type: "SST",
    },
    {
      internalCode: "171",
      title: "URINALYSIS TOTAL",
      type: "UR",
      sub: {
        internalCode: "715A",
        title: "REFLEX",
      },
    },
    {
      internalCode: "3392",
      title: "URINE PROT RNDM",
      type: "UR",
      sub: {
        internalCode: "3393",
        title: "24 HR",
      },
    },
    {
      internalCode: "7442",
      title: "VANCOMYCIN PEAK",
      type: "R",
      sub: {
        internalCode: "7441",
        title: "TROUGH",
      },
    },
    {
      internalCode: "7440",
      title: "VANCOMYCIN  RANDOM",
      type: "R",
    },
    {
      internalCode: "826",
      title: "VALPROIC ACID(DEPAKENE)",
      type: "R",
    },
    {
      internalCode: "7175",
      title: "VARICELLA, IGG AB",
      type: "SST",
    },
    {
      internalCode: "651",
      title: "VITAMIN B-12",
      type: "SST",
    },
    {
      internalCode: "7088",
      title: "VITAMIN D-25 HYDROXY",
      type: "SST",
    },
  ],
];

export const MICROBIOLOGY = [
  {
    internalCode: "718",
    title: "BETA STREP",
    type: "SW/C",
  },
  {
    internalCode: "705",
    title: "BLOOD CULTURE",
    type: "CALL",
  },
  {
    internalCode: "1007",
    title: "CANDIDA AURIS CULTURE",
    type: "SW",
  },
  {
    internalCode: "7272",
    title: "C. DIFFICILE",
    type: "ST",
  },
  {
    internalCode: "740",
    title: "CULTURE ROUTINE",
    type: "SW/C",
  },
  {
    internalCode: "732",
    title: "CHLAMYDIA AMP",
    key: ["U", "T", "R"],
    type: "SW/UR",
  },
  {
    internalCode: "7247",
    title: "FLU A+B w/ RSV SCREEN",
    type: "SW/UR",
  },
  {
    internalCode: "7003",
    title: "FLU A+B SCREEN",
    type: "SW",
  },
  {
    internalCode: "7004",
    title: "GBS PANEL",
    type: "SW",
  },
  {
    internalCode: "734",
    title: "GC AMP",
    key: ["U", "T", "R"],
    type: "SW",
  },
  {
    internalCode: "7005",
    title: "GI PCR",
    type: "SW",
  },
  {
    internalCode: "723",
    title: "OCCULT BLOOD - STOOL",
    type: "FOBT/ST",
  },
  {
    internalCode: "7261",
    title: "OVA & PARASITES",
    type: "ST",
  },
  {
    internalCode: "7246",
    title: "RSV SCREEN",
    type: "SW",
  },
  {
    internalCode: "713",
    title: "STOOL CULTURE",
    type: "ST",
  },
  {
    internalCode: "7245",
    title: "STEP A SCREEN",
    type: "SW",
  },
  {
    internalCode: "712",
    title: "SPUTUM CULTURE",
    type: "S",
  },
  {
    internalCode: "714",
    title: "THROAT CULTURE",
    type: "SW/C",
  },
  {
    internalCode: "7164",
    title: "TRICHOMONAS VAGINALIS",
    type: "UR/SW/KOH",
  },
  {
    internalCode: "715",
    title: "URINE CULTURE",
    type: "UR",
  },
  {
    internalCode: "716",
    title: "VAGINAL CULTURE",
    type: "SW/C",
  },
  {
    internalCode: "722",
    title: "WET MOUNT",
    type: "KOH/SW",
  },
  {
    internalCode: "717",
    title: "WOUND CULTURE",
    type: "SW",
  },
];

export const CYTOLOGY = [
  [
    {
      internalCode: "801",
      title: "PAP SMEAR",
    },
    {
      internalCode: "4522",
      title: "HPV",
    },
    {
      internalCode: "8011",
      title: "PAP W/ HPV if Ascus",
    },
    {
      internalCode: "5061",
      title: "URINE CYTOLOGY",
    },
    {
      internalCode: "3994",
      title: "ANAL PAP",
    },
    {
      internalCode: "3995",
      title: "FNA(source)",
    },
    {
      internalCode: "",
      title: "OTHER",
    },
    {
      title: "SOURCE:  C | V | E  LMP",
      key: ["C", "V", "E"],
      type: "LMP",
    },
    {
      title: "CLINICAL",
    },
    {
      title: "HISTORY :",
    },
  ],

  [
    {
      internalCode: "",
      title: "PREGNANT",
    },
    {
      internalCode: "",
      title: "POST PARTUM",
    },
    {
      internalCode: "",
      title: "HYSTERECTOMY",
    },
  ],
  [
    {
      internalCode: "",
      title: "POSTMENOPAUSAL",
    },
    {
      internalCode: "",
      title: "LIQUID BASE PAP",
    },
    {
      internalCode: "",
      title: "HARMONAL",
    },
  ],
  [
    {
      internalCode: "",
      title: "IUD",
    },

    {
      internalCode: "",
      title: "BCP",
    },

    {
      internalCode: "",
      title: "OTHER",
    },
  ],
];

export const BIOPSY = [
  {
    title: "Source Site(s):",
  },
  {
    title: "1)",
  },
  {
    title: "2)",
  },
  {
    title: "CLINICAL HISTORY:",
  },
  {
    title: "DIAGNOSIS:",
  },
];

export const PRESUMPTIVE_ELIGIBILITY = [
  {
    internalCode: "732",
    title: "Chlamydia, DNA",
    type: "DNA SW/UR",
  },
  {
    internalCode: "734",
    title: "GC, DNA",
    type: "DNA SW/UR",
  },
  {
    internalCode: "1085",
    title: "DRUG SCREEN, URINE",
    type: "UR",
  },
  {
    internalCode: "1361",
    title: "1-hour GTT, Post Gluc, Dose",
    type: "UR",
  },
  {
    internalCode: "469",
    title: "HIV Ag + HIV Ab 1 & 2 Screen",
    type: "SST",
  },
  {
    internalCode: "801",
    title: "Pap Smear I",
    type: "CP/SL/SP/TP",
  },
  {
    internalCode: "605",
    title: "Pregnancy Test Qual",
    type: "UR/SST",
  },
  {
    internalCode: "640",
    title: "Pregnancy Test Quant",
    type: "SST",
  },
  {
    internalCode: "415",
    title: "RPR",
    type: "SST",
  },
  {
    internalCode: "715",
    title: "Urine Culture, Bacterial",
    type: "UR",
  },
];

export const REQ_FORM_TESTS_NAMES = [
  {
    internalCode: "921",
    title: "Acute Hepatitis Panel",
    type: "SST",
  },
  {
    internalCode: "907",
    title: "Basic Metabolic Panel",
    type: "SST",
  },
  {
    internalCode: "904",
    title: "Electrolytes Panel",
    type: "SST",
  },
  {
    internalCode: "912",
    title: "Comprehensive Metabolic Panel",
    type: "SST",
  },
  {
    internalCode: "1085",
    title: "Urine Drug Screen Panel",
    type: "UR",
  },
  {
    internalCode: "1020",
    title: "Urine Drug Scrn w/Alcohol Panel",
    type: "UR",
  },
  {
    internalCode: "913",
    title: "Hepatic Function Panel",
    type: "SST",
  },
  {
    internalCode: "P175",
    title: "Hepatitis ABC Panel",
    type: "SST",
  },
  {
    internalCode: "P169",
    title: "Athritis Panel",
    type: "SST/LV",
  },
  {
    internalCode: "915",
    title: "Lipid Panel",
    type: "SST",
  },
  {
    internalCode: "916",
    title: "Obstetric Panel",
    type: "LV+R",
  },
  {
    internalCode: "917",
    title: "Renal Function Panel",
    type: "SST",
  },
  {
    internalCode: "A100",
    title: "Food Allergy Panel",
    type: "SST",
  },
  {
    internalCode: "A200",
    title: "Respiratiry Allergy Panel",
    type: "SST",
  },
  {
    internalCode: "7225",
    title: "Lupus Assessment Panel",
    type: "SST",
  },
  {
    internalCode: "7190",
    title: "Vaginosis",
    type: "SW",
  },
  {
    internalCode: "3435",
    title: "ABO & RH",
    type: "LV",
  },
  {
    internalCode: "2030",
    title: "AFP (NON-PREGNANT)",
    type: "SST",
  },
  {
    internalCode: "110",
    title: "AMMONIA",
    type: "LV",
  },
  {
    internalCode: "173",
    title: "AMYLASE",
    type: "SST",
    sub: {
      internalCode: "143",
      title: "LIPASE",
    },
  },
  {
    internalCode: "7219",
    title: "ANA SCREEN",
    type: "SST",
  },
  {
    internalCode: "402",
    title: "ASO SCREEN",
    type: "SST",
  },
  {
    internalCode: "640",
    title: "BHCG QUANT. (Pregnancy)",
    type: "SST",
  },
  {
    internalCode: "114",
    title: "BILIRUBIN (TOTAL)",
    type: "SST",
  },
  {
    internalCode: "9910",
    title: "BNP",
    type: "LV",
    sub: {
      internalCode: "1013",
      title: "NT PROBNP",
    },
  },
  {
    internalCode: "822",
    title: "CA 125",
    type: "SST",
  },
  {
    internalCode: "821",
    title: "CA 19-9",
    type: "SST",
    sub: {
      internalCode: "820",
      title: "CA 15-3",
    },
  },
  {
    internalCode: "7170",
    title: "CANDIDA SP",
    type: "SW",
    sub: {
      internalCode: "7188",
      title: "C. GLABRATA",
    },
  },
  {
    internalCode: "829",
    title: "CARBAMAZEPINE / TEGRETOL",
    type: "R",
  },
  {
    internalCode: "301",
    title: "CBC W/AUTO DIFF.",
    type: "LV",
    sub: {
      internalCode: "302",
      title: "M DIFF.",
    },
  },
  {
    internalCode: "162",
    title: "CCP ANTIBODY",
    type: "SST",
  },
  {
    internalCode: "211",
    title: "CEA",
    type: "SST",
  },
  {
    internalCode: "124",
    title: "CHOLESTEROL",
    type: "SST",
  },
  {
    internalCode: "7228",
    title: "CELIAC PANEL 1gA",
    type: "SST",
    sub: {
      internalCode: "7236",
      title: "1gG",
    },
  },

  {
    internalCode: "6990",
    title: "CK-MB",
    type: "LV",
  },
  {
    internalCode: "125",
    title: "CK-TOTAL",
    type: "SST",
  },
  {
    internalCode: "7060",
    title: "COVID-19, PCR",
    type: "SW",
  },
  {
    internalCode: "1262",
    title: "CRP HS",
    type: "SST",
    sub: {
      internalCode: "1265",
      title: "CRP QT",
    },
  },
  {
    internalCode: "7605",
    title: "D-DIMER",
    type: "LV",
  },
  {
    internalCode: "827",
    title: "DIGOXIN",
    type: "R",
    sub: {
      internalCode: "828",
      title: "DILANTIN",
    },
  },
  {
    internalCode: "319",
    title: "ESR",
    type: "LV",
  },
  {
    internalCode: "6571",
    title: "ESTRADIOL",
    type: "SST",
  },
  {
    internalCode: "659",
    title: "FERRITIN",
    type: "SST",
  },
  {
    internalCode: "7251",
    title: "FLU A + V w/ RSV PCR",
    type: "SW",
  },
  {
    internalCode: "7275",
    title: "FLUVID (FLU A+B and COVID)",
    type: "SW",
  },
  {
    internalCode: "660",
    title: "FOLATE",
    type: "SST",
  },
  {
    internalCode: "134",
    title: "GLUCOSE FASTING",
    type: "GR",
    sub: {
      internalCode: "135",
      title: "RANDOM",
    },
  },
  {
    internalCode: "2731",
    title: "GLYCOHGB A1C, HPLC",
    type: "LV",
  },
  {
    internalCode: "731",
    title: "H. PYLORI, IgG AB Screen",
    type: "SST",
  },
  {
    internalCode: "237",
    title: "HDL",
    type: "SST",
  },
  {
    internalCode: "676",
    title: "HEP A IgG",
    type: "GR",
    sub: {
      internalCode: "678",
      title: "HEP A IgM",
    },
  },
  {
    internalCode: "670",
    title: "HEP B Surf Ag",
    type: "GR",
    sub: {
      internalCode: "671",
      title: "HBS Ab",
    },
  },
  {
    internalCode: "672",
    title: "HEP B Code Ab Tot",
    type: "GR",
    sub: {
      internalCode: "680",
      title: "Core IgM",
    },
  },
  {
    internalCode: "9224",
    title: "HEP B DNA, Quant. (NAAT)",
    type: "SST",
  },
  {
    internalCode: "677",
    title: "HEP C Antibody (HCV)",
    type: "SST",
  },
  {
    internalCode: "9222",
    title: "HEP C Virus RNA Quant.",
    type: "SST",
  },
  {
    internalCode: "7199",
    title: "HERPES 1 & 2 (NAAT)",
    type: "SW/TP",
  },
  {
    internalCode: "0209",
    title: "HSV I, IgG",
    type: "SST",
    sub: {
      internalCode: "0201",
      title: "HSV II, IgG",
    },
  },
  {
    internalCode: "469",
    title: "HIV Ag + HIV Ab 1 & 2 Screen",
    type: "SST",
  },
  {
    internalCode: "7075",
    title: "HV CONFIRMATION",
    type: "SST",
  },
  {
    internalCode: "2161",
    title: "HOMOCYSTEINE",
    type: "SST",
  },
  {
    internalCode: "174",
    title: "IRON",
    type: "SST",
    sub: {
      internalCode: "140A",
      title: "TIBC",
    },
    sub1: {
      internalCode: "140B",
      title: "UIBC",
    },
  },
  {
    internalCode: "TX44",
    title: "KEPPRA",
    type: "SST",
  },
  {
    internalCode: "5004",
    title: "LEAD",
    type: "RB",
  },
  {
    internalCode: "665",
    title: "LH",
    type: "SST",
    sub: {
      internalCode: "661",
      title: "FSH",
    },
  },
  {
    internalCode: "1451",
    title: "LITHIUM",
    type: "SST/R",
  },
  {
    internalCode: "146",
    title: "NEGNESIUM",
    type: "SST",
  },
  {
    internalCode: "1531",
    title: "MICROOALBUMIN/CREA PROFILE",
    type: "UR",
  },
  {
    internalCode: "7177",
    title: "MMR",
    type: "SST",
  },
  {
    internalCode: "7206",
    title: "MYCOPLASMA",
    type: "UR/TP",
  },
  {
    internalCode: "312",
    title: "PT",
    type: "BL",
    sub: {
      internalCode: "310",
      title: "PTT",
    },
  },
  {
    internalCode: "5011",
    title: "PHENOBARBITAL",
    type: "R",
  },
  {
    internalCode: "1032",
    title: "PRELABUMIN",
    type: "SST",
  },
  {
    internalCode: "1044",
    title: "Nail Fungal Panel",
    type: "FFP",
  },
  {
    internalCode: "605",
    title: "PREGNANCY Qual.(S)",
    type: "SST/UR",
    sub: {
      internalCode: "604",
      title: "(U)",
    },
  },
  {
    internalCode: "246",
    title: "PROGESTERONE",
    type: "SST",
  },
  {
    internalCode: "255",
    title: "PROCLATIN",
    type: "SST",
  },
  {
    internalCode: "2701",
    title: "PSA TOTAL",
    type: "SST",
    sub: {
      internalCode: "2801",
      title: "PSA FREE",
    },
  },
  {
    internalCode: "150",
    title: "POTASSIUM",
    type: "SST",
  },
  {
    internalCode: "40",
    title: "PTH INTACT",
    type: "SST",
  },
  {
    internalCode: "61133",
    title: "QUANTIFERON TB Gold",
    type: "GRN",
  },
  {
    internalCode: "4011",
    title: "RA Latex / Rheumatoid Factor",
    type: "SST",
  },
  {
    internalCode: "314a",
    title: "RETICULOCYE COUNT",
    type: "LV",
  },
  {
    internalCode: "415",
    title: "RPR",
    type: "SST",
  },
  {
    internalCode: "4140",
    title: "RUBELLA, IgG",
    type: "SST",
  },
  {
    internalCode: "1003",
    title: "SYPHILIS TOTAL",
    type: "SST",
  },
  {
    internalCode: "346",
    title: "T-3 FREE",
    type: "SST",
    sub: {
      internalCode: "166",
      title: "T-3 TOTAL",
    },
  },
  {
    internalCode: "192",
    title: "T3 UPTAKE",
    type: "SST",
  },
  {
    internalCode: "342",
    title: "T-4 FREE",
    type: "SST",
    sub: {
      internalCode: "166",
      title: "T-4 TOTAL",
    },
  },
  {
    internalCode: "1684",
    title: "T-CELL LYMPH ENYN",
    type: "LV",
  },
  {
    internalCode: "601",
    title: "TETOSTERONE TOTAL",
    type: "SST",
    sub: {
      internalCode: "6013",
      title: "FREE",
    },
  },
  {
    internalCode: "4382",
    title: "TROPONIN I",
    type: "LV",
  },
  {
    internalCode: "663",
    title: "TSH (Ulta Sensitive)",
    type: "SST",
  },
  {
    internalCode: "168",
    title: "URIC ACID",
    type: "SST",
  },
  {
    internalCode: "171",
    title: "URINALYSIS TOTAL",
    type: "UR",
    sub: {
      internalCode: "715A",
      title: "REFLEX",
    },
  },
  {
    internalCode: "3392",
    title: "URINE PROT RNDM",
    type: "UR",
    sub: {
      internalCode: "3393",
      title: "24 HR",
    },
  },
  {
    internalCode: "7442",
    title: "VANCOMYCIN PEAK",
    type: "R",
    sub: {
      internalCode: "7441",
      title: "TROUGH",
    },
  },
  {
    internalCode: "7440",
    title: "VANCOMYCIN  RANDOM",
    type: "R",
  },
  {
    internalCode: "826",
    title: "VALPROIC ACID(DEPAKENE)",
    type: "R",
  },
  {
    internalCode: "7175",
    title: "VARICELLA, IGG AB",
    type: "SST",
  },
  {
    internalCode: "651",
    title: "VITAMIN B-12",
    type: "SST",
  },
  {
    internalCode: "7088",
    title: "VITAMIN D-25 HYDROXY",
    type: "SST",
  },
  {
    internalCode: "718",
    title: "BETA STREP",
    type: "SW/C",
  },
  {
    internalCode: "705",
    title: "BLOOD CULTURE",
    type: "CALL",
  },
  {
    internalCode: "1007",
    title: "CANDIDA AURIS CULTURE",
    type: "SW",
  },
  {
    internalCode: "7272",
    title: "C. DIFFICILE",
    type: "ST",
  },
  {
    internalCode: "740",
    title: "CULTURE ROUTINE",
    type: "SW/C",
  },
  {
    internalCode: "732",
    title: "CHLAMYDIA AMP",
    key: ["U", "T", "R"],
    type: "SW/UR",
  },
  {
    internalCode: "7247",
    title: "FLU A+B w/ RSV SCREEN",
    type: "SW/UR",
  },
  {
    internalCode: "7003",
    title: "FLU A+B SCREEN",
    type: "SW",
  },
  {
    internalCode: "7004",
    title: "GBS PANEL",
    type: "SW",
  },
  {
    internalCode: "734",
    title: "GC AMP",
    key: ["U", "T", "R"],
    type: "SW",
  },
  {
    internalCode: "7005",
    title: "GI PCR",
    type: "SW",
  },
  {
    internalCode: "723",
    title: "OCCULT BLOOD - STOOL",
    type: "FOBT/ST",
  },
  {
    internalCode: "7261",
    title: "OVA & PARASITES",
    type: "ST",
  },
  {
    internalCode: "7246",
    title: "RSV SCREEN",
    type: "SW",
  },
  {
    internalCode: "713",
    title: "STOOL CULTURE",
    type: "ST",
  },
  {
    internalCode: "7245",
    title: "STEP A SCREEN",
    type: "SW",
  },
  {
    internalCode: "712",
    title: "SPUTUM CULTURE",
    type: "S",
  },
  {
    internalCode: "714",
    title: "THROAT CULTURE",
    type: "SW/C",
  },
  {
    internalCode: "7164",
    title: "TRICHOMONAS VAGINALIS",
    type: "UR/SW/KOH",
  },
  {
    internalCode: "715",
    title: "URINE CULTURE",
    type: "UR",
  },
  {
    internalCode: "716",
    title: "VAGINAL CULTURE",
    type: "SW/C",
  },
  {
    internalCode: "722",
    title: "WET MOUNT",
    type: "KOH/SW",
  },
  {
    internalCode: "717",
    title: "WOUND CULTURE",
    type: "SW",
  },
  {
    internalCode: "801",
    title: "PAP SMEAR",
  },
  {
    internalCode: "4522",
    title: "HPV",
  },
  {
    internalCode: "8011",
    title: "PAP W/ HPV if Ascus",
  },
  {
    internalCode: "5061",
    title: "URINE CYTOLOGY",
  },
  {
    internalCode: "3994",
    title: "ANAL PAP",
  },
  {
    internalCode: "3995",
    title: "FNA(source)",
  },
  {
    internalCode: "732",
    title: "Chlamydia, DNA",
    type: "DNA SW/UR",
  },
  {
    internalCode: "734",
    title: "GC, DNA",
    type: "DNA SW/UR",
  },
  {
    internalCode: "1085",
    title: "DRUG SCREEN, URINE",
    type: "UR",
  },
  {
    internalCode: "1361",
    title: "1-hour GTT, Post Gluc, Dose",
    type: "UR",
  },
  {
    internalCode: "469",
    title: "HIV Ag + HIV Ab 1 & 2 Screen",
    type: "SST",
  },
  {
    internalCode: "801",
    title: "Pap Smear I",
    type: "CP/SL/SP/TP",
  },
  {
    internalCode: "605",
    title: "Pregnancy Test Qual",
    type: "UR/SST",
  },
  {
    internalCode: "640",
    title: "Pregnancy Test Quant",
    type: "SST",
  },
  {
    internalCode: "415",
    title: "RPR",
    type: "SST",
  },
  {
    internalCode: "715",
    title: "Urine Culture, Bacterial",
    type: "UR",
  },
];

export const REQ_FORM_TESTS_NAME = [
  {
    type: "SST",
    label: "Acute Hepatitis Panel",
    value: "921",
  },
  {
    type: "SST",
    label: "Basic Metabolic Panel",
    value: "907",
  },
  {
    type: "SST",
    label: "Electrolytes Panel",
    value: "904",
  },
  {
    type: "SST",
    label: "Comprehensive Metabolic Panel",
    value: "912",
  },
  {
    type: "UR",
    label: "Urine Drug Screen Panel",
    value: "1085",
  },
  {
    type: "UR",
    label: "Urine Drug Scrn W/Alcohol Panel",
    value: "1020",
  },
  {
    type: "SST",
    label: "Hepatic Function Panel",
    value: "913",
  },
  {
    type: "SST",
    label: "Hepatitis ABC Panel",
    value: "P175",
  },
  {
    type: "SST/LV",
    label: "Athritis Panel",
    value: "P169",
  },
  {
    type: "SST",
    label: "Lipid Panel",
    value: "915",
  },
  {
    type: "LV+R",
    label: "Obstetric Panel",
    value: "916",
  },
  {
    type: "SST",
    label: "Renal Function Panel",
    value: "917",
  },
  {
    type: "SST",
    label: "Food Allergy Panel",
    value: "A100",
  },
  {
    type: "SST",
    label: "Respiratiry Allergy Panel",
    value: "A200",
  },
  {
    type: "SST",
    label: "Lupus Assessment Panel",
    value: "7225",
  },
  {
    type: "SW",
    label: "Vaginosis",
    value: "7190",
  },
  {
    type: "LV",
    label: "ABO & RH",
    value: "3435",
  },
  {
    type: "SST",
    label: "AFP (NON-Pregnant)",
    value: "2030",
  },
  {
    type: "LV",
    label: "Ammonia",
    value: "110",
  },
  {
    type: "SST",
    label: "Amylase",
    value: "173",
  },
  {
    type: "SST",
    label: "Amylase Lipase",
    value: "143",
  },
  {
    type: "SST",
    label: "ANA Screen",
    value: "7219",
  },
  {
    type: "SST",
    label: "ASO Screen",
    value: "402",
  },
  {
    type: "SST",
    label: "Bhcg Quant. (Pregnancy)",
    value: "640",
  },
  {
    type: "SST",
    label: "Bilirubin (Total)",
    value: "114",
  },
  {
    type: "LV",
    label: "BNP",
    value: "9910",
  },
  {
    type: "LV",
    label: "BNP NT Probnp",
    value: "1013",
  },
  {
    type: "SST",
    label: "CA 125",
    value: "822",
  },
  {
    type: "SST",
    label: "CA 19-9",
    value: "821",
  },
  {
    type: "SST",
    label: "CA 19-9 CA 15-3",
    value: "820",
  },
  {
    type: "SW",
    label: "Candida SP",
    value: "7170",
  },
  {
    type: "SW",
    label: "Candida SP C. Glabrata",
    value: "7188",
  },
  {
    type: "R",
    label: "Carbamazepine / Tegretol",
    value: "829",
  },
  {
    type: "LV",
    label: "CBC W/Auto Diff.",
    value: "301",
  },
  {
    type: "LV",
    label: "CBC W/Auto Diff. M Diff.",
    value: "302",
  },
  {
    type: "SST",
    label: "CCP Antibody",
    value: "162",
  },
  {
    type: "SST",
    label: "CEA",
    value: "211",
  },
  {
    type: "SST",
    label: "Cholesterol",
    value: "124",
  },
  {
    type: "SST",
    label: "Celiac Panel 1GA",
    value: "7228",
  },
  {
    type: "SST",
    label: "Celiac Panel 1GA 1GG",
    value: "7236",
  },
  {
    type: "LV",
    label: "CK-MB",
    value: "6990",
  },
  {
    type: "SST",
    label: "CK-Total",
    value: "125",
  },
  {
    type: "SW",
    label: "Covid-19, PCR",
    value: "7060",
  },
  {
    type: "SST",
    label: "CRP HS",
    value: "1262",
  },
  {
    type: "SST",
    label: "CRP HS CRP QT",
    value: "1265",
  },
  {
    type: "LV",
    label: "D-Dimer",
    value: "7605",
  },
  {
    type: "R",
    label: "Digoxin",
    value: "827",
  },
  {
    type: "R",
    label: "Digoxin Dilantin",
    value: "828",
  },
  {
    type: "LV",
    label: "ESR",
    value: "319",
  },
  {
    type: "SST",
    label: "Estradiol",
    value: "6571",
  },
  {
    type: "SST",
    label: "Ferritin",
    value: "659",
  },
  {
    type: "SW",
    label: "FLU A + V W/ RSV PCR",
    value: "7251",
  },
  {
    type: "SW",
    label: "Fluvid (FLU A+B AND Covid)",
    value: "7275",
  },
  {
    type: "SST",
    label: "Folate",
    value: "660",
  },
  {
    type: "GR",
    label: "Glucose Fasting",
    value: "134",
  },
  {
    type: "GR",
    label: "Glucose Fasting Random",
    value: "135",
  },
  {
    type: "LV",
    label: "Glycohgb A1C, Hplc",
    value: "2731",
  },
  {
    type: "SST",
    label: "H. Pylori, IGG AB Screen",
    value: "731",
  },
  {
    type: "SST",
    label: "HDL",
    value: "237",
  },
  {
    type: "GR",
    label: "HEP A IGG",
    value: "676",
  },
  {
    type: "GR",
    label: "HEP A IGG HEP A IGM",
    value: "678",
  },
  {
    type: "GR",
    label: "HEP B Surf AG",
    value: "670",
  },
  {
    type: "GR",
    label: "HEP B Surf AG HBS AB",
    value: "671",
  },
  {
    type: "GR",
    label: "HEP B Code AB TOT",
    value: "672",
  },
  {
    type: "GR",
    label: "HEP B Code AB TOT Core IGM",
    value: "680",
  },
  {
    type: "SST",
    label: "HEP B DNA, Quant. (Naat)",
    value: "9224",
  },
  {
    type: "SST",
    label: "HEP C Antibody (HCV)",
    value: "677",
  },
  {
    type: "SST",
    label: "HEP C Virus RNA Quant.",
    value: "9222",
  },
  {
    type: "SW/TP",
    label: "Herpes 1 & 2 (Naat)",
    value: "7199",
  },
  {
    type: "SST",
    label: "HSV I, IGG",
    value: "0209",
  },
  {
    type: "SST",
    label: "HSV I, IGG HSV II, IGG",
    value: "0201",
  },
  {
    type: "SST",
    label: "HIV AG + HIV AB 1 & 2 Screen",
    value: "469",
  },
  {
    type: "SST",
    label: "HV Confirmation",
    value: "7075",
  },
  {
    type: "SST",
    label: "Homocysteine",
    value: "2161",
  },
  {
    type: "SST",
    label: "Iron",
    value: "174",
  },
  {
    type: "SST",
    label: "Iron Tibc",
    value: "140A",
  },
  {
    type: "SST",
    label: "Keppra",
    value: "TX44",
  },
  {
    type: "RB",
    label: "Lead",
    value: "5004",
  },
  {
    type: "SST",
    label: "LH",
    value: "665",
  },
  {
    type: "SST",
    label: "LH FSH",
    value: "661",
  },
  {
    type: "SST/R",
    label: "Lithium",
    value: "1451",
  },
  {
    type: "SST",
    label: "Negnesium",
    value: "146",
  },
  {
    type: "UR",
    label: "Microoalbumin/Crea Profile",
    value: "1531",
  },
  {
    type: "SST",
    label: "MMR",
    value: "7177",
  },
  {
    type: "UR/TP",
    label: "Mycoplasma",
    value: "7206",
  },
  {
    type: "BL",
    label: "PT",
    value: "312",
  },
  {
    type: "BL",
    label: "PT PTT",
    value: "310",
  },
  {
    type: "R",
    label: "Phenobarbital",
    value: "5011",
  },
  {
    type: "SST",
    label: "Prelabumin",
    value: "1032",
  },
  {
    type: "FFP",
    label: "Nail Fungal Panel",
    value: "1044",
  },
  {
    type: "SST/UR",
    label: "Pregnancy Qual.(S)",
    value: "605",
  },
  {
    type: "SST/UR",
    label: "Pregnancy Qual.(S) (U)",
    value: "604",
  },
  {
    type: "SST",
    label: "Progesterone",
    value: "246",
  },
  {
    type: "SST",
    label: "Proclatin",
    value: "255",
  },
  {
    type: "SST",
    label: "PSA Total",
    value: "2701",
  },
  {
    type: "SST",
    label: "PSA Total PSA Free",
    value: "2801",
  },
  {
    type: "SST",
    label: "Potassium",
    value: "150",
  },
  {
    type: "SST",
    label: "PTH Intact",
    value: "40",
  },
  {
    type: "GRN",
    label: "Quantiferon TB Gold",
    value: "61133",
  },
  {
    type: "SST",
    label: "RA Latex / Rheumatoid Factor",
    value: "4011",
  },
  {
    type: "LV",
    label: "Reticulocye Count",
    value: "314a",
  },
  {
    type: "SST",
    label: "RPR",
    value: "415",
  },
  {
    type: "SST",
    label: "Rubella, IGG",
    value: "4140",
  },
  {
    type: "SST",
    label: "Syphilis Total",
    value: "1003",
  },
  {
    type: "SST",
    label: "T-3 Free",
    value: "346",
  },
  {
    type: "SST",
    label: "T-3 Free T-3 Total",
    value: "166",
  },
  {
    type: "SST",
    label: "T3 Uptake",
    value: "192",
  },
  {
    type: "SST",
    label: "T-4 Free",
    value: "342",
  },
  {
    type: "SST",
    label: "T-4 Free T-4 Total",
    value: "166",
  },
  {
    type: "LV",
    label: "T-Cell Lymph Enyn",
    value: "1684",
  },
  {
    type: "SST",
    label: "Tetosterone Total",
    value: "601",
  },
  {
    type: "SST",
    label: "Tetosterone Total Free",
    value: "6013",
  },
  {
    type: "LV",
    label: "Troponin I",
    value: "4382",
  },
  {
    type: "SST",
    label: "TSH (Ulta Sensitive)",
    value: "663",
  },
  {
    type: "SST",
    label: "Uric Acid",
    value: "168",
  },
  {
    type: "UR",
    label: "Urinalysis Total",
    value: "171",
  },
  {
    type: "UR",
    label: "Urinalysis Total Reflex",
    value: "715A",
  },
  {
    type: "UR",
    label: "Urine Prot Rndm",
    value: "3392",
  },
  {
    type: "UR",
    label: "Urine Prot Rndm 24 HR",
    value: "3393",
  },
  {
    type: "R",
    label: "Vancomycin Peak",
    value: "7442",
  },
  {
    type: "R",
    label: "Vancomycin Peak Trough",
    value: "7441",
  },
  {
    type: "R",
    label: "Vancomycin  Random",
    value: "7440",
  },
  {
    type: "R",
    label: "Valproic Acid(Depakene)",
    value: "826",
  },
  {
    type: "SST",
    label: "Varicella, IGG AB",
    value: "7175",
  },
  {
    type: "SST",
    label: "Vitamin B-12",
    value: "651",
  },
  {
    type: "SST",
    label: "Vitamin D-25 Hydroxy",
    value: "7088",
  },
  {
    type: "SW/C",
    label: "Beta Strep",
    value: "718",
  },
  {
    type: "CALL",
    label: "Blood Culture",
    value: "705",
  },
  {
    type: "SW",
    label: "Candida Auris Culture",
    value: "1007",
  },
  {
    type: "ST",
    label: "C. Difficile",
    value: "7272",
  },
  {
    type: "SW/C",
    label: "Culture Routine",
    value: "740",
  },
  {
    type: "SW/UR",
    label: "Chlamydia AMP",
    value: "732",
  },
  {
    type: "SW/UR",
    label: "FLU A+B W/ RSV Screen",
    value: "7247",
  },
  {
    type: "SW",
    label: "FLU A+B Screen",
    value: "7003",
  },
  {
    type: "SW",
    label: "GBS Panel",
    value: "7004",
  },
  {
    type: "SW",
    label: "GC AMP",
    value: "734",
  },
  {
    type: "SW",
    label: "GI PCR",
    value: "7005",
  },
  {
    type: "FOBT/ST",
    label: "Occult Blood - Stool",
    value: "723",
  },
  {
    type: "ST",
    label: "OVA & Parasites",
    value: "7261",
  },
  {
    type: "SW",
    label: "RSV Screen",
    value: "7246",
  },
  {
    type: "ST",
    label: "Stool Culture",
    value: "713",
  },
  {
    type: "SW",
    label: "Step A Screen",
    value: "7245",
  },
  {
    type: "S",
    label: "Sputum Culture",
    value: "712",
  },
  {
    type: "SW/C",
    label: "Throat Culture",
    value: "714",
  },
  {
    type: "UR/SW/KOH",
    label: "Trichomonas Vaginalis",
    value: "7164",
  },
  {
    type: "UR",
    label: "Urine Culture",
    value: "715",
  },
  {
    type: "SW/C",
    label: "Vaginal Culture",
    value: "716",
  },
  {
    type: "KOH/SW",
    label: "WET Mount",
    value: "722",
  },
  {
    type: "SW",
    label: "Wound Culture",
    value: "717",
  },
  {
    type: "",
    label: "PAP Smear",
    value: "801",
  },
  {
    type: "",
    label: "HPV",
    value: "4522",
  },
  {
    type: "",
    label: "PAP W/ HPV IF Ascus",
    value: "8011",
  },
  {
    type: "",
    label: "Urine Cytology",
    value: "5061",
  },
  {
    type: "",
    label: "Anal PAP",
    value: "3994",
  },
  {
    type: "",
    label: "FNA(Source)",
    value: "3995",
  },
  {
    type: "DNA SW/UR",
    label: "Chlamydia, DNA",
    value: "732",
  },
  {
    type: "DNA SW/UR",
    label: "GC, DNA",
    value: "734",
  },
  {
    type: "UR",
    label: "Drug Screen, Urine",
    value: "1085",
  },
  {
    type: "UR",
    label: "1-Hour GTT, Post Gluc, Dose",
    value: "1361",
  },
  {
    type: "SST",
    label: "HIV AG + HIV AB 1 & 2 Screen",
    value: "469",
  },
  {
    type: "CP/SL/SP/TP",
    label: "PAP Smear I",
    value: "801",
  },
  {
    type: "UR/SST",
    label: "Pregnancy Test Qual",
    value: "605",
  },
  {
    type: "SST",
    label: "Pregnancy Test Quant",
    value: "640",
  },
  {
    type: "SST",
    label: "RPR",
    value: "415",
  },
  {
    type: "UR",
    label: "Urine Culture, Bacterial",
    value: "715",
  },
];
