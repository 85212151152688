import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { NEW_PROVIDER_OBJECT } from "constant";
import { updateCompanySetting } from "store/features/companySetting/companySettingSlice";
import { callAmplifyAPI, callAmplifyAPIPut } from "views/GraphQL/gqApi";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  PROVIDER,
  PROVIDER_FETCH,
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_CLIENT,
} from "./providersContants";

// Async thunk to fetch all providers
export const fetchAllProviders = createAsyncThunk(PROVIDER_FETCH, async () => {
  const response = await callAmplifyAPI("/providers/list");
  return response?.rows || {};
});

// Async thunk to create a provider
export const createProviderAsync = createAsyncThunk(
  CREATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    let providerDefaultSetting = defaultSetting;
    const response = await callAmplifyAPI("/providers", {
      name: provider.name,
      clientID: provider.clientID,
      firstName: provider.firstName,
      middleName: provider.middleName,
      lastName: provider.lastName,
      code: provider.code,
      npi: provider.npi,
      street: provider.street,
      city: provider.city,
      state: provider.state,
      zip: provider.zip,
      phoneNumber: provider.phoneNumber,
      fax: provider.fax,
      isActive: provider.isActive,
      taxid: provider.taxid,
      speciality: provider.speciality,
      npiType: provider.npiType,
      associatedClient: provider.associatedClient,
    });
    Object.keys(defaultSetting).map((m) => {
      providerDefaultSetting[m] = response?.rows?.id;
    });

    //TODO:: Functionality Pending to add in Default Setting
    //dispatch(updateCompanySetting(providerDefaultSetting));
    return response?.rows;
  }
);

// Async thunk to update a provider
export const updateProviderAsync = createAsyncThunk(
  UPDATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    const response = await callAmplifyAPIPut("/providers", {
      id: provider.id,
      name: provider.name,
      clientID: provider.clientID,
      firstName: provider.firstName,
      middleName: provider.middleName,
      lastName: provider.lastName,
      code: provider.code,
      npi: provider.npi,
      street: provider.street,
      city: provider.city,
      state: provider.state,
      zip: provider.zip,
      phoneNumber: provider.phoneNumber,
      fax: provider.fax,
      isActive: provider.isActive,
      taxid: provider.taxid,
      speciality: provider.speciality,
      npiType: provider.npiType,
      associatedClient: provider.associatedClient,
    });
    // dispatch(updateCompanySetting(defaultSetting));
    return response?.rows;
  }
);
export const updateProviderClientAsync = createAsyncThunk(UPDATE_PROVIDER_CLIENT, async (provider, { dispatch }) => {
  // let providerDefaultSetting = defaultSetting;
  const response = await api.updateProviderClient(provider.pId, provider.uId);
  // Object.keys(defaultSetting).map((m) => {
  //   providerDefaultSetting[m] = response.id;
  // });
  // dispatch(updateCompanySetting(providerDefaultSetting));
  return response;
});
export const updateProviderNoteAsync = createAsyncThunk(UPDATE_PROVIDER, async (provider, { dispatch }) => {
  const response = await callAmplifyAPI("/providers/note", {
    id: provider.id,
    note: provider.note,
  });
  dispatch(setMessage("Provider Note Add SuccessFully!", MESSAGE_MODES.success));
  return response?.rows;
});

// Async thunk to delete a provider
export const deleteProviderAsync = createAsyncThunk(DELETE_PROVIDER, async (providerId) => {
  const response = await callAmplifyAPI("/providers/remove", {
    id: providerId,
  });
  return response?.rows?.id;
});

const providerSlice = createSlice({
  name: PROVIDER,
  initialState: {
    providers: [],
    filteredProviders: [],
    openCreator: false,
    newUser: NEW_PROVIDER_OBJECT,
  },
  reducers: {
    setFilteredProviders: (state, action) => {
      state.filteredProviders = action.payload;
    },
    setOpenCreatorProviderModal: (state, action) => {
      state.openCreator = action.payload;
    },
    setNewUserProvider: (state, action) => {
      state.newUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.filteredProviders = action.payload;
      })
      .addCase(createProviderAsync.fulfilled, (state, action) => {
        state.providers.unshift(action.payload);
        state.filteredProviders.unshift(action.payload);
      })

      .addCase(deleteProviderAsync.fulfilled, (state, action) => {
        const deletedproviderId = action.payload;
        if (deletedproviderId) {
          state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
          state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
        }
      })
      .addCase(updateProviderClientAsync.fulfilled, (state, action) => {
        console.log(state.providers);
        // const deletedproviderId = action.payload;
        // state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
        // state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
      })
      .addMatcher(
        (action) =>
          [updateProviderAsync, updateProviderNoteAsync].some((thunk) => action.type.startsWith(thunk.fulfilled.type)),
        (state, action) => {
          const updatedprovider = action.payload;
          if (updatedprovider) {
            const existingproviderIndex = state.providers.findIndex((provider) => provider.id === updatedprovider.id);
            if (existingproviderIndex !== -1) {
              state.providers[existingproviderIndex] = updatedprovider;
            }
            const existingFilteredProvidersIndex = state.filteredProviders.findIndex(
              (provider) => provider.id === updatedprovider.id
            );
            if (existingFilteredProvidersIndex !== -1) {
              state.filteredProviders[existingFilteredProvidersIndex] = updatedprovider;
            }
          }
        }
      );
  },
});

export const { setFilteredProviders, setNewUserProvider, setOpenCreatorProviderModal } = providerSlice.actions;

export default providerSlice.reducer;
