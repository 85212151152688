import { useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNoInput = (props) => {
  const { handleChange, value, error, disabled = false, cssCode, cssClass } = props;
  const [defaultCountryCode, setDefaultCountryCode] = useState("US");

  useEffect(() => {
    if (error) error(validatePhone());
  });

  useEffect(() => {
    if (value && isPossiblePhoneNumber(value)) {
      let countryCode = parsePhoneNumber(value)?.country;
      setDefaultCountryCode(countryCode || "US");
    }
  }, [value]);

  const validatePhone = () => {
    if (value) {
      if (isPossiblePhoneNumber(value)) {
        return "";
      }
      return "Invalid phone number";
    }
    return "";
  };

  return (
    <PhoneInput
      key={defaultCountryCode}
      international
      countryCallingCodeEditable={false}
      className={`modalPhoneInput ${cssClass}`}
      defaultCountry={defaultCountryCode || "US"}
      placeholder="Phone"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      error={validatePhone()}
    />
  );
};

export default PhoneNoInput;
