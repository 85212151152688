import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import { Button, Card, Table, Container, Row, Col, Form } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { Link } from "react-router-dom";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { AppContext } from "context/app-context";
import MFPagination from "components/Pagination/MFPagination";
import {
  PAGE_LIMIT,
  TEST_HEADER_TYPE,
  SUPER_ADMIN_ID,
  RESULT_VALUE,
  IR_COLUMN_INDEX,
  TABLE_QUICK_TOOLS,
  RESULT_SETTINGS_OPT,
} from "constant";
import TimeFilter from "components/TimeFilter";
import Header from "components/Table/Header";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  changeSort,
  getCrewsName,
  formatTest,
  formatPhoneNumber,
  setPhoneNo,
  calculateTdWidth,
  sortTestList,
  sortingFilterInLC,
  formatUTCDate,
  removeSpecialCharacters,
  convertToLower,
  isAllowToArchive,
} from "utils";
import ContactInfo from "components/Modal/ContactInfo";
import FilterSelect from "components/FilterSelect";
import TestSubmitModal from "components/Modal/TestSubmitModal";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import TestLogsModal from "components/Modal/TestLogsModal";
import ChangeTestLabModal from "components/Modal/ChangeTestLabModal";
import moment from "moment";
import { useInitialRender } from "components/useInitialRender";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import Icon from "components/Icon";
import { stringConstant } from "constant";
import { CONFIG } from "constant";
import ImportResultModal from "components/Modal/ImportResultModal";
import ChangeResultModal from "components/Modal/ChangeResultModal";
import ResultModal from "components/ResultStatus/ResultModal";
import SequenceNo from "components/SequenceNo";
import EditAccessionNoModal from "components/Modal/EditAccessionNoModal";
import { TEST_EMPTY_OBJECT } from "constant";
import TestModal from "components/Modal/TestModal";
import { UNPROCESSED_LOCATION_COLUMN_DETAILS } from "views/Test/Columns";
import { getValidName, getBarCodeFromTest } from "utils";
import { GET_CUST_COLOR } from "constant";
import MainTable from "components/Table/MainTable";

const UnprocessedTests = () => {
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [importStatus, setImportStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showLabChangeModal, setShowLabChangeModal] = useState(false);
  const [modalShowResult, setModalShowResult] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [uploadResultSummary, setUploadResultSummary] = useState(null);
  const [editAccessionNo, setEditAccessionNo] = useState(null);
  const [selectedTest, setSelectedTest] = useState([]);
  const [newTestCreate, setTestCreate] = useState(_.cloneDeep(TEST_EMPTY_OBJECT));
  const [openCreator, setOpenCreator] = useState(false);
  const { filterDates } = TimeFilter;
  const initialRender = useInitialRender();
  const location = useLocation();
  const history = useHistory();
  const waitingResultCall = location.pathname.includes("senttest") ? true : false;
  const appContext = useContext(AppContext);

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes];

  const searchTerms = CONFIG.isWhiteLabel
    ? [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        `${stringConstant.show}`,
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "Custody Status",
      ]
    : [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone",
        "Client",
        `${stringConstant.show}`,
        "Lab",
        "Test Status",
        "Test Type",
        `${stringConstant.sequenceNo}`,
        "Tester",
        "isVaccinated",
        "White Glove",
        "Pattern",
        "Capacity",
      ];

  const selectedItem = CONFIG.isWhiteLabel
    ? [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "siteID",
        "status",
        "test_type",
        `${stringConstant.accession_no}`,
      ]
    : [
        "createdAt",
        "firstName",
        "lastName",
        "dob",
        "email",
        "phoneNumber",
        "clientID",
        "siteID",
        "labID",
        "status",
        "test_type",
        `${stringConstant.accession_no}`,
        "tester_name",
        "isVaccinated",
        "employeeType",
        "whiteGlove",
        "patternTestAnswer",
      ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const handleCloseCreateTest = () => {
    setTestCreate(_.cloneDeep(TEST_EMPTY_OBJECT));
    setSelectedTest([]);
    setOpenCreator(false);
  };

  const handleOpenCreateTest = () => {
    // set the created At date when open the test Modal
    let newObjValue = { ...TEST_EMPTY_OBJECT, createdAt: moment().format("YYYY-MM-DDTHH:mm") };

    // if any test is selected to clone the test data
    if (selectedTest && selectedTest.length > 0) {
      let selectedUser = { ...selectedTest[0] };
      const emptyObjectKeys = Object.keys(TEST_EMPTY_OBJECT);
      for (const objKey of emptyObjectKeys) {
        if (objKey === "sequenceNo") continue;
        if (objKey == "employee_demographics") {
          let demographicObj =
            typeof selectedUser[objKey] === "string" ? JSON.parse(selectedUser[objKey]) : selectedUser[objKey];
          newObjValue[objKey] = { ...demographicObj, phoneNumber: selectedUser["phoneNumber"] };
          if (selectedUser["dob"]) {
            newObjValue[objKey] = { ...demographicObj, dob: moment(selectedUser["dob"]).format("MMDDYYYY") };
          }
        } else {
          newObjValue[objKey] = selectedUser[objKey];
        }
      }
    }
    setTestCreate(newObjValue);
    setOpenCreator(true);
  };

  const createTest = async (testData) => {
    // create test
    testData = {
      ...testData,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      tester_name: appContext?.user?.name,
    };

    // Lab Check barcode is active or not
    const checkLab = labs?.find((item) => item.id === testData.labID && item.barCodeProvided);
    if (checkLab && checkLab.id) {
      let barcode = await API.getLabProvidedBarCode(checkLab.id);
      if (barcode) {
        testData = { ...testData, barcode };
      }
    }

    let newTest = await GQAPI.createTest(testData);
    setLoading(false);
    if (newTest) {
      appContext.showSuccessMessage("Test created successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be created");
    }
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone || infoObj.phone_number,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;

    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key]);
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "Tester") {
          return obj.tester_name && obj.tester_name.toLowerCase().includes(filters[key].toLowerCase());
        }

        // if ((key = "Test Type")) {
        //   if ("antigen".includes(filter[key].toLowerCase()))
        //     return obj.isAntigen;
        //   if ("pcr".includes(filter[key].toLowerCase())) return !obj.isAntigen;
        // }

        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }

        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        if (key === "vaccinated") {
          return (obj["isVaccinated"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "whiteGlove") {
          return (obj["whiteGlove"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "capacity") {
          return obj["employeeType"] && obj["employeeType"].toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "pattern") {
          if (filters[key] === "1") return obj["patternTestAnswer"] && obj["patternTestAnswer"] !== "undefined";

          if (filters[key] === "0") return obj["patternTestAnswer"] == "undefined" || obj["patternTestAnswer"] == "";
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });

    return models;
  };

  const displayPdf = async (test) => {
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const loadTestFromPG = async () => {
    console.log("Calling LoadTestFromPG");
    try {
      setLoading(true);
      const pgList = await API.getTestListFromPG({ waiting: waitingResultCall ? "w" : "p" });
      setLoading(false);
      let ttlTests = pgList.rows.map((t) => formatTest(t));
      if (waitingResultCall) {
        ttlTests = ttlTests.filter((t) => t.status !== "New");
      }
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCheckboxChange = (e, id, test) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    const prevFilteredTests = selectedTest.filter((t) => t.id !== id);

    if (e.target.checked) {
      filteredList.push(id);
      setSelectedTest([...prevFilteredTests, test]);
    } else {
      setSelectedTest([...prevFilteredTests]);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const submitTests = async () => {
    try {
      const testsToSubmit = tests.filter((test) => checkboxes.includes(test.id));
      if (!testsToSubmit.length) return;
      setLoading(true);
      const submission = await GQAPI.submitUnprocessedTests(
        testsToSubmit,
        appContext.user,
        CONFIG.isLive ? "LIVE" : "DEV"
      );
      const newTestList = tests.filter((test) => !checkboxes.includes(test.id));

      setFilteredTests(newTestList);
      setAllSelected(false);
      setCheckboxes([]);
      setImportStatus(submission);
      setShowAlert(true);
      setShowFilter(false);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage("Fail to Submit the Record");
    }
  };

  const reSubmitTests = async () => {
    const testsToSubmit = tests.filter((test) => checkboxes.includes(test.id));
    if (!testsToSubmit.length) return;
    setLoading(true);
    const submission = await API.reSubmitUnprocessedTests(testsToSubmit, appContext.user);
    // addLogs(testsToSubmit, "ReSubmitToLab");
    setAllSelected(false);
    setCheckboxes([]);
    setImportStatus(submission);
    setShowAlert(true);
    setShowFilter(false);
    setLoading(false);
  };

  const addLogs = (items, slug) => {
    const ids = items.map((s) => {
      return { id: s.id, sequenceNo: s.sequenceNo };
    });
    const params = { ids: ids, userID: appContext.user?.sub, userName: appContext.user?.name, slug: slug };
    API.addTestLogs(params);
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "unprocessedtest") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (waitingResultCall && sortingFilters.senttest.sortBy) {
      setSortDescending(sortingFilters.senttest.sortDescending);
      setSortBy(sortingFilters.senttest.sortBy);
    } else if (sortingFilters.unprocessedtest.sortBy) {
      setSortDescending(sortingFilters.unprocessedtest.sortDescending);
      setSortBy(sortingFilters.unprocessedtest.sortBy);
    }
    loadTestFromPG();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLabs(appContext.labs);
    setSites(appContext.sites);
  }, [appContext.labs, appContext.sites]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState[waitingResultCall ? "senttest" : "unprocessedtest"] = {
      filter,
      timeFilter,
      sortBy,
      sortDescending,
    };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
  }, [location.state]);

  useEffect(() => {
    refreshTestList(tests);
  }, [filter, triggerFilter, timeFilter]);

  useEffect(() => {
    if (initialRender) return;

    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      const newList = [...tests];

      if (eventType === "DELETE" || model.result || model.isArchive) {
        setTests(tests.filter((t) => t.id !== model.id));
        setFilteredTests(filteredTests.filter((t) => t.id !== model.id));
        return;
      }

      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      }
      setTests(newList);
      refreshTestList(newList);
    }
  }, [appContext.testObject]);

  const refreshTestList = (list) => {
    const testList = filterDates(nestedFilter(list, filter), timeFilter);

    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          testList,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName
        )
      );
    } else {
      setFilteredTests(testList);
    }
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "unprocessedtest", filter, timeFilter });
  };

  const afterDeletedTest = (testList) => {
    const newTestlist = filteredTests.filter((t) => testList.findIndex((f) => f.id === t.id) === -1);
    setTests(newTestlist);
    refreshTestList(newTestlist);

    setCheckboxes([]);
  };

  const handleCSVOnDropOld = async (csvData) => {
    try {
      setLoading(true);
      // const fileName = `portal/${moment().utc().format("YYYY_MM_DD")}_${appContext?.userLabName?.replace(
      //   /(:|\s+)/g,
      //   "_"
      // )}_${appContext?.user?.name?.replace(/(:|\s+)/g, "_")}.csv`;
      // const val = csvData.map((csv) => csv.data);
      // const csv = jsonToCSV(val);
      // await Storage.put(fileName, csv, { bucket: "results-from-labs" });

      const updatedId = [];
      const params = [];
      const errorfiles = [];
      let ttlRecord = csvData.length;
      setModalShowResult(false);
      for (let index = 0; index < ttlRecord; index++) {
        const { data } = csvData[index];
        const row = { ...data };
        let result = row.result.trim().toLowerCase();
        if (row.result && row.accession_number) {
          if (
            result === "positive" ||
            result === "negative" ||
            result === "detected" ||
            result === "not detected" ||
            result === "notdetected" ||
            result === "inconclusive"
          ) {
            if (result === "detected") result = "positive";
            else if (result === "not detected" || result === "notdetected") result = "negative";
            try {
              params.push({
                result: RESULT_VALUE[result],
                StampBy: appContext.user.sub,
                StampByName: appContext.user.name,
                accession_number: row.accession_number,
              });
            } catch (err) {
              console.log(err);
            }
          }
        } else {
          errorfiles.push(row);
        }
      }

      if (params.length > 0) {
        await GQAPI.updateWhiteLabelTestResult(params);
        appContext.showSuccessMessage(`${params.length} Test(s) Result Uploaded Successfully`);
      }
    } catch (err) {
      console.log("Err", err);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleEditNumber = (test) => {
    if (test.result) return;
    setEditAccessionNo(test);
  };

  const handleCSVOnDrop = async (csvData) => {
    try {
      setLoading(true);
      // const fileName = `portal/${moment().utc().format("YYYY_MM_DD")}_${appContext?.userLabName?.replace(
      //   /(:|\s+)/g,
      //   "_"
      // )}_${appContext?.user?.name?.replace(/(:|\s+)/g, "_")}.csv`;
      // const val = csvData.map((csv) => csv.data);
      // const csv = jsonToCSV(val);
      // await Storage.put(fileName, csv, { bucket: "results-from-labs" });

      const params = [];
      const errorfiles = [];
      const gridDataStartIndex = csvData.findIndex((row) => {
        if (
          row.data.indexOf("Fluor") !== -1 &&
          row.data.indexOf("Target") !== -1 &&
          row.data.indexOf("Sample") !== -1 &&
          row.data.indexOf("Call") !== -1
        ) {
          IR_COLUMN_INDEX.Fluor = row.data.indexOf("Fluor");
          IR_COLUMN_INDEX.Target = row.data.indexOf("Target");
          IR_COLUMN_INDEX.Sample = row.data.indexOf("Sample");
          IR_COLUMN_INDEX.Call = row.data.indexOf("Call");
          return true;
        }
        return false;
      });
      // If grid data not found, return (or handle accordingly)
      if (gridDataStartIndex === -1) {
        setLoading(false);
        appContext.showErrorMessage("'Flour','Call','Target' and 'Sample' Column are required in csv");
        return;
      }

      // Skip rows until grid data starts
      let actualData = csvData.slice(gridDataStartIndex);
      let patternData = actualData
        .filter(
          (f) =>
            (convertToLower(f.data[IR_COLUMN_INDEX.Fluor]) === "cy5" ||
              convertToLower(f.data[IR_COLUMN_INDEX.Fluor]) === "cal red 610") &&
            convertToLower(f.data[IR_COLUMN_INDEX.Target]) === "rnasep"
        )
        .map((f) => {
          return {
            fluor: f.data[IR_COLUMN_INDEX.Fluor],
            target: f.data[IR_COLUMN_INDEX.Target],
            sample: f.data[IR_COLUMN_INDEX.Sample],
          };
        });
      actualData = actualData.filter(
        (f) =>
          convertToLower(f.data[IR_COLUMN_INDEX.Fluor]) === "fam" &&
          convertToLower(f.data[IR_COLUMN_INDEX.Target]) === "covid-19 rna"
      );
      let ttlRecord = actualData.length;

      if (ttlRecord === 0) {
        setLoading(false);
        appContext.showErrorMessage("Required data is not in correct format");
        return;
      }

      const responseMessage = [];

      const clientResultTypes = getResultTypes();

      setModalShowResult(false);
      for (let index = 0; index < ttlRecord; index++) {
        const { data } = actualData[index];
        let id = data[IR_COLUMN_INDEX.Sample];
        let result = removeSpecialCharacters(data[IR_COLUMN_INDEX.Call]);
        let isValidAccessioNo = tests.find((t) => t.barcode === id);
        let fluorValue = patternData.find((f) => f.sample === id);
        let fluor = fluorValue?.fluor || data[IR_COLUMN_INDEX.Fluor];
        if (!isValidAccessioNo) {
          id = null;
        }
        if (id) {
          try {
            let finalResult = "";
            if (result === "positive") {
              finalResult = "Positive";
            } else if (!result) {
              finalResult = "Negative";
            } else if (result === "in") {
              finalResult = "Invalid";
            } else if (result === "i") {
              finalResult = "Indeterminate";
            } else {
              finalResult = "Error Result";
            }
            // Check if any of the result type is not set at client level
            if (finalResult !== "Error Result" && !clientResultTypes[finalResult]) {
              finalResult = "Error Result";
            }

            responseMessage[finalResult] = parseInt(responseMessage[finalResult] || 0) + 1;
            params.push({
              Target: data[IR_COLUMN_INDEX.Target],
              Sample: data[IR_COLUMN_INDEX.Sample],
              Call: data[IR_COLUMN_INDEX.Call],
              fluor: fluor,
              labID: CONFIG.whiteLabelID,
              StampBy: appContext.user.sub,
              StampByName: appContext.user.name,
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          responseMessage["Invalid Accession No"] = parseInt(responseMessage["Invalid Accession No"] || 0) + 1;
        }
      }
      const arrayOfObjects = Object.entries(responseMessage).map(([key, value]) => ({ label: key, tests: value }));

      if (params.length > 0) {
        await GQAPI.updateWhiteLabelTestResult(params, false);
      }
      setUploadResultSummary(arrayOfObjects);
    } catch (err) {
      console.log("Err", err);
      setLoading(false);
    }
    setLoading(false);
  };

  const updateLabChange = async (selectedType, isSubmitToLab) => {
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id) && test.test_type === "PCR");
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      appContext.showErrorMessage("No PCR test was selected");
      return;
    }
    setLoading(true);
    try {
      let testList = [];
      if (!loading) setLoading(true);
      if (isSubmitToLab) {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            status: "Sent",
            submittedBy: appContext.user.sub,
            submittedByName: appContext.user.name,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
        const lab = appContext.labs.find((l) => l.id === selectedType.value);
        await API.submitToLab(lab, updateSelectedTestResult);
      } else {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
      }
      addLogs(updateSelectedTestResult, "LabChange", selectedType.label);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} lab has been changed`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to update the lab`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const customRenderTD = (item, row) => {
    return (
      <td
        className="ellipsis accessionListNo"
        style={{ textAlign: "center", cursor: "Pointer" }}
        title={getBarCodeFromTest(row)}
      >
        {row.barcode}
        {CONFIG.isWhiteLabel && !row.result && !appContext.user.isSite() && (
          <i
            className="fas fa-edit accessionEdit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditNumber(row);
            }}
          />
        )}
      </td>
    );
  };

  const getResultTypes = () => {
    let types = ["Positive", "Negative", "Invalid", "Indeterminate"];

    if (appContext.clients && appContext.clients.length > 0) {
      let types = appContext.clients[0].resultTypes.results;
    }
    return Object.fromEntries(types.map((key) => [key, true]));
  };

  const handleChangePage = (page) => setCurrentPage(page);
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };
  const clearSelection = async () => {
    const newList = tests.filter((test) => !checkboxes.includes(test.id));
    refreshTestList(newList);
    setCheckboxes([]);
  };
  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      return;
    }
    updateTestResult("positive");
  };

  const handleUpdateNoteReocrd = (row) => {
    setFilteredTests((prevTests) => {
      const prevIndex = prevTests.findIndex((t) => t.id === row.id);
      if (prevIndex !== -1) {
        prevTests[prevIndex].note = row.note;
      }
      return [...prevTests];
    });
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row.id, row);
        break;
      case TABLE_QUICK_TOOLS.edit:
        history.push("/admin/test/" + row.id, { unprocessed: true, type: "unprocessed", test: row });
        handleSaveFilter();
        break;
      case TABLE_QUICK_TOOLS.notes:
        appContext.openNoteModal({ ...row, objectType: "test", handleUpdateNoteReocrd, title: row.barcode });
        break;
      default:
        break;
    }
  };

  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title as="h4">
                  Showing Test PDF for{" "}
                  {typeof testPdf.employee_demographics === "string"
                    ? JSON.parse(testPdf.employee_demographics).firstName
                    : testPdf.employee_demographics.firstName}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer table-full-width table-responsive px-0">
              <PDFViewer className="pdfView">
                <PDF test={testPdf} testsLab={testsLab} parseTestResultForPDF={appContext.parseTestResultForPDF} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid>
        {showAlert && <TestSubmitModal data={importStatus} handleClose={setShowAlert} />}
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {/* Date Range */}
                      <FilterSelect setTimeFilter={setTimeFilter} />
                      <Icon
                        handleClick={() => {
                          if (filteredTests && filteredTests.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />

                      {isAllowToArchive(appContext.user?.phone_number) && (
                        <Icon
                          handleClick={() =>
                            appContext.openDeleteConfirmation(
                              filteredTests.filter((f) => checkboxes.includes(f.id)),
                              afterDeletedTest
                            )
                          }
                          title={"Archive"}
                          label={"Archive"}
                          iconType={"binIcon"}
                          disabled={checkboxes.length === 0}
                        />
                      )}
                      <ExportToExcel
                        items={filteredTests}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        filter={filter}
                        title={waitingResultCall ? "Waiting Results" : "Unprocessed Tests"}
                        sortBy={sortBy}
                      />
                      {!CONFIG.isWhiteLabel && (
                        <Icon
                          disabled={checkboxes.length === 0}
                          handleClick={() => setShowLabChangeModal(true)}
                          title={"Change Lab"}
                          label={"Change Lab"}
                          iconType={"labIcon"}
                        />
                      )}

                      {waitingResultCall && (appContext.user["custom:role"] === "Admins" || CONFIG.isWhiteLabel) ? (
                        <>
                          {CONFIG.isWhiteLabel && !CONFIG.siteID && (
                            <Icon
                              handleClick={() => setShowResultModal(true)}
                              title={"Result Tests"}
                              label={"Result Tests"}
                              iconType={"resultsIcon"}
                              disabled={checkboxes.length === 0}
                            />
                          )}

                          {!CONFIG.isWhiteLabel && (
                            <Icon
                              disabled={checkboxes.length === 0}
                              handleClick={() => reSubmitTests()}
                              title={"ReSubmit Selected Tests"}
                              label={"ReSubmit"}
                              iconType={"submitIcon"}
                            />
                          )}
                          {CONFIG.isWhiteLabel && !CONFIG.siteID && (
                            <>
                              <Icon
                                handleClick={() => setModalShowResult(!modalShowResult)}
                                iconType={"importIcon"}
                                label={"Upload Results"}
                                title={"Upload Results"}
                              />
                              <Icon
                                handleClick={() => handleOpenCreateTest()}
                                title={"Create Test"}
                                label={"Create Test"}
                                iconType={"createIcon"}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Icon
                            disabled={checkboxes.length === 0}
                            handleClick={() => submitTests()}
                            title={"Submit Selected Tests"}
                            label={"Submit"}
                            iconType={"submitIcon"}
                          />
                        </>
                      )}

                      {filteredTests.length > 0 && !CONFIG.isWhiteLabel && (
                        <>
                          {Object.keys(filter).length > 0 && (
                            <>
                              <TestPDFLayout
                                tests={filteredTests}
                                selectedItem={selectedItem}
                                filter={filter}
                                timeFilter={timeFilter}
                                title={waitingResultCall ? "Waiting Results" : "Unprocessed Tests"}
                                appContext={appContext}
                                sortBy={sortBy}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="6">
                      <Card.Title>
                        <div className="">
                          {waitingResultCall ? "Waiting Results" : "Unprocessed Tests"} :
                          <span className="ps-2"> {filteredTests.length}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <MFPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <MainTable
                    columns={UNPROCESSED_LOCATION_COLUMN_DETAILS}
                    rows={testsToMap}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    selectedRows={checkboxes}
                    tools={quickTools}
                    dropDownOptions={[
                      { value: "Logs", title: "Logs" },
                      { value: "Print", title: "Print Accession No" },
                    ]}
                    handleDropDownClick={(type, row) => {
                      if (type === "Logs") {
                        appContext.showLogs(row);
                      } else if (type === "Print Accession No") {
                        appContext.printBarcode(row);
                      }
                    }}
                    customColumnCellRenderer={customRenderTD}
                    handleCellClick={handleCellClick}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />

      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="Result Positive"
          message="Are you sure, to result positive ?"
          handleConfirm={handleConfirm}
        />
      )}
      {showLabChangeModal && (
        <ChangeTestLabModal
          handleSave={updateLabChange}
          labs={appContext.labs}
          handleClose={() => setShowLabChangeModal(false)}
        />
      )}

      {showResultModal && (
        <ChangeResultModal
          handleClose={() => setShowResultModal(false)}
          appContext={appContext}
          title="Result Tests"
          tests={tests.filter((test) => checkboxes.includes(test.id))}
          callback={clearSelection}
        />
      )}
      {modalShowResult && (
        <ImportResultModal
          handleImport={handleCSVOnDrop}
          handleClose={() => setModalShowResult(false)}
          header={false}
        />
      )}
      {editAccessionNo && (
        <EditAccessionNoModal
          handleClose={(val) => {
            setEditAccessionNo(null);
            if (val) {
              val === "success"
                ? appContext.showSuccessMessage("Successfully Changed the Accession No!")
                : appContext.showErrorMessage(val);
            }
          }}
          test={editAccessionNo}
          appContext={appContext}
        />
      )}

      {uploadResultSummary && (
        <ResultModal data={uploadResultSummary} handleClose={() => setUploadResultSummary(false)} />
      )}
      {openCreator && (
        <TestModal
          newTestCreate={newTestCreate}
          handleCloseCreateTest={handleCloseCreateTest}
          createTest={createTest}
          setTestCreate={setTestCreate}
          clients={appContext.clients}
          sites={appContext.sites}
          labs={appContext.labs}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default UnprocessedTests;
