import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "api";
import Icon from "components/Icon";

import CreateLabelOption from "./CreateLabelOption";
import { TEST_SETTING_TYPES } from "constant";
import { customIsEmpty } from "util/customLodash";

const useOptions = () => {
  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    try {
      const optionList = await api.getGeneralSetting(TEST_SETTING_TYPES.TEST_LABEL);
      setOptions(optionList);
    } catch (error) {
      console.error(`Error fetching :`, error);
    }
  };

  const addOption = async (optionName) => {
    try {
      const isExist = options.some((item) => item.label.toLowerCase() === optionName.label.toLowerCase());
      if (!isExist) {
        await api.createTestLabel(optionName);
      }
      getOptions();
    } catch (error) {
      console.error(`Error adding :`, error);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return { options, addOption };
};

const LabelIdSelector = ({ onChange, value, tabIndex = 1, updateMainTest }) => {
  const { options, addOption } = useOptions();

  const [showCreateLabelModal, setShowCreateLabelModal] = useState(false);

  const handelAddOption = (value) => {
    if (!customIsEmpty(value.label) && !customIsEmpty(value.code)) {
      addOption(value);
      setShowCreateLabelModal(false);
    }
    updateMainTest(value);
  };

  return (
    <div className="row mt-2">
      <p className="testDetailsSelector">Label ID</p>
      <div className="d-flex align-items-center">
        <Select
          className={`w-100`}
          options={options}
          blurInputOnSelect={true}
          menuPlacement={"auto"}
          value={value}
          placeholder={"Label ID"}
          onChange={onChange}
          tabIndex={tabIndex}
        />
        <Icon
          iconType={"createIcon"}
          containerClass={"ms-2 cursor-pointer"}
          innerClass={"test-selector-add-icon"}
          handleClick={() => setShowCreateLabelModal(true)}
        />
      </div>
      {showCreateLabelModal && (
        <CreateLabelOption handleClose={() => setShowCreateLabelModal(false)} handleSaveChange={handelAddOption} />
      )}
    </div>
  );
};

export default LabelIdSelector;
