import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import { AppContext } from "context/app-context";

const ImportErrorModal = ({ data, handleClose, title }) => {
  const { successData, errData } = data;
  const appContext = useContext(AppContext);

  const TableRow = ({ err, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{err.message}</td>
      </tr>
    );
  };
  const successMessage = () => {
    if (successData.length === 0) return "No Data Import";
    return `Successfully Import ${successData.length} ${title}${successData.length === 1 ? "" : "s"}`;
  };
  return (
    <>
      <Modal show animation={true} onHide={() => handleClose()} size={"lg"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Import {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="marginTop0">
          <>
            <h4 style={{ margin: 0, marginBottom: "10px" }}>{successMessage()}</h4>
            <div style={{ flex: 1 }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      {errData.length > 0 && (
                        <div className="form-group-wrapper d-block">
                          <div className="table-responsive" style={{ overflow: "hidden" }}>
                            <span>Errors Details</span>
                            <Table className="table-hover table-striped">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Sr#</th>
                                  <th>Error</th>
                                </tr>
                              </thead>
                              <tbody>
                                {errData.length > 0 &&
                                  errData.map((err, index) => <TableRow err={err} key={index} index={index} />)}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse">
          <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportErrorModal;
