import PhoneNoInput from "components/Phone/PhoneNoInput";

const LoginUi = ({ phone, setPhone, password, setPassword, handleKeyDownEvent, disableBtn, handleSubmit }) => {
  return (
    <div className="login-right-side">
      <h3 className="mt-0">Login</h3>
      <p className="tag-line">Sign in to your account</p>
      <div className="form-group">
        <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
      </div>
      <div className="input-group group-wrapper mt-3">
        <span className="input-group-text">
          <i className="fa fa-lock" />
        </span>
        <input
          className="form-control"
          placeholder="Enter your password"
          minLength={8}
          value={password}
          type="password"
          onKeyDown={handleKeyDownEvent}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <p className="mt-2">
        I have read and agree to the
        <a
          className="text-decoration-none"
          target="_blank"
          href="https://medflow-images.s3.eu-west-1.amazonaws.com/PrivacyPolicy-MedFlow.pdf"
        >
          &nbsp; Privacy Policy
        </a>
        &nbsp; and &nbsp;
        <a
          className="text-decoration-none"
          href="https://medflow-images.s3.eu-west-1.amazonaws.com/TermsofUse-MedFlow.pdf"
          target="_blank"
        >
          Terms and Use &nbsp;
        </a>
      </p>
      <div className="text-right w-100">
        <button
          type="submit"
          disabled={disableBtn}
          className="text-uppercase w-100 btn signin-btn"
          onClick={handleSubmit}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default LoginUi;
